import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import "../Phoneinput.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function EditTutorModal({ tutorId, onClose }) {
  const [showPrivileges, setShowPrivileges] = useState(false);

  const { data, loading, error } = useFetch("/subject"); // Fetch subject list

  const [processing, setProcessing] = useState(false);

  const privileges = [
    {
      name: "Administrator",
      value: "administrator",
    },

    {
      name: "Edit Own Schedule",
      value: "edit_own_schedule",
    },

    {
      name: "Take Attendance",
      value: "take_attendance",
    },

    {
      name: "View Student/parent Email Addresses and phone numbers",
      value: "view_student_parent_detail",
    },

    {
      name: "Create/View Reports",
      value: "create_view_report",
    },
    {
      name: "View/Download Student Profile Attachments",
      value: "view_download_student_profile",
    },
  ];

  const initialState = {
    first_name: "",
    last_name: "",
    phone_no: "",
    mobile_no: "",
    role: "tutor",
    address: "",
    subject: "",
    privilege: [],
  };
  const [form, setForm] = useState(initialState);
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const [value, setValue] = useState();

  //get tutor detail
  function fetchTutorDetail() {
    setProcessing(true);
    api
      .get(`edit-tutor/${tutorId}`)
      .then((res) => {
        let res_data = res.data.data;

        setForm({
          ...form,
          first_name: res_data.first_name,
          last_name: res_data.last_name,
          email: res_data.email,
          phone_no: res_data.phone_no,
          mobile_no: res_data.mobile_no,
          role: res_data.role,
          address: res_data.address,
          subject: res_data.subject.id,
          privilege: JSON.parse(res_data.privilege),
        });

        //console.log("form:", form);
        setSelectedPrivileges(
          Array.isArray(res_data.privilege)
            ? JSON.parse(res_data.privilege)
            : []
        ); // Initialize selectedPrivileges with fetched privileges or an empty array if it's not an array
      })
      .catch((error) => console.error(error))
      .finally(() => setProcessing(false));
  }

  useEffect(() => {
    fetchTutorDetail();
  }, []);

  // function handlePrivilegeChange(e) {
  //   const { value, checked } = e.target;

  //   setForm((prevForm) => {
  //     if (checked) {
  //       // Add the privilege if it's not already present
  //       if (!prevForm.privilege.includes(value)) {
  //         return {
  //           ...prevForm,
  //           privilege: [...prevForm.privilege, value],
  //         };
  //       }
  //     } else {
  //       // Remove the privilege if it's present
  //       return {
  //         ...prevForm,
  //         privilege: prevForm.privilege.filter(
  //           (privilege) => privilege !== value
  //         ),
  //       };
  //     }
  //     // Return the previous form state if no changes are made
  //     return prevForm;
  //   });
  // }

  // Handle form input change
  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    // Update status based on checkbox
    const updatedValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    // Use a single call to setForm
    setForm({
      ...form,
      [name]: name === "subject" ? Number(updatedValue) : updatedValue,
    });
  }

  // Modify handleSubmit to convert privilege to an array before sending it to the server
  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const formData = {
        ...form,
        privilege: form.privilege,
      };

      // console.log('formdata', formData);
      // return;

      const response = await api({
        method: "post",
        url: `update-tutor/${tutorId}`,
        data: formData,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Tutor Updated Successfully!");
        onClose();
        window.location.reload();
        //navigate("/tutor");
      }
    } catch (error) {
      // Handle errors
    } finally {
      setProcessing(false);
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0  w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className=" w-5/6   pb-2 align-middle md:px-6 max-w-fit  gap-[16px] rounded-md border  bg-white m-2 p-4  ">
        <div className="mb-4 mt-4">
          <h2 className="text-dark font-bold  px-2">Edit Tutor</h2>
          {loading && <LoadingBlock>Loading...</LoadingBlock>}
          <div className="w-full flex flex-row   justify-start  items-center">
            <label className="w-1/5 text-neutral-700 text-base font-medium">
              User Type:
            </label>
            <div className="w-4/6 flex flex-row items-center">
              <input
                type="radio"
                className=" m-4 border  w-10 border-gray-400 rounded mr-4 p-2  text-neutral-700  text-base font-normal"
                value="tutor"
                name="role"
                onChange={handleChange}
                checked={form.role === "tutor"}
              />
              {"Tutoring Staff "}

              <input
                type="radio"
                className="m-4 border w-10 border-gray-400 rounded mr-4 p-2  text-neutral-700  text-base font-normal"
                value="nontutor"
                name="role"
                checked={form.role === "nontutor"}
                onChange={handleChange}
              />
              {"Non Tutoring Staff "}
            </div>
          </div>
        </div>

        {/* <div className="w-full flex mx-4 px-8">
          <div className="w-full flex flex-col">
            <div className="mb-2 ">
              <div className="w-full flex flex-row  justify-start ">
                <label
                  className="w-1/6  text-neutral-700 text-base font-medium  mb-4 cursor-pointer"
                  onClick={() => setShowPrivileges(!showPrivileges)}
                >
                  Privileges {showPrivileges ? "▼" : "▲"}
                </label>
              </div>
              <div
                className="w-full flex flex-col items-center mb-4"
                style={{ display: showPrivileges ? "block" : "none" }}
              >
                {privileges.map((item, index) => {
                  console.log("Form privilege:", form.privilege);
                  console.log("Selected privileges:", selectedPrivileges);
                  return (
                    <div key={index}>
                      <input
                        type="checkbox"
                        className="border border-gray-400 rounded p-2 mb-4 mr-2"
                        name={item.value}
                        value={item.value}
                        checked={form.privilege.includes(item.value)}
                        onChange={handlePrivilegeChange}
                      />
                      <span className="ml-3  text-neutral-700  text-base font-normal">
                        {item.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div> */}

        <div className="flex md:flex-row flex-col w-full justify-center  mb-2">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                First Name:
              </label>

              <input
                type="text"
                className="w-5/6  border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your First Name"
                name="first_name"
                value={form.first_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row   ">
              <label className="w-2/5 text-black text-base font-medium  mb-2">
                Last Name:
              </label>
              <input
                type="text"
                className="w-5/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Last Name"
                name="last_name"
                value={form.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col w-full justify-center ">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Email:
              </label>

              <input
                type="email"
                className="w-5/6  border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="w-4/6 ml-28 text-xs text-red-500">
              Email can't be updated!
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Address:
              </label>
              <input
                type="text"
                className="w-5/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Address"
                name="address"
                value={form.address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col w-full justify-center  ">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Phone:
              </label>

              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                className="md:w-[190px] w-[220px] border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal "
              />
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Subject:
              </label>

              <select
                className="w-5/6  border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                name="subject"
                value={form.subject}
                onChange={handleChange}
              >
                <option value="selected" selected>
                  Select Subject
                </option>
                {data?.data?.map((sub, i) => (
                  <option value={sub.id} key={i}>
                    {sub.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className=" ">
          <button
            className="bg-lime-600 text-white px-8 py-2 lg:mb-4 rounded-md"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "update"}
          </button>
          <button
            className="text-gray-600 py-2 rounded-md mx-4"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
