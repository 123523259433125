import { useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate, Link } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import Select from "react-select";
import LoadingBlock from "../../Components/LoadingBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { CalendarSelectedDate } from "../../context/CalendarSelectedDate";
import { getStartEndDates } from "../../context/CalendarDates";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddCalendarEvent() {
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedTutor, setSelectedTutor] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);

  const { isClickedDate, setIsClickeddDate } = useContext(CalendarSelectedDate);

  //get subject list
  const { data: subjectData, loading: subjectLoading } = useFetch("/subject");

  // Transform subject data into options for React Select
  const subjectOptions =
    subjectData?.data?.map((sub) => ({
      value: sub.id,
      label: sub.title,
    })) || [];

  // Handle subject change
  const handleSubjectChange = (selectedOption) => {
    setSelectedSubject(selectedOption);
  };

  // get tutor list

  const { data: activeTutor, loading: tutorLoading } =
    useFetch("/active-tutor-list");

  // Transform tutor data into options for React Select
  const TutorOptions =
    activeTutor?.data?.map((tutor) => ({
      value: tutor.id,
      label: tutor.first_name + " " + tutor.last_name,
    })) || [];

  // Handle tutor change
  const handleTutorChange = (selectedOption) => {
    setSelectedTutor(selectedOption);
  };

  // get student list

  const { data: activeStudent, loading: studentLoading } = useFetch(
    "/active-student-list"
  );

  // Transform tutor data into options for React Select
  const studentOptions =
    activeStudent?.data?.map((stu) => ({
      value: stu.id,
      label: stu.first_name + " " + stu.last_name,
    })) || [];

  // Handle tutor change
  const handleStudentChange = (selectedOption) => {
    setSelectedStudent(selectedOption);
  };

  const repeat = {
    DAILY: "daily",
    WEEKLY: "weekly",
  };

  const initialState = {
    topic: "",
    subject_id: "",
    tutor_id: "",
    student_id: "",
    start_date: isClickedDate ? getStartEndDates(isClickedDate) : "",
    //start_time: "",
    //duration: "",
    repeat: "",
    //repeat_until: "",
    zoom_link: "",
    end_date: "",
    meeting_id: "",
    passcode: "",
    repeat_day: [],
  };

  const [form, setForm] = useState(initialState);
  // const [startDate, setStartDate] = useState(
  //   setHours(setMinutes(new Date(), 30), 16)
  // );

  const [endDate, setEndDate] = useState(isClickedDate);
  //console.log('end date', endDate.toISOString());

  const [startDate, setStartDate] = useState(isClickedDate || new Date());

  // function handleTimeChange(time) {
  //   //console.log('local time', time);
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     start_time: time,
  //   }));
  // }

  //handle form input

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "repeat") {
      if (value === repeat.DAILY) {
        // Clear repeat_day if the repeat type is "daily"
        setForm((prevForm) => ({
          ...prevForm,
          repeat: value,
          repeat_day: [],
        }));
      } else {
        //const selectedDate = new Date(form.start_date);
        const selectedDate = new Date(form.start_date);
        const dayOfWeekAbbreviation = selectedDate
          .toLocaleDateString("en", { weekday: "short" })
          .toUpperCase()
          .substring(0, 2);

        //console.log('day of week',  dayOfWeekAbbreviation);
        setForm((prevForm) => ({
          ...prevForm,
          repeat: value,
          repeat_day: [dayOfWeekAbbreviation], // Set repeat_day as an array with the day of the week
        }));
      }
    } else {
      // For other fields, update the form as usual
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const formData = {
        ...form,
        start_date: startDate.toISOString(), //convert to UTC format
        end_date: endDate.toISOString(),
        tutor_id: selectedTutor.value, // <-- Include tutor_id
        student_id: selectedStudent.value, // <-- Include student_id
        subject_id: selectedSubject.value, // <-- Include
      };

      //console.log("formdata", formData);
      //return;
      const response = await api({
        method: "post",
        url: "store-schedule",
        data: formData,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Class Created Successfully !");
        navigate("/calendar");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  if (tutorLoading || studentLoading || subjectLoading) {
    return <LoadingBlock />;
  }

  return (
    <div className="mx-auto w-full px-5 lg:my-3 lg:pb-4  lg:flex-col">
      <div className="inline-block  lg:ml-2 lg:mr-10 min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  gap-[16px] rounded-md border  bg-white  overflow-x-auto">
        <div className="font-bold text-xl  pt-8 text-dark ">
          <Link
            to="/calendar"
            className="text-blue-500 text-start text-sm w-40 mt-2 ml-4 mr-80"
          >
            <FontAwesomeIcon icon={faAnglesLeft} />
            Back to Calendar
          </Link>
          Add Event
          <div className=" mb-4 mt-4 md:mt-7">
            <div className="flex md:flex-row flex-col w-full justify-center items-center mx-4 px-8 mb-4">
              <div className="mb-4 lg:w-1/2 w-full">
                <div className="w-full flex flex-row  ">
                  <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                    Topic:
                  </label>

                  <input
                    type="text"
                    className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                      errors.has("topic") ? "border-red-500" : ""
                    }`}
                    placeholder="Class Topic"
                    name="topic"
                    onChange={handleChange}
                  />
                </div>
                {errors.has("topic") && (
                  <div className="text-red-500 mt-1 ml-32 font-light text-sm">
                    {errors.first("topic")}
                  </div>
                )}
              </div>

              <div className="mb-4 lg:w-1/2 w-full">
                <div className="w-full flex flex-row  ">
                  <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                    Tutor:
                  </label>

                  <Select
                    className={`w-4/6  rounded  text-neutral-700  text-base font-normal ${
                      errors.has("tutor_id") ? "border-red-500" : ""
                    }`}
                    name="tutor_id"
                    onChange={handleTutorChange}
                    options={TutorOptions}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#EFF7F9",
                      }),
                    }}
                  />
                </div>
                {errors.has("tutor_id") && (
                  <div className="text-red-500 mt-1 ml-32 text-sm font-light ">
                    {errors.first("tutor_id")}
                  </div>
                )}
              </div>
            </div>

            <div className="flex md:flex-row flex-col w-full justify-center items-center mx-4 px-8 mb-4">
              <div className="mb-4 lg:w-1/2 w-full">
                <div className="w-full flex flex-row ">
                  <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                    Student:
                  </label>

                  <Select
                    className={`w-4/6  rounded  text-neutral-700  text-base font-normal  ${
                      errors.has("student_id") ? "border-red-500" : ""
                    }`}
                    name="student_id"
                    onChange={handleStudentChange}
                    options={studentOptions}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#EFF7F9",
                      }),
                    }}
                  />
                </div>
                {errors.has("student_id") && (
                  <div className="text-red-500 mt-1 ml-32 text-sm font-light">
                    {errors.first("student_id")}
                  </div>
                )}
              </div>

              <div className="mb-4 lg:w-1/2 w-full">
                <div className="w-full flex flex-row  ">
                  <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                    Subject:
                  </label>

                  <Select
                    className={`w-4/6 bg-main  rounded  text-neutral-700  text-base font-normal ${
                      errors.has("subject_id") ? "border-red-500" : ""
                    }`}
                    name="subject_id"
                    onChange={handleSubjectChange}
                    options={subjectOptions}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "#EFF7F9",
                      }),
                    }}
                  />
                </div>
                {errors.has("subject_id") && (
                  <div className="text-red-500 mt-1 ml-32 text-sm font-light">
                    {errors.first("subject_id")}
                  </div>
                )}
              </div>
            </div>

            <div className="flex md:flex-row flex-col w-full justify-center  mx-4 px-8">
              <div className="mb-4 lg:w-1/2 w-full ">
                <div className="w-full flex flex-row ">
                  <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                    Start Date:
                  </label>
                  <div className="w-4/6 flex flex-col">
                    <ReactDatePicker
                      className={`w-full border border-gray-400 rounded p-2  text-neutral-700 bg-main text-base font-light${
                        errors.has("start_date") ? " border-red-500" : ""
                      }`}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      dateFormat={"d/M/yyyy            h:mm aa"}
                      name="start_date"
                      id="startDate"
                    />
                    <em className="text-xs mt-1">
                      This is the local time. Date/Time might be different for
                      users.
                    </em>

                    {errors.has("start_date") && (
                      <div className="text-red-500 mt-1 ml-2 text-sm">
                        {errors.first("start_date")}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-4 lg:w-1/2 w-full ">
                <div className="w-full flex flex-row ">
                  <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                    Duration:
                  </label>
                  <div className="w-4/6  flex flex-row">
                    <input
                      type="text"
                      className={` w-full border bg-main border-gray-400 rounded-l p-2  text-black text-base font-light${
                        errors.has("duration") ? "border-red-500" : ""
                      }`}
                      placeholder="Enter duration in minutes "
                      name="duration"
                      onChange={handleChange}
                    />

                    <span className=" inline-flex items-center px-3 text-sm text-white bg-dark border opacity-80 border-dark rounded-e-md">
                      Minutes
                    </span>

                    {errors.has("duration") && (
                      <div className="text-red-500 mt-1 ml-26 text-sm">
                        {errors.first("duration")}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="w-1/2 mb-8">
                <div className="w-full flex flex-row items-center">
                  <label className="w-1/6 ml-4 text-black text-base font-normal mb-2">
                    Time:
                  </label>
                  <div className="w-5/6 ml-4 flex flex-col">
                    
                    <ReactDatePicker
                      className={`border border-gray-400 rounded p-2 mr-2 text-black bg-main text-base font-light${
                        errors.has("start_time") ? " border-red-500" : ""
                      }`}
                      selected={form.start_time}
                      onChange={handleTimeChange}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      name="start_time"
                      dateFormat={"h:mm aa"}
                      timeCaption="Time"
                      placeholderText="-- -- --"
                    />
                    {/* <input
                      type="time"
                      className={`border border-gray-400 rounded p-2 mr-2 text-black bg-main text-base font-light${
                        errors.has("start_time") ? " border-red-500" : ""
                      }`}
                      name="start_time"
                      onChange={handleChange}
                      style={{ WebkitAppearance: "textfield" }}
                    /> 
                    {errors.has("start_time") && (
                      <div className="text-red-500 mt-1 ml-2 text-sm">
                        {errors.first("start_time")}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
            </div>

            {/* <div className="w-[850px] flex flex-row ">
              <div className="w-1/2 mb-8">
                <div className="w-full flex flex-row items-center">
                  <label className="w-1/6 ml-4 text-black text-base font-normal mb-2">
                    Duration:
                  </label>
                  <div className=" relative w-5/6 ml-4 flex flex-row">
                    <input
                      type="text"
                      className={`w-full border border-gray-400 rounded p-2 bg-main text-black text-base font-light ${
                        errors.has("duration") ? "border-red-500" : ""
                      }`}
                      placeholder="Enter duration in minutes "
                      name="duration"
                      onChange={handleChange}
                    />
                    <span className="absolute inset-y-0 right-0 flex items-center px-3 text-sm text-gray-900 bg-main border border-gray-300 rounded-r-md">
                      Minutes
                    </span>
                  </div>
                </div>
                {errors.has("duration") && (
                  <div className="text-red-500 mt-1 ml-26 text-sm">
                    {errors.first("duration")}
                  </div>
                )}
              </div>
            </div> */}

            <div className="flex lg:flex-row flex-col w-full justify-center  mx-4 px-8 ">
              <div className="mb-4 lg:w-1/2 w-full flex flex-row ">
                {/* {isRepeating && ( */}
                <div className="lg:w-1/4 w-20 text-neutral-700 text-base font-medium  mb-2">
                  This event repeats:
                </div>
                <div className="w-4/6  repeating-event-section bg-main  py-3 rounded-md drop-shadow border border-gray-400  ">
                  <h2 className="w-full   text-teal-700 text-lg font-normal  mb-2 text-center">
                    Recurring Event
                  </h2>
                  <div className="flex items-center mb-2 ml-6">
                    <h3 className="w-1/4  text-black  text-base font-light  mb-2">
                      Repeat:
                    </h3>
                    <label className="font-light text-base  ">Daily</label>
                    <input
                      type="radio"
                      name="repeat"
                      value="daily"
                      className=" border  border-gray-400 rounded ml-4  text-black  text-base font-light"
                      onChange={handleChange}
                    />
                    <label className="ml-10 mr-4 font-light text-base  ">
                      Weekly
                    </label>
                    <input
                      type="radio"
                      name="repeat"
                      value="weekly"
                      onChange={handleChange}
                      className="border border-gray-400 rounded "
                    />
                  </div>

                  {errors.has("repeat") && (
                    <div className="text-red-500 mt-1 ml-26 text-sm">
                      {errors.first("repeat")}
                    </div>
                  )}

                  <div className="flex flex-col items-start text-left    mb-2 ml-6">
                    <div className=" flex ">
                      <h4 className="w-1/4  text-black text-base  ">
                        Repeat Until:
                      </h4>
                      <ReactDatePicker
                        className="border w-5/6 border-gray-400 bg-white rounded px-14 py-2 text-base font-light ml-4"
                        selected={endDate}
                        //onChange={(date) => setEndDate(date)}
                        //showTimeSelect
                        onChange={(date) => {
                          // Combine selected date with current time
                          const newEndDate = new Date(date);
                          newEndDate.setHours(endDate.getHours());
                          newEndDate.setMinutes(endDate.getMinutes());
                          setEndDate(newEndDate);
                        }}
                        dateFormat={"d/M/yyyy"}
                        name="end_date"
                        id="endDate"
                        placeholderText="dd/mm/yy"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 lg:w-1/2 w-full ">
                <div className="w-full flex flex-row ">
                  <div className="w-1/4  text-black text-base font-normal mb-2">
                    Description:
                  </div>
                  <textarea
                    className="w-4/6  h-40 border bg-main border-gray-400 rounded p-2 text-base font-normal "
                    placeholder="Class Zoom Link Description"
                    name="zoom_link"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row flex-col w-full justify-center  mx-4 px-8 my-3.5  ">
              <div className="mb-4 lg:w-1/2 w-full flex flex-row ">
                {/* {isRepeating && ( */}
                <div className="lg:w-1/4 w-20 text-neutral-700 text-base font-medium  mb-2">
                  Meeting ID:
                </div>

                <input
                  type="text"
                  className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                    errors.has("meeting_id") ? "border-red-500" : ""
                  }`}
                  placeholder="Meeting ID"
                  name="meeting_id"
                  onChange={handleChange}
                />
                {errors.has("meeting_id") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("meeting_id")}
                  </div>
                )}
              </div>

              <div className="mb-4 lg:w-1/2 w-full ">
                <div className="w-full flex flex-row ">
                  <div className="w-1/4  text-black text-base font-normal mb-2">
                    Passcode:
                  </div>
                  <input
                    type="text"
                    className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                      errors.has("passcode") ? "border-red-500" : ""
                    }`}
                    placeholder="Passcode"
                    name="passcode"
                    onChange={handleChange}
                  />
                  {errors.has("passcode") && (
                    <div className="text-red-500 mt-1 ml-2 text-sm">
                      {errors.first("passcode")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-2 md:ml-10">
              <button
                className="bg-lime-600 text-sm  text-white px-8 py-2 rounded-md"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                {processing ? "Please wait..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCalendarEvent;
