import React, { useContext, useEffect, useState } from "react";
import api from "../../../api";
import LoadingBlock from "../../../Components/LoadingBlock";
import Pagination from "../../../Components/Pagination";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import Avatar from "../../../Components/auth/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

function StudentTutorList() {
  const [tutor, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  //fetch tutors

  function fetchTutors(page = 1) {
    setLoading(true);
    api
      .get(`get-student-tutor/${user.id}`, {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setTutors(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTutors();
  }, []);

  return (
    <section className="mx-auto w-full px-5 lg:pb-4 lg:ml-2">
      <div className="lg:mt-3 flex flex-col overflow-x-auto">
        <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  ">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex  space-y-4 flex-row items-center justify-between md:space-y-0 py-2 lg:py-5  bg-white rounded-t-lg ">
                <div>
                  <h2 className="text-lg p-4  text-dark font-semibold">
                    Assigned Tutors
                  </h2>
                </div>
              </div>

              <table className="w-full table-auto divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-right text-sm font-medium text-gray-600"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 lg:pl-44 pl-20s"
                    >
                      <span>Name</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 lg:pr-28 pr-16"
                    >
                      <span>Subject</span>
                    </th>
                    {/* <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Action</span>
                    </th> */}
                  </tr>
                </thead>

                {loading && <LoadingBlock>Loading...</LoadingBlock>}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tutor?.data?.length > 0 ? (
                    tutor?.data?.map((tutor, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-base text-right font-normal whitespace-nowrap px-4 py-4">
                          {index + 1}
                        </td>
                        <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 flex gap-3 lg:pl-44 pl-20">
                          <Avatar user={tutor} />
                          <div>
                            <Link
                              to={`#`}
                              className="text-dark  text-base font-medium hover:underline "
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {tutor.first_name} {tutor.last_name}
                            </Link>

                            <p className="text-xs font-normal text-gray-500">
                              {tutor.email}
                            </p>
                          </div>
                        </td>

                        <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 lg:pr-28 pr-16 ">
                          {tutor.subject?.title}
                        </td>

                        {/* <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 ">
                          <Link to={`/student/message/${tutor.id}`}>
                            <button className="btn-email text-slate-600 text-sm font-bold leading-tight">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="mr-2 text-lime-600"
                              />
                              Email
                            </button>
                          </Link>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4">
                        No Tutors available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {tutor?.meta?.last_page > 1 && (
          <Pagination
            meta={tutor.meta}
            onChange={(page) => fetchTutors(page)}
          />
        )}
      </div>
    </section>
  );
}

export default StudentTutorList;
