import { useState } from "react";
import Errors from "../../utils/Errors";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";

function AddSubject({ closeModal }) {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const initialState = {
    title: "",
    status: 1,
  };
  const [form, setForm] = useState(initialState);

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await api({
        method: "post",
        url: "store-subject",
        data: form,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Subject Added Sucessfully !");
        closeModal();
        navigate("/subject");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          // Display individual error messages
          Object.keys(error.response.data.errors).forEach((fieldName) => {
            const errorMessage = error.response.data.errors[fieldName][0];
            toast.error(errorMessage);
          });
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          console.error(error);
          toast.error("Failed to process form !");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong !");
      }
    } finally {
      setProcessing(false);
    }
  }
  return (
    <div className=" w-full justify-center px-4">
      <div className="mb-4 mt-4">
        <div className="w-full flex flex-row items-center">
          <label className="w-1/6  text-black  text-base font-normal  mb-2 ">
            Title:
          </label>

          <input
            type="text"
            className={`w-5/6 border border-gray-400 rounded p-2  bg-main  text-black  text-base font-light${
              errors.has("title") ? "border-red-500" : ""
            }`}
            placeholder="Subject Title"
            name="title"
            onChange={handleChange}
          />

          {errors.has("title") && (
            <div className="text-red-500 mt-1 ml-2 text-sm">
              {errors.first("title")}
            </div>
          )}
        </div>
      </div>
      <div className="mb-4">
        <div className="w-full flex flex-row items-center">
          <label className="w-1/6  text-black  text-base font-normal  mb-2 ">
            Status:
          </label>

          <select
            className="w-5/6 border border-gray-400 rounded p-2  bg-main  text-black  text-base font-light"
            name="status"
            onChange={handleChange}
          >
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
        </div>
      </div>

      <div className="mt-4 py-2  text-center">
        <button
          className="bg-lime-600 text-white px-10 p-2 rounded-md"
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {processing ? "Please wait..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default AddSubject;
