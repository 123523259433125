import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import withReactContent from "sweetalert2-react-content";
import { DeleteStudentAction } from "../../../utils/ButtonActions";
import LoadingBlock from "../../../Components/LoadingBlock";
import { Delete, Edit } from "@mui/icons-material";
import EditStudentModal from "./EditStudentModal";
import useFetch from "../../../hooks/useFetch";
import Swal from "sweetalert2";

export default function ViewStudentDetail() {
  const [showEditStudentModal, setShowEditStudentModal] = useState(false); // State for edit confirmation modal
  const { studentId } = useParams();
  const [StudentId, setStudentId] = useState(null);
  const { data, loading, error } = useFetch(`/user-info/${studentId}`);
  const ReactSwal = withReactContent(Swal);
  const navigate = useNavigate();

  //console.log(data);

  const handleEditClick = (studentId) => {
    setStudentId(studentId); // Set session ID
    setShowEditStudentModal(true);
  };

  //delete action

  async function DeleteStudent(studentId) {
    const result = await ReactSwal.fire({
      title: <p className="text-center text-gray-600">Confirm delete</p>,
      html: `
            <p className="Align">Are you sure you want to delete this student? </p>
          `,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "delete-button-class",
        cancelButton: "cancel-button-class",
      },
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteStudentAction(studentId)
      .then((res) => {
        if (res) {
          navigate("/student");
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) {
    return <LoadingBlock />;
  }
  return (
    <div className="md:w-1/2 w-full flex items-center">
      <div className="w-full md:ml-4 flex flex-col md:gap-[16px]">
        <div className="w-full flex items-center">
          <label className="text-black mr-2 w-1/3 text-nowrap">
            Full Name:
          </label>
          <p className="text-dark font-bold lg:ml-20 ml-6 w-2/3 text-nowrap">
            {data?.data?.first_name} {data?.data?.last_name}
          </p>
          <div className="flex items-center gap-1 lg:mr-24">
            <span
              onClick={() => handleEditClick(studentId)}
              className="text-dark cursor-pointer"
            >
              <div className="rounded-full border border-gray-300 p-1 bg-white hover:bg-gray-200">
                <Edit />
              </div>
            </span>
            <span
              className="text-dark cursor-pointer"
              onClick={() => DeleteStudent(studentId)}
            >
              <div className="rounded-full border border-gray-300 bg-white hover:bg-gray-200 p-1">
                <Delete />
              </div>
            </span>
          </div>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Address:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.address}</p>
        </div>

        {/* <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Email:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.email}</p>
        </div> */}

        {/* <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Phone:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.phone_no}</p>
        </div> */}
      </div>

      {showEditStudentModal && (
        <EditStudentModal
          studentId={studentId}
          onClose={() => setShowEditStudentModal(false)}
        />
      )}
    </div>
  );
}
