import React from "react";
import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import router from "./Route/MainRoute";
import { useAxiosAuthInterceptor } from "./api";

function App() {
  useAxiosAuthInterceptor();

  return <RouterProvider router={router} />;
}

export default App;
