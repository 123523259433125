import React, { useEffect, useState } from "react";

import logo from "../../Assets/Images/Logo.png";
import { Link } from "react-router-dom";
import Illustration from "../../Assets/Images/Forgotpassword.png";
import Errors from "../../utils/Errors";
import api from "../../api";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await api.post("forgot-password", {
        email: email, // Ensure that 'email' matches the name attribute in your form
      });
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.success);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setErrors(new Errors(error.response.data.errors));
        toast.error("User not found");
      }

      if (error.response.status === 403) {
        setErrors(new Errors(error.response.data.errors)); //setErrors(error.response.errors);
        //console.error(error);
        toast.error(error.response.data.error); // Display the server error message
      }

      if (error.response.status === 400) {
        setErrors(new Errors(error.response.data.errors)); //setErrors(error.response.errors);
        //console.error(error);
        toast.error(error.response.data.error); // Display the server error message
      }

      if (error.response.status === 422) {
        setErrors(new Errors(error.response.data.errors)); //setErrors(error.response.errors);
        //console.error(error);
        toast.error("Failed to process form");
      }
    } finally {
      setProcessing(false);
    }
  }

  //useEffect(() => setErrors(new Errors()), [email]);

  return (
    <section className="flex flex-wrap">
      <div className="w-full lg:w-1/2 p-4">
        <img src={Illustration} alt="Illustration" className="w-full h-auto" />
      </div>
      <div className="w-full border-l-2 lg:w-1/2 mt-6 mb-12 pt-20">
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            className="w-56 h-24 mr-[260px]  mx-auto"
          />
        </div>
        <div className="flex items-center justify-center px-4  sm:px-6 sm:py-16 lg:px-8 text-center">
          <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
            <h2 className="text-3xl font-bold leading-tight text-green-700 sm:text-4xl">
              Forgot Password
            </h2>

            <form action="#" method="POST" className="mt-8">
              <div className="space-y-5">
                <div>
                  <p className="text-base text-gray-700">
                    Enter the email address associated with your account:
                  </p>
                  <div className="mt-2">
                    <input
                      name="email"
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    {errors.has("email") && (
                      <div className="text-red-500 mt-1 ml-2 text-sm">
                        {errors.first("email")}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="inline-flex w-full items-center justify-center rounded-md bg-yellow bg-amber-500 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {processing ? "please wait..." : "Reset Password"}{" "}
                  </button>
                </div>
                <div className="text-center mt-4">
                  Go back to&nbsp;
                  <Link to="/" className="text-green-700 hover:underline">
                    Login page
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
