import { Class, LibraryBooks, People } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  getDashStartEndDates,
  getStartEndTime,
} from "../../context/CalendarDates";
import AttendanceStatus from "../layouts/AttendanceStatus";
import { useState } from "react";
//
import CountUp from "react-countup";
import { Link } from "react-router-dom";

const AdminDashboard = ({
  totalClasses,
  totalTeachers,
  totalSubjects,
  totalStudents,
  upcomingSessionClass,
  previousSessionClass,
  userTimezone,
}) => {
  const [previousSessionCount, setPreviousSessionCount] = useState(5);
  const [upcomingSessionCount, setUpcomingSessionCount] = useState(5);

  const handleShowMorePreviousSessions = () => {
    setPreviousSessionCount((prevCount) => prevCount + 5); // Increment count by 5
  };

  const handleShowMoreUpcomingSessions = () => {
    setUpcomingSessionCount((prevCount) => prevCount + 5); // Increment count by 5
  };

  const handleShowLessPreviousSessions = () => {
    setPreviousSessionCount((prevCount) => Math.max(5, prevCount - 5)); // Decrement count by 5, but not below 5
  };

  const handleShowLessUpcomingSessions = () => {
    setUpcomingSessionCount((prevCount) => Math.max(5, prevCount - 5)); // Decrement count by 5, but not below 5
  };

  // Filter sessions for the upcoming section
  const sortedUpcomingSessionClass = upcomingSessionClass
    .filter((session) => {
      const sessionStartDate = new Date(session.start_date.date_time);
      const currentDateTimeInUserTZ = new Date().toLocaleString("en", {
        timeZone: userTimezone,
      });
      const currentDateInUserTZ = new Date(currentDateTimeInUserTZ);

      // Check if the session start date is after or equal to the current date
      return sessionStartDate >= currentDateInUserTZ.setHours(0, 0, 0, 0);
    })
    .sort(
      (a, b) =>
        new Date(a.start_date.date_time) - new Date(b.start_date.date_time)
    );

  // Filter sessions for the previous section
  const sortedPreviousSessionClass = previousSessionClass
    .filter((session) => {
      const sessionEndDate = new Date(session.end_date.date_time);
      const sessionEndDatePlusOneHour = new Date(
        sessionEndDate.getTime() + 3600000
      ); // Adding one hour in milliseconds
      const currentDateTimeInUserTZ = new Date().toLocaleString("en", {
        timeZone: userTimezone,
      });

      // Check if the session end time plus one hour is before the current date and time
      return sessionEndDatePlusOneHour < new Date(currentDateTimeInUserTZ);
    })
    .sort(
      (a, b) =>
        new Date(a.start_date.date_time) - new Date(b.start_date.date_time)
    );

  console.log("previous session", sortedPreviousSessionClass);

  // Dashboard data
  const dashboardData = [
    {
      title: "Total Classes",
      amount: totalClasses,
      icon: <Class />,
    },
    {
      title: "Total Teachers",
      amount: totalTeachers,
      icon: <GroupsIcon />,
    },
    {
      title: "Total Subject",
      amount: totalSubjects,
      icon: <LibraryBooks />,
    },
    {
      title: "Total Students",
      amount: totalStudents,
      icon: <People />,
    },
  ];

  const topChannelsHeader = [
    {
      label: "Date/Time",
      className: " text-xs text-left  sm:block xl:p-2",
    },
    { label: "Tutor", className: "text-xs  text-left sm:block xl:p-2" },
    { label: "Student", className: " text-xs text-left sm:block  xl:p-2" },
    {
      label: "Subject",
      className: " text-xs text-left  sm:block xl:p-2",
    },
    {
      label: "Attendance",
      className: " text-xs lg:text-left sm:block xl:p-2",
    },
  ];

  const Channel = ({ rowData }) => (
    <div
      className={`grid  border-b border-stroke dark:border-strokedark grid-cols-5`}
    >
      <div
        className={`flex items-center justify-center p-1.5 xl:p-2 text-xs font-medium`}
      >
        <Link to={`/session-history/${rowData.id}`}>
          {getDashStartEndDates(rowData.start_date.date_time, userTimezone)},
          <br />
          {getStartEndTime(rowData.start_date.raw, userTimezone)}-
          {getStartEndTime(rowData.end_date.raw, userTimezone)}
        </Link>
      </div>
      <div
        className={`flex  items-center  p-1.5 xl:p-2 text-xs lg:text-sm lg:font-normal   font-medium`}
      >
        {rowData.tutor.first_name}{" "}
        {rowData.tutor.last_name.length > 4 ? <br /> : " "}
        {rowData.tutor.last_name}
      </div>
      <div
        className={`flex items-center  p-1.5 xl:p-2 text-xs lg:text-sm lg:font-normal   font-medium`}
      >
        {rowData.student.first_name}{" "}
        {rowData.student.last_name.length > 4 ? <br /> : " "}
        {rowData.student.last_name}
      </div>
      <div
        className={`flex items-center  p-1.5 xl:p-2 text-xs lg:text-sm lg:font-normal   font-medium`}
      >
        {rowData.subject.title}
      </div>

      <AttendanceStatus status={rowData.attendance_status} />
    </div>
  );

  const topChannelsHeader2 = [
    {
      label: "Date/Time",
      className: " text-xs text-left  sm:block xl:p-2",
    },
    { label: "Tutor", className: "text-xs  text-left sm:block xl:p-2" },
    { label: "Student", className: " text-xs text-left  sm:block xl:p-2" },
    {
      label: "Subject",
      className: " text-xs text-center sm:block xl:p-2",
    },
  ];

  const Channel2 = ({ rowData }) => (
    <div
      className={`grid  border-b border-stroke dark:border-strokedark grid-cols-4`}
    >
      <div className={`flex items-center  p-2.5 xl:p-2 text-xs font-medium`}>
        <Link to={`/session-history/${rowData.id}`}>
          {getDashStartEndDates(rowData.start_date.date_time, userTimezone)},
          <br />
          {getStartEndTime(rowData.start_date.raw, userTimezone)}-
          {getStartEndTime(rowData.end_date.raw, userTimezone)}
        </Link>
      </div>
      <div
        className={`flex  items-center  p-2.5 xl:p-2 text-xs lg:text-sm lg:font-normal  font-medium`}
      >
        {rowData.tutor.first_name}{" "}
        {rowData.tutor.last_name.length > 4 ? <br /> : " "}
        {rowData.tutor.last_name}
      </div>
      <div
        className={`flex items-center  p-2.5 xl:p-2 text-xs lg:text-sm lg:font-normal  font-medium`}
      >
        {rowData.student.first_name}{" "}
        {rowData.student.last_name.length > 4 ? <br /> : " "}
        {rowData.student.last_name}
      </div>
      <div
        className={` flex items-center justify-center p-2.5 sm:flex xl:p-2 text-xs lg:text-sm lg:font-normal  font-medium`}
      >
        {rowData.subject.title}
      </div>
    </div>
  );

  return (
    // <section className="w-[1160px] h-auto px-[50px] pt-[31px] pb-36 flex-col justify-start items-start gap-[49px] inline-flex ">
    <section className="mx-auto max-w-screen-3xl lg:p-4 lg:ml-3 px-4 mr-1 ml-1 flex-grow ">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 ">
        {dashboardData?.map((item, index) => (
          <div
            key={index}
            className="rounded-lg border border-stroke bg-white px-15 py-4 shadow-default dark:border-strokedark dark:bg-boxdark"
          >
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-main text-dark">
              {item.icon}
            </div>

            <div className="flex sm:flex-row  lg:flex-col justify-between">
              <h4 className="text-2xl font-bold text-lime-600 dark:text-white py-3 pl-4">
                <CountUp end={item.amount} duration={5} />
              </h4>
              <span className="text-base font-medium">{item.title}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full flex flex-col  lg:flex-row">
        <div className="w-full lg:w-1/2 rounded-xl border border-stroke bg-white  pb-2.5 pt-6 mt-8 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-4 xl:pb-1">
          <h4 className="mb-2 text-xl font-bold text-dark pl-2 dark:text-white lg:text-left">
            Previous Session
          </h4>
          {sortedPreviousSessionClass.length > 0 ? (
            <div className="flex flex-col">
              {/* Header Row */}
              <div className="grid  rounded-sm bg-main  dark:bg-meta-4 grid-cols-5">
                {topChannelsHeader.map((header, index) => (
                  <div key={index} className={`p-1.5 ${header.className}`}>
                    <h5 className="text-xs font-medium   xsm:text-xs">
                      {header.label}
                    </h5>
                  </div>
                ))}
              </div>

              {/* {sortedPreviousSessionClass.map((rowData, rowIndex) => (
              <Channel key={rowIndex} rowData={rowData} />
            ))}{" "} */}

              {sortedPreviousSessionClass
                .slice(0, previousSessionCount)
                .map((rowData, index) => (
                  // Render previous session data
                  // Example: <div>{rowData.name}</div>

                  <Channel key={index} rowData={rowData} />
                ))}
              {/* See more/less button for previous classes */}
              {sortedPreviousSessionClass.length > 5 && ( // Only render the "Show More" button if there are more than 5 sessions
                <div className="flex items-center justify-center p-2.5 xl:p-2 text-xs font-medium text-dark">
                  {previousSessionCount < sortedPreviousSessionClass.length ? (
                    <button
                      onClick={handleShowMorePreviousSessions}
                      className="mr-2"
                    >
                      Show More
                    </button>
                  ) : (
                    <button
                      onClick={handleShowLessPreviousSessions}
                      className="ml-2"
                    >
                      Show Less
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <p className="text-center mt-8 text-gray-500">
              No Previous Session
            </p> // Display "No Previous Session" text if there are no previous sessions
          )}
        </div>
        <div className="w-full lg:w-1/2 rounded-xl border border-stroke bg-white  pb-2.5 pt-6 mt-8 lg:ml-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-4 xl:pb-1">
          <h4 className="mb-2 text-xl font-bold text-dark dark:text-white pl-2 lg:text-left">
            Upcoming Session
          </h4>
          {sortedUpcomingSessionClass.length > 0 ? (
            <div className="flex flex-col">
              {/* Header Row */}
              <div className="grid  rounded-sm bg-main  dark:bg-meta-4 grid-cols-4">
                {topChannelsHeader2.map((header, index) => (
                  <div key={index} className={`p-2.5 ${header.className}`}>
                    <h5 className="text-xs font-medium xsm:text-xs">
                      {header.label}
                    </h5>
                  </div>
                ))}
              </div>

              {/* Data Rows */}
              {/* {sortedUpcomingSessionClass.map((rowData, rowIndex) => (
              <Channel2 key={rowIndex} rowData={rowData} />
            ))} */}

              {sortedUpcomingSessionClass
                .slice(0, upcomingSessionCount)
                .map((rowData, index) => (
                  // Render upcoming session data
                  // Example: <div>{rowData.name}</div>
                  <Channel2 key={index} rowData={rowData} />
                ))}
              {/* See more/less button for upcoming classes */}
              {sortedUpcomingSessionClass.length > 5 && (
                <div className="flex items-center justify-center p-2.5 xl:p-2 text-xs font-medium text-dark">
                  <>
                    {upcomingSessionCount <
                    sortedUpcomingSessionClass.length ? (
                      <button
                        onClick={handleShowMoreUpcomingSessions}
                        className="mr-2"
                      >
                        Show More
                      </button>
                    ) : (
                      <button
                        onClick={handleShowLessUpcomingSessions}
                        className="ml-2"
                      >
                        Show Less
                      </button>
                    )}
                  </>
                </div>
              )}
            </div>
          ) : (
            <p>No Upcoming Sessions</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
