import { useContext, useEffect, useState } from "react";

import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import moment from "moment/moment";
import { getStartEndTime } from "../../context/CalendarDates";
import { AuthContext } from "../../context/AuthContext";
import LoadingBlock from "../../Components/LoadingBlock";

function EditStudentAttendance() {
  const { attendanceId } = useParams();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const navigate = useNavigate();

  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  let redirect;
  if (user.role === "superadmin") {
    redirect = "/class";
  } else if (user.role === "tutor" || user.role === "nontutor") {
    redirect = "/calendar";
  }

  const [state, setState] = useState({
    event_id: attendanceId,
    attendance_status: "", // Provide initial structure
    details: "",
  });

  const attendance_status = {
    UNRECORDED: "Unrecorded",
    PRESENT: "Present",
    ABSENT: "Absent",
    TUTOR_CANCELED: "Tutor Canceled",
  };

  function fetchEventDetail() {
    setLoading(true);
    api
      .get(`attendance-detail/${attendanceId}`)
      .then((res) => {
        let data = res.data.data;

        setState({
          ...state,
          attendance_status: data.attendance_status,
          details: data.details,
          first_name: data.student.first_name,
          last_name: data.student.last_name,
          start_time: getStartEndTime(data.start_date.raw, user.timezone),
          end_time: getStartEndTime(data.end_date.raw, user.timezone),
          subject: data.event.subject.title,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchEventDetail();
  }, []);

  function handleAttendanceChange(e) {
    const { value } = e.target;
    setState((prevState) => ({
      ...prevState,
      attendance_status: value,
    }));
  }

  const handleChange = (value) => {
    setState({ ...state, details: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    console.log("data", state);
    try {
      const response = await api({
        method: "post",
        url: `/update-attendance/${attendanceId}`,
        data: state,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Attendace Updated Successfully !");
        navigate(redirect);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form !");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong !");
      }
    }
  }

  if (loading) {
    return <LoadingBlock />;
  }

  return (
    <div className=" max-w-screen-3xl md:px-8 px-4  py-4  flex-grow bg-white rounded-lg lg:ml-7 lg:mr-4 mx-5.5 ">
      <div className="mb-6 mt-6">
        <div className="w-full items-center">
          <div className="">
            <div className="text-xl text-amber-500 font-medium mb-6 ml-10">
              {state?.first_name} {state?.last_name}
            </div>
            <div className="w-full flex py-8 px-10 border border-gray-200 text-black rounded bg-main ">
              <div className="mr-14">
                Date:{" "}
                {state
                  ? moment(state?.data?.start_date).format("MMMM D, YYYY")
                  : "Loading..."}
              </div>
              <div>
                Time: {state?.start_time} - {state?.end_time},
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full border rounded-md px-8 mt-12 relative bg-main ">
        <div>
          <h1 className="mt-5 text-lg font-semibold">Attendance</h1>
          <p className="mb-10">
            Record attendance for this {state?.subject} class.
          </p>
        </div>
        <div className="mb-4 mt-4">
          <div className="w-full items-center">
            <div className="w-1/4 text-base font-medium">Attendance Status</div>
            {Object.entries(attendance_status).map(([name, label], index) => (
              <div key={index}>
                <input
                  type="radio"
                  className="border border-gray-400 rounded p-2 m-4"
                  name="attendance_status"
                  value={name}
                  checked={state?.attendance_status === name}
                  onChange={handleAttendanceChange}
                />
                {label}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full border rounded-md px-8 mt-12 relative bg-main ">
        <div>
          <h1 className="mt-5 text-lg font-semibold">Lesson Notes</h1>
          <p className="mb-10">Lesson Notes for the {state?.subject} class.</p>
        </div>
        <div>
          <label>Attendance Notes:</label>
          <div>
            <ReactQuill
              value={state.details}
              className="w-full h-[180px] mb-8 pb-10 bg-white"
              name="details"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 ml-1">
        <button
          className=" bg-amber-500 text-white px-4 py-2 rounded mr-2"
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {processing ? "Please wait..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default EditStudentAttendance;
