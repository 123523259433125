import { createBrowserRouter } from "react-router-dom";
import GuestLayout from "../Components/auth/layout/Guestlayout";
import AuthLayout from "../Components/auth/layout/Authlayout";
import Root from "../Components/auth/layout/Root";
import AuthRoutes from "./AuthRoute";
//import Tutoradmin from "../Components/Tutors/Tutoradmin";
//import Tutordetails from "../Components/Tutors/TutorDetail";
import Student from "../pages/Students/Student";
import AddStudent from "../pages/Students/AddStudent";
// import Student from "../Components/Students/Student";
// import AddStudent from "../Components/Modals/AddStudent";
import EditStudent from "../pages/Students/EditStudent";
import AddParent from "../pages/Parents/AddParent";
import SubjectList from "../pages/Subjects/Subject";
import TutorList from "../pages/Tutors/Tutor";
import AddTutor from "../pages/Tutors/AddTutor";
import AddSubject from "../pages/Subjects/AddSubject";
import EditSubject from "../pages/Subjects/EditSubject";
//import EditTutor from "../pages/Tutors/EditTutor";
//import EditTutorDetails from "../Components/Tutors/EditTutorDetail";
import EventCalendar from "../pages/Calendar/EventCalendar";
//import TutoraTab from "../pages/Students/StudentTab.js/TutorTab";
import AddCalendarEvent from "../pages/Calendar/AddCalendarEvent";
//import AssignStudent from "../Components/Tutors/AssignStudent";
import StudentAttendance from "../pages/Attendance/StudentAttendance";
import ClassList from "../pages/ClassEvent/ClassList";
import EditStudentAttendance from "../pages/Attendance/EditStudentAttendance";
import EmailMessage from "../pages/Message/EmailMessage";
import TutorStudent from "../pages/User/Tutor/TutorStudent";
import StudentTutorList from "../pages/User/Student/StudentTutorList";
import ParentTutorList from "../pages/User/Parent/ParentTutorList";
import Dashboard from "../pages/Dashboard/Dashboard";
import TutorMessage from "../pages/Message/TutorMessage";
import SessionHistory from "../pages/SessionHistory/SessionHistory";
import HistoryDetail from "../pages/SessionHistory/HistoryDetail";
import ParentEmail from "../pages/Message/PrivateEmail/ParentEmail";
import TutorEmail from "../pages/Message/PrivateEmail/TutorEmail";
import PageNotFound from "../pages/Errors/PageNotFound";
import Parents from "../pages/Parents/Parents";
import MyProfile from "../pages/Setting/MyProfile";
import EditEvent from "../pages/Calendar/EditEvent";
import EmailLog from "../pages/EmailLog/EmailLog";
import ViewEmailLog from "../pages/EmailLog/ViewEmailLog";
import ProtectedRoute from "../utils/ProtectedRoute";
import EditTutor from "../pages/Tutors/EditTutor";
import EditParent from "../pages/Parents/EditParent";
import ParentMessage from "../pages/Message/ParentMessage";
//import ClassReminder from "../pages/Email/ClassReminder";

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        element: <GuestLayout />,
        children: AuthRoutes,
      },

      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              {
                path: "/dashboard",
                element: <Dashboard />,
              },

              {
                path: "/tutor",
                element: <TutorList />,
              },
              {
                path: "/add-tutor",
                element: <AddTutor />,
              },

              // {
              //   path: "/tutor/edit/:tutorId",
              //   element: <EditTutor />,
              // },
              // {
              //   path: "/tutor-admin",
              //   element: <Tutoradmin />,
              // },
              {
                path: "/tutor/:tutorId",
                element: <EditTutor />,
              },
              // {
              //   path: "/tutor-tab",
              //   element: <TutoraTab />,
              // },
              // {
              //   path: "/assign-student",
              //   element: <AssignStudent />,
              // },

              // {
              //   path: "/edit-tutor-detail",
              //   element: <EditTutorDetails />,
              // },

              {
                path: "/student",
                element: <Student />,
              },
              {
                path: "/add-student",
                element: <AddStudent />,
              },
              {
                path: "add-student/:studentId/parent/add",
                element: <AddParent />,
              },

              {
                path: "/student/edit/:studentId",
                element: <EditStudent />,
              },

              {
                path: "/parent",
                element: <Parents />,
              },

              {
                path: "/parent/:parentId",
                element: <EditParent />,
              },

              {
                path: "/subject",
                element: <SubjectList />,
              },

              {
                path: "/add-subject",
                element: <AddSubject />,
              },
              {
                path: "/subject/edit/:subjectId",
                element: <EditSubject />,
              },
              {
                path: "/calendar",
                element: <EventCalendar />,
              },

              {
                path: "/calendar/add",
                element: <AddCalendarEvent />,
              },

              {
                path: "/class",
                element: <ClassList />,
              },

              {
                path: "/session-history",
                element: <SessionHistory />,
              },

              {
                path: "/session-history/:attendanceId",
                element: <HistoryDetail />,
              },

              {
                path: "/edit-event/:sessionId",
                element: <EditEvent />,
              },

              {
                path: "/attendance/:attendanceId",
                element: <StudentAttendance />,
              },
              {
                path: "/edit-attendance/:attendanceId",
                element: <EditStudentAttendance />,
              },

              //send individual, parent and teacher

              {
                path: "/parent/send-message/:parentId",
                element: <ParentEmail />,
              },

              {
                path: "/tutor/send-message/:tutorId",
                element: <TutorEmail />,
              },

              // send message from calendar
              {
                path: "/message/:sessionId",
                element: <EmailMessage />,
                //element: <ClassList />,
              },

              //send message to students and their parent from tutor
              {
                path: "/tutor/message/:stuId",
                element: <ParentMessage />,
              },

              //send message to tutor from student
              {
                path: "/student/message/:tutorId",
                element: <TutorMessage />,
              },

              //send message to tutor from parent
              {
                path: "/parent/message/:tutorId",
                element: <TutorMessage />,
              },

              {
                path: "/tutor-student",
                element: <TutorStudent />,
              },

              {
                path: "/student-tutor",
                element: <StudentTutorList />,
              },

              {
                path: "/parent-tutor",
                element: <ParentTutorList />,
              },

              //email logs

              {
                path: "email-log",
                element: <EmailLog />,
              },

              {
                path: "view-log/:logId",
                element: <ViewEmailLog />,
              },

              //settings
              {
                path: "settings",
                children: [
                  {
                    path: "profile",
                    element: <MyProfile />,
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

export default router;
