import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LoadingBlock from "../../Components/LoadingBlock";
import {
  getDashStartEndDates,
  getStartEndTime,
} from "../../context/CalendarDates";
import { getAuthUserInfo } from "../../utils/helpers";

function HistoryDetail() {
  const { attendanceId } = useParams();
  const { data, loading, error } = useFetch(`session-detail/${attendanceId}`);
  const user = getAuthUserInfo();
  //console.log("data", data);

  const daysName = {
    SU: "Sunday",
    MO: "Monday",
    TU: "Tuesday",
    WE: "Wednesday",
    TH: "Thursday",
    FR: "Friday",
    SA: "Saturday",
  };

  // Helper function to get full day name
  const getFullDayName = (dayAbbreviation) => {
    return daysName[dayAbbreviation] || dayAbbreviation;
  };

  if (loading) {
    return <LoadingBlock />;
  }

  return (
    <div className="mx-auto max-w-screen-3xl lg:px-4 lg:ml-3 px-4 mr-1 ml-1 flex-grow flex flex-row">
      {/* Left Column - General and Attendance Details */}
      <div className="w-1/2 pr-8">
        {/* General Details Block */}
        <div className="bg-white p-6 mb-6 rounded-md">
          <h2 className="text-xl font-bold mb-4">General Details</h2>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Titte:</label>
            <p>{data?.data?.event?.topic}</p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Start Date:</label>
            <p>{getDashStartEndDates(data?.data?.start_date, user.timezone)}</p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">End Date:</label>
            <p>{getDashStartEndDates(data?.data?.end_date, user.timezone)}</p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Start Time:</label>
            <p>{getStartEndTime(data?.data?.start_date.raw, user.timezone)}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">End Time:</label>
            <p>{getStartEndTime(data?.data?.end_date.raw, user.timezone)}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">
              Class Duration:
            </label>
            <p>{data?.data?.duration} Mins</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Class Repeat:</label>
            <p>{data?.data?.repeat}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Repeat Day:</label>
            <p>{getFullDayName(data?.data?.repeat_day)}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Subject:</label>
            <p>{data?.data?.subject.title}</p>
          </div>

          {/* Add other general details similarly */}
        </div>

        {/* Attendance Details Block */}
        <div className="bg-white p-6 mb-6 rounded-md">
          <h2 className="text-xl font-bold mb-4">Attendance Details</h2>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">
              Attendance Taken:
            </label>
            <p>
              {(data?.data?.attendance === 1 ? "Yes" : "No")
                ? data?.data?.attendance === 1
                  ? "Yes"
                  : "No"
                : "---"}
            </p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">
              Attendance Status:
            </label>
            <p>
              {data?.data?.attendance_status
                ? data?.data?.attendance_status
                : "---"}
            </p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Lesson Notes:</label>
            {/* <p>{data?.data?.attendanceDetail.details}</p> */}
            <p
              dangerouslySetInnerHTML={{
                __html: data?.data?.details,
              }}
            ></p>
          </div>
          {/* Add other attendance details similarly */}
        </div>
      </div>

      {/* Right Column - Tutor, Student, and Parent Details */}
      <div className="w-1/2 ">
        {/* Tutor Details Block */}
        <div className="bg-white p-6 mb-6 rounded-md">
          <h2 className="text-xl font-bold mb-4">Assigned Tutor Details</h2>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">First Name:</label>
            <p>{data?.data?.tutor.first_name}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Last Name:</label>
            <p>
              {data?.data?.tutor.last_name
                ? data?.data?.tutor.last_name
                : "---"}
            </p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Email:</label>
            <p>{data?.data?.tutor.email}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Phone:</label>
            <p>
              {data?.data?.tutor.phone_no ? data?.data?.tutor.phone_no : "---"}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Address:</label>
            <p>
              {data?.data?.tutor.address ? data?.data?.tutor.address : "---"}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Subject:</label>
            <p>{data?.data?.tutor.subject.title}</p>
          </div>
          {/* Add other tutor details similarly */}
        </div>

        {/* Student Details Block */}
        <div className="bg-white p-6 mb-6 rounded-md">
          <h2 className="text-xl font-bold mb-4">Assigned Student Details</h2>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">First Name:</label>
            <p>{data?.data?.student.first_name}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Last Name:</label>
            <p>
              {data?.data?.student.last_name
                ? data?.data?.student.last_name
                : "---"}
            </p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Email:</label>
            <p>{data?.data?.student.email}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Phone:</label>
            <p>
              {data?.data?.student.phone_no
                ? data?.data?.student.phone_no
                : "---"}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <label className="w-1/4 text-md font-semibold">Address:</label>
            <p>
              {data?.data?.student.address
                ? data?.data?.student.address
                : "---"}
            </p>
          </div>
          {/* Add other student details similarly */}
        </div>

        {/* Parent Details Block */}
        {data.data?.parent && (
          <div className="bg-white p-6 mb-6 rounded-md">
            <h2 className="text-xl font-bold mb-4">Parent Details</h2>
            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">First Name:</label>
              <p>
                {data?.data?.parent.first_name
                  ? data?.data?.parent.first_name
                  : "---"}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">Last Name:</label>
              <p>
                {data?.data?.parent.last_name
                  ? data?.data?.parent.last_name
                  : "---"}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">Email:</label>
              <p>
                {data?.data?.parent.email ? data?.data?.parent.email : "---"}
              </p>
            </div>

            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">Phone:</label>
              <p>
                {data?.data?.parent.phone_no
                  ? data?.data?.parent.phone_no
                  : "---"}
              </p>
            </div>
            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">Address:</label>
              <p>
                {data?.data?.parent.address
                  ? data?.data?.parent.address
                  : "---"}
              </p>
            </div>
            {/* Add other parent details similarly */}
          </div>
        )}
      </div>
    </div>
  );
}

export default HistoryDetail;
