import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { loginAsAdmin } from "../../utils/Admin";

const LoggedInAsUserTopbar = () => {
  const { getAuthUser } = useContext(AuthContext);
  const user = getAuthUser();

  return (
    <header className="flex lg:flex-row flex-col items-center w-full ml-40">
      <div className="lg:text-base hidden lg:block text-sm ">
        You are logged in as {user?.role}.
      </div>
      <div className="flex">
        <button
          type="button"
          onClick={loginAsAdmin}
          className="text-white px-2 lg:px-5 py-1 bg-lime-600 rounded-md border justify-center ml-2 lg:text-base text-xs"
        >
          Back to Admin
        </button>
      </div>
    </header>
  );
};

export default LoggedInAsUserTopbar;
