export default function Avatar({ user }) {
  const userImage = user.profile_pic || null;
  const initials =
    user.first_name.charAt(0) +
    (user.last_name ? user.last_name.charAt(0) : "");
  return (
    <>
      {userImage ? (
        <img
          className="h-10 w-10 object-cover"
          src={userImage}
          alt="User Profile"
        />
      ) : (
        <div className="h-10 w-10 flex items-center justify-center rounded-full bg-gray-300">
          <span className="text-gray-600 text-lg">{initials}</span>
        </div>
      )}
    </>
  );
}
