import { EditingState, ViewState } from "@devexpress/dx-react-scheduler";
import {
  AppointmentForm,
  AppointmentTooltip,
  Appointments,
  ConfirmationDialog,
  DateNavigator,
  DragDropProvider,
  EditRecurrenceMenu,
  MonthView,
  Scheduler,
  Toolbar,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  TableCell,
  alpha,
  darken,
  lighten,
  styled,
  Button,
  Paper,
} from "@mui/material";
import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LoadingBlock from "../../Components/LoadingBlock";
import ClassEventDetail from "../../Components/Calendar/ClassEventDetail";
import { getStartEndTime } from "../../context/CalendarDates";
import { AuthContext } from "../../context/AuthContext";
import CalendarContexMenu from "../../Components/Calendar/CalendarContextMenu";
import { CalendarSelectedDate } from "../../context/CalendarSelectedDate";
import ContextMessage from "../../Components/Calendar/ContextMessage";
import "./calendar.css";

const EventCalendar = () => {
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  let url;
  switch (user.role) {
    case "superadmin":
      url = "/list-calendar-schedule";
      break;
    case "tutor":
    case "nontutor":
      url = `/tutor-class-list/${user.id}`;
      break;
    case "student":
      url = `/student-class-list/${user.id}`;
      break;
    case "parent":
      url = `/parent-class-list/${user.id}`;
      break;
    default:
      break;
  }

  const currentMonth = new Date();
  //console.log("EventCalendar is rendered");

  //get schedule class list
  const { data: schedule, loading, error } = useFetch(url);

  const PREFIX = "proactive";
  const classes = {
    cell: `${PREFIX}-cell`,
    content: `${PREFIX}-content`,
    text: `${PREFIX}-text`,
    //sun: `${PREFIX}-sun`,
    //cloud: `${PREFIX}-cloud`,
    //rain: `${PREFIX}-rain`,
    //sunBack: `${PREFIX}-sunBack`,
    //cloudBack: `${PREFIX}-cloudBack`,
    //rainBack: `${PREFIX}-rainBack`,
    opacity: `${PREFIX}-opacity`,
    appointment: `${PREFIX}-appointment`,
    apptContent: `${PREFIX}-apptContent`,
    flexibleSpace: `${PREFIX}-flexibleSpace`,
    flexContainer: `${PREFIX}-flexContainer`,
    tooltipContent: `${PREFIX}-tooltipContent`,
    tooltipText: `${PREFIX}-tooltipText`,
    title: `${PREFIX}-title`,
    icon: `${PREFIX}-icon`,
    circle: `${PREFIX}-circle`,
    textCenter: `${PREFIX}-textCenter`,
    dateAndTitle: `${PREFIX}-dateAndTitle`,
    titleContainer: `${PREFIX}-titleContainer`,
    container: `${PREFIX}-container`,
    multipleSessions: `${PREFIX}-multipleSessions`,
  };

  // Add a new styled component for the title container
  // const StyledTitleContainer = styled('div')(() => ({
  //   [`&.${classes.titleContainer}`]: {
  //     backgroundColor: '#FF7043', // Set the desired background color
  //   },
  // }));

  const getBorder = (theme) =>
    `1px solid ${
      theme.palette.mode === "light"
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
    }`;

  const DayScaleCell = (props) => (
    <MonthView.DayScaleCell
      {...props}
      style={{ textAlign: "center", fontWeight: "bold" }}
    />
  );

  // const StyledOpacity = styled(Opacity)(() => ({
  //   [`&.${classes.rain}`]: {
  //     color: '#4FC3F7',
  //   },
  // }));
  // const StyledWbSunny = styled(WbSunny)(() => ({
  //   [`&.${classes.sun}`]: {
  //     color: '#FFEE58',
  //   },
  // }));
  // const StyledFilterDrama = styled(FilterDrama)(() => ({
  //   [`&.${classes.cloud}`]: {
  //     color: '#90A4AE',
  //   },
  // }));

  // const WeatherIcon = ({ id }) => {
  //   switch (id) {
  //     case 0:
  //       return <StyledOpacity className={classes.rain} fontSize="large" />;
  //     case 1:
  //       return <StyledWbSunny className={classes.sun} fontSize="large" />;
  //     case 2:
  //       return <StyledFilterDrama className={classes.cloud} fontSize="large" />;
  //     default:
  //       return null;
  //   }
  // };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${classes.cell}`]: {
      color: "#78909C!important",
      position: "relative",
      userSelect: "none",
      verticalAlign: "top",
      padding: 0,
      height: 130,
      width: 130,
      borderLeft: getBorder(theme),
      "&:first-of-type": {
        borderLeft: "none",
      },
      "&:last-child": {
        paddingRight: 0,
      },
      "tr:last-child &": {
        borderBottom: "none",
      },
      "&:hover": {
        backgroundColor: "white",
        cursor: "pointer",
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        outline: 0,
      },
    },
    // [`&.${classes.sunBack}`]: {
    //   backgroundColor: "#FFFDE7",
    // },
    // [`&.${classes.cloudBack}`]: {
    //   backgroundColor: "#ECEFF1",
    // },
    // [`&.${classes.rainBack}`]: {
    //   backgroundColor: "#E1F5FE",
    // },
    [`&.${classes.opacity}`]: {
      opacity: "0.5",
    },
  }));

  const StyledDivText = styled("div")(() => ({
    [`&.${classes.text}`]: {
      padding: "0.5em",
      textAlign: "center",
    },
  }));

  const StyledDivContent = styled("div")(() => ({
    [`&.${classes.content}`]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
    },
  }));

  const StyledAppointmentsAppointment = styled(Appointments.Appointment)(
    () => ({
      [`&.${classes.appointment}`]: {
        borderRadius: "10px",
        "&:hover": {
          opacity: 0.6,
        },
      },
    })
  );

  const StyledAppointmentsAppointmentContent = styled(
    Appointments.AppointmentContent
  )(() => ({
    [`&.${classes.apptContent}`]: {
      "&>div>div": {
        whiteSpace: "normal !important",
        lineHeight: 1.2,
      },
    },
  }));

  const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
    [`&.${classes.flexibleSpace}`]: {
      flex: "none",
    },
    [`& .${classes.flexContainer}`]: {
      display: "flex",
      alignItems: "center",
    },
  }));

  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(schedule?.data)) {
      const formattedAppointments = schedule.data.flatMap((item) => {
        const mainTitle = (
          <>
            {getStartEndTime(item.start_date.raw, user.timezone)} -
            {getStartEndTime(item.end_date.raw, user.timezone)}
            <br />
            {item.student.first_name} {item.student.last_name} <br /> &nbsp;
            {item.tutor.first_name} {item.tutor.last_name} <br /> &nbsp;
            {item.subject.title} <br />
          </>
        );
        if (item.repeat === "daily") {
          return [
            {
              id: item.id,
              title: mainTitle,
              topic: item.event.topic,
              startDate: new Date(item.start_date.raw),
              endDate: new Date(item.end_date.raw),
              startTime: getStartEndTime(item.start_date.raw, user.timezone),
              endTime: getStartEndTime(item.end_date.raw, user.timezone),
              attendanceTaken: item.attendance,
              recurring: item.type,
              eventId: item.event_id,
              subjectTitle: item.subject.title,
              tutorTitle: item.tutor.first_name + " " + item.tutor.last_name,
              studentTitle:
                item.student.first_name + " " + item.student.last_name,
              zoomLink: item.zoom_link,
              meetingID: item.meeting_id,
              passCode: item.passcode,
            },
          ];
        } else if (item.repeat === "weekly") {
          return generateWeeklyAppointments(item);
        }
        return [];
      });
      //console.log('Formatted Appointments:', formattedAppointments);

      setData(formattedAppointments);
    }
  }, [schedule]);

  const generateWeeklyAppointments = (item) => {
    const startDateTime = new Date(item.start_date.raw);
    const endDateTime = new Date(item.end_date.raw);
    const daysOfWeek = item.repeat_day;

    const appointments = [];
    let currentDate = new Date(startDateTime);

    // console.log('current date', currentDate);
    // console.log('end date time', endDateTime);

    while (currentDate <= endDateTime) {
      const recurringIcon =
        item.type === "FREQ" ? (
          <div
            className="HorizontalAppointment-imageContainer"
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "1",
            }}
          >
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium HorizontalAppointment-image css-vubbuv"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="RepeatIcon"
              style={{ fill: "#fff" }}
            >
              <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z"></path>
            </svg>
          </div>
        ) : null;

      const mainTitle = (
        <>
          {recurringIcon}
          {getStartEndTime(item.start_date.raw, user.timezone)} -
          {getStartEndTime(item.end_date.raw, user.timezone)}
          <br />
          {item.student.first_name} {item.student.last_name} <br /> &nbsp;
          {item.tutor.first_name} {item.tutor.last_name} <br /> &nbsp;
          {item.subject.title} <br />
        </>
      );
      if (
        daysOfWeek.includes(
          ["SU", "MO", "TU", "WE", "TH", "FR", "SA"][currentDate.getDay()]
        )
      ) {
        appointments.push({
          id: item.id,
          title: mainTitle,
          startDate: new Date(currentDate),
          endDate: new Date(
            currentDate.getTime() + (endDateTime - startDateTime)
          ),
          startTime: getStartEndTime(item.start_date.raw, user.timezone),
          endTime: getStartEndTime(item.end_date.raw, user.timezone),
          attendanceTaken: item.attendance,
          recurring: item.type,
          studentTitle: item.student.first_name + " " + item.student.last_name,
          eventId: item.event_id,
          subjectTitle: item.subject.title,
          tutorTitle: item.tutor.first_name + " " + item.tutor.last_name,
          //studentTitle: item.student_id,
          zoomLink: item.zoom_link,
          meetingID: item.meeting_id,
          passCode: item.passcode,
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return appointments;
  };

  //console.log("Data in State: ", data);

  //console.log('class', data);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  const isFutureDate = selectedDate
    ? new Date(selectedDate) >= new Date().setHours(0, 0, 0, 0) : false;

    console.log('future date'+isFutureDate);

  const { isClickedDate, setIsClickeddDate } = useContext(CalendarSelectedDate);

  const onDateCellClick = useCallback(
    (e) => {
      //console.log("Date cell clicked:", e);
      const { cellData } = e;
      const selectedDate = new Date(cellData.startDate);
      setSelectedDate(selectedDate);
      setIsClickeddDate(selectedDate);
      //setSelectedDate(new Date(cellData.startDate));
      setIsCustomModalOpen(true);
    },
    [setIsClickeddDate]
  );

  const calculateNumberOfSessionsForDate = (date) => {
    // Assuming you have access to the data containing sessions
    // Iterate over the data and count the sessions for the given date
    const numberOfSessions = data.filter((session) => {
      const sessionDate = new Date(session.startDate);
      return sessionDate.toDateString() === date.toDateString();
    }).length;

    return numberOfSessions;
  };

  const CellBase = React.memo(({ startDate, formatDate, otherMonth }) => {
    //const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
    const isFirstMonthDay = startDate.getDate() === 1;
    const formatOptions = isFirstMonthDay
      ? { day: "numeric", month: "long" }
      : { day: "numeric" };

    const numberOfSessions = calculateNumberOfSessionsForDate(startDate);

    //console.log("number of session", numberOfSessions);

    // const handleViewMoreClick = () => {
    //   // Implement logic to handle the click event
    //   // For example, display a modal or navigate to another page
    // };

    return (
      <StyledTableCell
        tabIndex={0}
        className={classNames({
          [classes.cell]: true,
          [classes.opacity]: otherMonth,
          [classes.multipleSessions]: numberOfSessions > 1, // Add a class if there are more than 2 sessions

          // [classes.rainBack]: iconId === 0,
          // [classes.sunBack]: iconId === 1,
          // [classes.cloudBack]: iconId === 2,
        })}
        //style={{ backgroundColor: getBackgroundColor() }}

        onClick={() => onDateCellClick({ cellData: { startDate } })}
      >
        <StyledDivContent className={classes.content}>
          {/* Display the "View More" button if there are more than 2 sessions */}
          {/* {numberOfSessions > 2 && (
            <Button onClick={handleViewMoreClick}>View More</Button>
          )} */}
        </StyledDivContent>
        <StyledDivText className={classes.text}>
          <div style={{ fontSize: 18, textAlign: "right" }}>
            {formatDate(startDate, formatOptions)}
          </div>
        </StyledDivText>
      </StyledTableCell>
    );
  });

  const TimeTableCell = CellBase;

  //  const Appointment = ({ ...restProps }) => (

  //   <StyledAppointmentsAppointment
  //     {...restProps}
  //     className={classes.subjectBg}
  //     //key={data.id}
  //   />
  // );

  const Appointment = ({ ...restProps }) => {
    // Generate a unique background color based on the subject
    const getBackgroundColor = (subject) => {
      // For simplicity, let's assume we have predefined colors for different subjects
      const colors = {
        Math: "#249cd0",
        Science: "#489c39",
        English: "#69acb2",

        // Add more colors for other subjects as needed
      };
      // Return the color based on the subject, or a default color if not found
      return colors[subject] || "#9E9E9E"; // Default color
    };

    return (
      <StyledAppointmentsAppointment
        {...restProps}
        className={classes.subjectBg}
        style={{
          backgroundColor: getBackgroundColor(restProps.data.subjectTitle),
        }}
      />
    );
  };

  const FlexibleSpace = ({ ...restProps }) => (
    <StyledToolbarFlexibleSpace
      {...restProps}
      className={classes.flexibleSpace}
    >
      {/* {user.role === "superadmin" && (
        <div className={classes.flexContainer}>
          <Link to={"/calendar/add"}>
            <Button variant="contained" color="primary">
              Add Class
            </Button>
          </Link>
        </div>
      )} */}
    </StyledToolbarFlexibleSpace>
  );

  const AppointmentContent = ({ ...restProps }) => (
    <StyledAppointmentsAppointmentContent
      {...restProps}
      className={classes.apptContent}
    />
  );

  const commitChanges = ({ added, changed, deleted }) => {
    // console.log("added", added);
    // console.log("changed", changed);
    // console.log("deleted", deleted);
    // Clone the data to avoid mutating the original state
    // let changedData = [...data];
    // if (changed) {
    //   console.log("changed data", changedData);
    //   changedData = data.map((appointment) =>
    //     changed[appointment.id]
    //       ? { ...appointment, ...changed[appointment.id] }
    //       : appointment
    //   );
    // }
    // setData(changedData);
  };

  if (loading) {
    return <LoadingBlock>Loading...</LoadingBlock>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="flex-grow mx-auto w-full max-w-fit px-5 lg:px-4 lg:py-4 lg:ml-3 ">
      <Paper>
        <Scheduler data={data}>
          <EditingState onCommitChanges={commitChanges} />

          <ViewState defaultCurrentDate={currentMonth} />

          <EditRecurrenceMenu />

          <MonthView
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />

          <Appointments
            appointmentComponent={Appointment}
            //appointmentComponent={appointmentComponent}
            appointmentContentComponent={AppointmentContent}
          />

          <Toolbar flexibleSpaceComponent={FlexibleSpace} />

          <DateNavigator />

          <AppointmentTooltip
            showCloseButton
            contentComponent={ClassEventDetail}
          />

          <AppointmentForm
            onVisibilityChange={setIsCustomModalOpen}
            visible={false}
          />
        </Scheduler>
      </Paper>

      {isFutureDate && user.role === "superadmin" && (
        <CalendarContexMenu
          isOpen={isCustomModalOpen}
          setIsModalOpen={setIsCustomModalOpen}
          clickedDate={selectedDate}
        />
      )}

      {!isFutureDate && (
        <ContextMessage
          isOpen={isCustomModalOpen}
          setIsModalOpen={setIsCustomModalOpen}
        />
      )}
    </section>
  );
};

export default EventCalendar;
