import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../api";
import LoadingBlock from "../../../Components/LoadingBlock";
import {
  getDashStartEndDates,
  getStartEndTime,
} from "../../../context/CalendarDates";
import AttendanceStatus from "../../../Components/layouts/AttendanceStatus";
import { getAuthUserInfo } from "../../../utils/helpers";
import Pagination from "../../../Components/Pagination";

export default function AttendanceNotesDetail() {
  const { tutorId } = useParams();
  const [loading, setLoading] = useState(false);
  const [assignedStudent, setAssignedStudent] = useState([]);
  const user = getAuthUserInfo();
  //fetch students

  function fetchAssignedStudent(page = 1) {
    setLoading(true);
    api
      .get(`assigned-student/${tutorId}`, {
        //params: { page },
        params: {
          page,
        },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setAssignedStudent(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchAssignedStudent();
    //}, []);
  }, []);

  console.log("assinged", assignedStudent);

  return (
    <div className="w-full flex items-center table-fixed overflow-x-auto ">
      <div className="w-5/6 ml-4 flex flex-col">
        <div className="mb-4">
          <p className="text-dark font-bold  px-2">
            Students Attendance and Notes Details.
          </p>
        </div>
        <table className="w-full divide-y divide-gray-200 overflow-x-auto">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Full Name</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Start Date/Time</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Attendance</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {loading && (
              <tr>
                <td colSpan="4" className="text-center py-4">
                  <LoadingBlock loading={loading} />
                </td>
              </tr>
            )}
            {assignedStudent?.data?.length > 0 ? (
              assignedStudent.data.map((assignment, index) =>
                assignment.sessions.map((session, sessionIndex) => (
                  <tr key={sessionIndex}>
                    <td className="text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                      {session.student?.first_name} {session.student?.last_name}
                    </td>

                    <td className="text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                      {getDashStartEndDates(session.start_date.date_time, user.timezone)},{" "}
                      {getStartEndTime(session.start_date.raw, user.timezone)}-
                      {getStartEndTime(session.end_date.raw, user.timezone)}
                    </td>
                    <td className="text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                      <AttendanceStatus status={session.attendance_status} />
                    </td>
                  </tr>
                ))
              )
            ) : (
              <tr>
                <td colSpan="10" className="text-center py-4">
                  No Assined Student available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {assignedStudent?.data?.length > 0 &&
            assignedStudent.data[0].sessions?.meta && (
              <Pagination
                meta={assignedStudent.data[0].sessions?.meta}
                onChange={(page) => fetchAssignedStudent(page)}
              />
            )}
      </div>

      {/* {isPopupOpen && <AssignStudentPopup  onClose={closePopup} />} */}
    </div>
  );
}
