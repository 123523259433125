import React, { useState } from "react";
import PersonalTab from "./StudentTab.js/PersonalTabOld";
import TutorTab from "./StudentTab.js/TutorTab";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LoadingBlock from "../../Components/LoadingBlock";
import { Actiontab } from "./StudentTab.js/Actiontab";
import ViewStudentDetail from "./StudentTab.js/ViewStudentDetail";
import ViewParent from "./StudentTab.js/ViewParent";

export default function EditStudent() {
  const [activeTab, setActiveTab] = useState("Personal");
  const { studentId } = useParams();

  const tabs = [
    "Personal",
    "Tutors",
    "Family",
    "Action",
    //"Availability",
    //"Payroll",
    //"Default Settings",
  ];
  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };

  // get student detail
  const { data: student, loading } = useFetch(`edit-student/${studentId}`);
  //console.log(student);

  //get parent detail from student details
  const { data: parent, loading: parentLoading } = useFetch(
    `edit-parent/${student?.data?.parent_id}`
  );

  //get parent detail from student details
  const { data: tutor, loading: tutorLoading } = useFetch(
    `assigned-tutors/${studentId}`
  );

  //console.log('parent', parent);

  if (loading || parentLoading || tutorLoading) {
    return <LoadingBlock></LoadingBlock>;
  }
  return (
    <div className="mx-auto px-5 py-4 ml-7 mr-4 mt-4 lg:mb-8 bg-white rounded-lg">
      <div className="space-x-4 flex-row lg:pt-4 ml-5">
        {/* Desktop and Tablet view - Dropdown */}
        <select
          className="md:px-20 px-12 py-2 border rounded-md lg:hidden w-full xsm:mb-3  md:w-5/6"
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabs.map((tab) => (
            <option key={tab} value={tab}>
              {tab}
            </option>
          ))}
        </select>

        <div className="hidden space-x-4 lg:mb-8 lg:flex">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`text-left text-base px-6 py-2 max-w-fit cursor-pointer border rounded-md ${
                activeTab === tab
                  ? "bg-amber-500 text-white"
                  : "bg-main text-neutral-700"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="w-full">
          <div>
            {activeTab === "Personal" && (
              <div>
                {/* <PersonalTab student={student} loading={loading} /> */}
                <ViewStudentDetail />
                {/* {console.log("Form data in EditStudent:", student)} */}
              </div>
            )}
            {activeTab === "Tutors" && (
              <div>
                <TutorTab tutor={tutor} />
              </div>
            )}
            {activeTab === "Family" && (
              <div>
                <ViewParent parent={parent} />
                {/* {console.log("Form data in EditStudent:", parent)} */}
              </div>
            )}
            {activeTab === "Action" && (
              <div>
                <Actiontab userId={studentId}/>
              </div>
            )}
            {/* {activeTab === "Availability" && (
              <div>Content for Availability</div>
            )}
            {activeTab === "Payroll" && <div>Content for Payroll</div>}
            {activeTab === "Default Settings" && (
              <div>Content for Default Settings</div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
