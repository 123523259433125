import React, { useState } from "react";
import Errors from "../../utils/Errors";
import { useNavigate, Link } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { TimeZone } from "../../utils/TimeZone";
import Select from "react-select";

const AddStudent = () => {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [studentCount, setStudentCount] = useState(1); // Initialize count to 1

  // Options for react-select
  const timezoneOptions = TimeZone().reduce(
    (acc, group) =>
      acc.concat(
        group.zones.map((zone) => ({
          value: zone.value,
          label: `${group.group} - ${zone.name}`,
        }))
      ),
    []
  );

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    role: "student",
    timezone: "",
    //invitation_status:true,
    status: true,
  };
  const [form, setForm] = useState(initialState);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#EFF7F9",
      borderColor: state.isFocused ? "#004CBD" : "#9ca3af",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  //handle form input change
  function handleChange(e) {
    const { name, value } = e.target;
    // For other input fields
    setForm({ ...form, [name]: value });
  }

  // Handle change event for react-select
  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value);
    setForm({ ...form, timezone: selectedOption.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    // Generate a random 5-digit string
    const randomString = Math.random().toString(36).substring(2, 7);

    const formData = {
      ...form,
      email: `${form.first_name}.${form.last_name}${randomString}@proactiveacademy.com`,
    };

    console.log("form data", formData);
    //return;

    try {
      const response = await api({
        method: "post",
        url: "store",
        data: formData,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Student Created Sucessfully !");
        let studentId = response.data.data.id;
        // Increment student count
        setStudentCount((prevCount) => prevCount + 1);

        navigate(`${studentId}/parent/add`);
        setProcessing(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  return (
    <div className="mx-auto w-full  px-5 lg:my-3 lg:pb-4  lg:flex-col ">
      <div className=" inline-block  lg:ml-2 lg:mr-10 min-w-full pb-2 align-middle md:px-6 lg:px-8   max-w-fit  gap-[16px] rounded-md border  bg-white overflow-x-clip">
        <div className=" font-bold text-xl  pt-8 text-dark">
          <Link
            to="/tutor"
            className="text-blue-500 text-start text-xs w-40 mt-2 ml-2 md:mr-80 mr-4"
          >
            <FontAwesomeIcon icon={faAnglesLeft} />
            Back to Student List
          </Link>
          Add Student
        </div>

        <div className="mb-4 mt-4 md:mt-7">
          <div className="flex md:flex-row flex-col w-full justify-center  mx-4 lg:px-8 px-2  mb-4">
            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row  ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  First Name:
                </label>

                <input
                  type="text"
                  className={`w-4/6 border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal${
                    errors.has("first_name") ? "border-red-500" : ""
                  }`}
                  placeholder="Your First Name"
                  name="first_name"
                  onChange={handleChange}
                />
              </div>
              {errors.has("first_name") && (
                <div className="text-red-500 mt-1 ml-32  text-sm">
                  {errors.first("first_name")}
                </div>
              )}
            </div>

            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row   ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  Last Name:
                </label>
                <input
                  type="text"
                  className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                  placeholder="Your Last Name"
                  name="last_name"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col w-full justify-center mx-4 lg:px-8 px-2  mb-4">
            <div className="mb-4 lg:w-1/2 w-full">
              {/* <div className="w-full flex flex-row ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  Email:
                </label>

                <input
                  type="email"
                  className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                    errors.has("email") ? "border-red-500" : ""
                  }`}
                  placeholder="Your Email"
                  name="email"
                  onChange={handleChange}
                />

                {errors.has("email") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("email")}
                  </div>
                )}
              </div> */}

              <div className="w-full flex flex-row ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  TimeZone:
                </label>

                <Select
                  className={`w-4/6  bg-main text-base ${
                    errors.has("timezone") ? "border-red-500" : ""
                  }`}
                  options={timezoneOptions}
                  name="timezone"
                  onChange={handleTimeZoneChange}
                  value={timezoneOptions.find(
                    (option) => option.value === selectedTimeZone
                  )}
                  styles={customStyles}
                  placeholder="search timezone"
                />
              </div>
              {errors.has("timezone") && (
                <div className="text-red-500 mt-1 ml-32 text-sm">
                  {errors.first("timezone")}
                </div>
              )}
            </div>

            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row  ">
                <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                  Address:
                </label>
                <input
                  type="text"
                  className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* <div className="flex md:flex-row flex-col  w-full justify-center  mx-4 lg:px-8 px-2   ">
            <div className="mb-8 lg:w-1/2 w-full">
              <div className="w-full flex flex-row ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  TimeZone:
                </label>

                <Select
                  className={`w-4/6  bg-main text-base ${
                    errors.has("timezone") ? "border-red-500" : ""
                  }`}
                  options={timezoneOptions}
                  name="timezone"
                  onChange={handleTimeZoneChange}
                  value={timezoneOptions.find(
                    (option) => option.value === selectedTimeZone
                  )}
                  styles={customStyles}
                  placeholder="search timezone"
                />
              </div>
              {errors.has("timezone") && (
                <div className="text-red-500 mt-1 ml-32 text-sm">
                  {errors.first("timezone")}
                </div>
              )}
            </div>

            <div className="mb-8 lg:w-1/2 w-full">
              <div className="w-full flex flex-row items-center mt-2">
                <label className="w-1/9  text-neutral-700 text-base font-medium  ">
                  Invite Tutor:
                </label>

                <input
                  type="checkbox"
                  className="1/6  border border-gray-400 rounded p-2  ml-8 mx-4 text-neutral-700"
                  name="invitation_status"
                  onChange={handleChange}
                />
                <p>Invite Tutors for the class</p>
              </div>
            </div>
          </div> */}

          <div className="md:w-2/4 w-full mt-4 lg:ml-12 ml-8 flex ">
            {/* <div className="mr-2">
              <button
                className=" bg-amber-500 text-white lg:px-6 p-2 rounded-md"
                type="submit"
                disabled={processingExit}
                onClick={(e) => handleSubmit(e, "exit")}
              >
                {processingExit ? "Please wait..." : "Save and Exit"}
              </button>
            </div> */}

            <div className="">
              <button
                className="lg:ml-28 ml-24 bg-lime-600 text-white lg:px-6 p-2 rounded-md md:mb-4"
                type="submit"
                //disabled={processing}
                onClick={(e) => handleSubmit(e)}
              >
                {processing ? "Please wait..." : "Add Parent +"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStudent;
