import { useState } from "react";
import api from "../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import LoadingBlock from "../Components/LoadingBlock";

function EnableDisableButton({ userId, redirect }) {
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const { data, loading, error } = useFetch(`/user-info/${userId}`);

  async function DisableUser() {
    //e.preventDefault();
    setProcessing(true);

    // Create the payload
    const payload = {
      user_id: userId,
    };

    //console.log("Form Data:", payload);

    try {
      const response = await api({
        method: "post",
        url: "disable-user",
        data: payload,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("User Disabled !");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  async function EnableUser() {
    //e.preventDefault();
    setProcessing(true);

    // Create the payload
    const payload = {
      user_id: userId,
    };

    //console.log("Form Data:", payload);

    try {
      const response = await api({
        method: "post",
        url: "enable-user",
        data: payload,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(
          "User Enabled Sucessfully. An email has been sent user to set password !"
        );
        navigate(redirect);
        //window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  if (loading) {
    return <LoadingBlock />;
  }
  return data?.data?.status === 1 ? (
    <button
      className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
      onClick={() => DisableUser()}
    >
      {processing ? "Please Wait..." : "Disable User"}
    </button>
  ) : (
    <button
      className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
      onClick={() => EnableUser()}
    >
      {processing ? "Please Wait..." : "Enable User"}
    </button>
  );
}

export default EnableDisableButton;
