import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { isLoggedInAsUser } from "../../../utils/Admin";
import LoggedInAsUserTopbar from "../../layouts/LoggedInAsUserTopbar";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import TutorSidebar from "./TutorSidebar";
import StudentSidebar from "./StudentSidebar";
import ParentSidebar from "./ParentSidebar";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  let user = getAuthUser();
  const [users, getUsers] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const userImage = user.profile_pic || null;

  function LogoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("original_user");
    localStorage.removeItem("original_token");
    getUsers(false);
    navigate("/");
  }

  useEffect(() => {
    // Add event listener to handle clicks outside the dropdown
    function handleClickOutside(event) {
      if (
        !event.target.closest(".user-dropdown") &&
        !event.target.closest(".user-image")
      ) {
        setIsMenuOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="lg:sticky  max-w-screen-3xl  ml-5 mr-5 lg:ml-10 lg:mr-7 bg-white my-4.5 rounded-lg shadow-default px-2 border border-gray-200 p-1 ">
      <div className=" flex items-center justify-between ">
        <div className=" lg:hidden">
          <button onClick={toggleSidebar}>
            <MenuIcon size={20} />
          </button>
        </div>

        {/* Sidebar */}
        {user.role === "superadmin" ? (
          <Sidebar
            sidebarToggle={sidebarToggle}
            toggleSidebar={toggleSidebar}
          />
        ) : user.role === "tutor" || user.role === "nontutor" ? (
          <TutorSidebar />
        ) : user.role === "student" ? (
          <StudentSidebar />
        ) : user.role === "parent" ? (
          <ParentSidebar />
        ) : null}

        <div className="flex items-center space-x-4">
          {isLoggedIn() && (
            <span className="lg:text-xl text-sm font-bold text-dark block text-nowrap px-6">
              Welcome, {getAuthUser()?.first_name}!
            </span>
          )}

          {isLoggedInAsUser() && <LoggedInAsUserTopbar />}
        </div>

        <div className=" flex items-center gap-3 2xsm:gap-7  justify-end">
          <ul className=" lg:w-[70px] flex items-center gap-2 lg:gap-8 ">
            <div className="ml-2 mt-2 ">
              <div className="relative flex">
                <span
                  className="lg:h-12 lg:w-12 h-8 w-8 rounded-full block cursor-pointer user-image"
                  onClick={toggleMenu}
                >
                  {userImage ? (
                    <img
                      className="h-full w-full object-cover"
                      src={userImage}
                      alt="User Profile"
                    />
                  ) : (
                    <div className=" rounded-full cursor-pointer bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-600 text-lg">
                        {user.first_name.charAt(0)}
                        {user.last_name ? user.last_name.charAt(0) : ""}
                      </span>
                    </div>
                  )}
                </span>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-10 lg:mt-14 w-48 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-99999 user-dropdown">
                    <Link to={"settings/profile"}>
                      <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Profile
                      </div>
                    </Link>

                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={LogoutUser}
                      style={{ cursor: "pointer" }}
                    >
                      Sign Out
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
