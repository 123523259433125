import { useState } from "react";
import Errors from "../../utils/Errors";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { useEffect } from "react";
import LoadingBlock from "../../Components/LoadingBlock";
import { toast } from "react-toastify";

const EditSubject = ({ subjectId, closeModal }) => {
  //const { subjectId } = useParams();
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function fetchSubjectDetail() {
    setLoading(true);
    api
      .get(`edit-subject/${subjectId}`)
      .then((res) => {
        let data = res.data.data;
        //console.log("Data from API: ", data);
        setState({
          ...state,
          title: data.title,
          status: data.status,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSubjectDetail();
  }, []);

  //update subject details

  const [state, setState] = useState({
    title: "",
    status: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
    console.log(state);
  }

  function handleSubmit(e) {
    setProcessing(true);
    e.preventDefault();
    api
      .post(`update-subject/${subjectId}`, state)
      .then((res) => {
        toast.success("Subject Updated !");
        closeModal();
        navigate("/subject");
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setProcessing(false));
  }

  if (Loading) {
    return <LoadingBlock></LoadingBlock>;
  }

  return (
    <div className="w-full flex items-center">
      <div className="w-full ml-4 flex flex-col">
        <div className="mb-4 mt-4">
          <div className="w-full flex flex-row items-center">
            <label className="w-1/6  text-black  text-base font-normal  mb-2">
              Title:
            </label>
            <input
              type="text"
              className="w-5/6 border border-gray-400 rounded p-2  bg-main  text-black  text-base font-light"
              placeholder="Subject Ttile"
              name="title"
              value={state.title}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-4">
          <div className="w-full flex flex-row items-center">
            <label className="w-1/6  text-black  text-base font-normal  mb-2">
              Status:
            </label>

            <select
              className="w-5/6 border border-gray-400 rounded p-2  bg-main  text-black  text-base font-light"
              name="status"
              value={state.status}
              onChange={handleChange}
            >
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
          </div>
        </div>

        <div className="mt-4 py-2 text-center">
          <button
            className="bg-lime-600 text-white px-6 py-2 rounded-md"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSubject;
