import { toast } from "react-toastify";
import api from "../api";

export function DeleteSubjectAction(subjectId){
    const promise = api.delete(`subject/${subjectId}`);
    toast.promise(promise, {
        pending: "Deleting Subject",
        success: "Subject has been deleted",
        error: "Error while deleting this subject !",
    });
    return promise
}

export function DeleteTutorAction(tutorId){
    const promise = api.delete(`tutor/${tutorId}`);
    toast.promise(promise, {
        pending: "Deleting Tutor",
        success: "Tutor has been deleted",
        error: "Error while deleting this tutor !",
    });
    return promise
}

export function DeleteStudentAction(studentId){
    const promise = api.delete(`student/${studentId}`);
    toast.promise(promise, {
        pending: "Deleting Student",
        success: "Student has been deleted",
        error: "Error while deleting this student !",
    });
    return promise
}

export function DeleteParentAction(parentId){
    const promise = api.delete(`parent/${parentId}`);
    toast.promise(promise, {
        pending: "Deleting Parent",
        success: "Parent has been deleted",
        error: "Error while deleting this Parent !",
    });
    return promise
}

export function DeleteLogAction(logId){
    const promise = api.delete(`delete-email-log/${logId}`);
    toast.promise(promise, {
        pending: "Deleting Log",
        success: "Log has been deleted",
        error: "Error while deleting this log !",
    });
    return promise
}


export function RichTextViewer({content}) {
    
    // return <p>{reactHtmlEntities(content)}</p>
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }