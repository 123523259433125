import React, { useContext, useEffect, useState } from "react";

import logo from "../../Assets/Images/Logo.png";
import Illustration from "../../Assets/Images/LoginIllustration.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import api from "../../api";
import { toast } from "react-toastify";

const TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000;

export default function Login() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email") ?? "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const { login } = useContext(AuthContext);

  useEffect(() => {
    setErrorMessage("");

    // Clear user details from local storage after 12 hours
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData) {
      const { timestamp } = storedUserData;
      if (Date.now() - timestamp > TWELVE_HOURS_IN_MS) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }
    }
  //}, [email, password]);
  }, []);

  function redirectUserBasedOnRole(user) {
    navigate("/dashboard");
    // if (user.role === "superadmin") {
    //   navigate("/tutor");
    //   return;
    // //}
    // } else if (user.role === "tutor") {
    //   navigate("/tutor-calendar");
    //   return;
    // } else if(user.role === 'student'){
    //   navigate('/student-calendar');
    //   return;
    // } else if(user.role === 'parent'){
    //   navigate('/parent-calendar');
    //   return;
    // }

    // switch (user.role) {
    //   case "superadmin":
    //     navigate("/dashboard");
    //     break;
    //   case "tutor":
    //     navigate("/tutor-calendar");
    //     break;
    //   case "nontutor":
    //     navigate("/tutor-calendar");
    //     break;
    //   case "student":
    //     navigate("/student-calendar");
    //     break;
    //   case "parent":
    //     navigate("/parent-calendar");
    //     break;
    //   default:
    //     break;
    // }
  }

  function handleSubmit(e) {
    // console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
    // console.log('baseURL:', baseURL);

    //return;
    e.preventDefault();
    setProcessing(true);
    api
      .post("login", {
        email: email,
        password: password,
      })
      .then(async (response) => {
        await login(response.data.token, response.data.user);

        // Save user details along with timestamp to local storage
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify({
        //     user: response.data.user,
        //     timestamp: Date.now(),
        //   })
        // );

        // We are delaying the navigation to provide time for the
        // useEffect hook setting up the axios request interceptor
        setTimeout(() => {
          redirectUserBasedOnRole(response.data.user);
        }, 1000);
      })
      .catch((error) => {
        if (error.response && error.response.data.error) {
          setErrorMessage(error.response.data.error);
          return;
        }
        setErrorMessage("Credentials do not match!!");
      })
      .finally(() => setProcessing(false));
  }

  if (errorMessage) {
    toast.error(errorMessage);
  }

  return (
    <section className="flex flex-wrap">
      <div className="w-full lg:w-1/2 p-4">
        <img src={Illustration} alt="Illustration" className="w-full h-auto " />
      </div>
      <div className="w-full border-l-2 lg:w-1/2 mt-12 mb-12 pt-20">
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            className="w-52 h-24 mr-[300px]  mx-auto"
          />
        </div>
        <div className="w full ml-28">
          <div className="flex  px-4 py-10 sm:px-6 sm:py-8 ">
            <div className=" xl:w-full xl:max-w-sm 2xl:max-w-md">
              <h2 className="text-3xl font-bold leading-tight text-green-700 sm:text-4xl text-center">
                Log In
              </h2>

              <form
                action="#"
                method="POST"
                className="mt-8"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      Email address{" "}
                    </label>
                    <div className="mt-2">
                      <input
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor=""
                        className="text-base font-medium text-gray-900"
                      >
                        {" "}
                        Password{" "}
                      </label>
                      <a
                        href="forgot-password"
                        title="forgot-password"
                        className="text-sm font-semibold text-black hover:underline"
                      >
                        {" "}
                        Forgot password?{" "}
                      </a>
                    </div>
                    <div className="mt-2">
                      <input
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-yellow bg-amber-500 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                    >
                      {processing ? "Please Wait..." : "Log In"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
