import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import Errors from "../../../utils/Errors";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
import ReactQuill from "react-quill";

function TutorEmail() {
  const { tutorId } = useParams();
  const { data, loading, error } = useFetch(`get-tutor-detail/${tutorId}`);
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(new Errors());
  let user = getAuthUser();
  const [processing, setProcessing] = useState(false);

  const adminEmail = user.email;
  // Check if tutor properties exist
  const tutorEmail = data?.data?.email || "";
  const tutorfName = data?.data?.first_name || "";
  const tutorlName = data?.data?.last_name || "";

  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    // Create the payload
    const payload = {
      fromEmail: adminEmail,
      subject: formData.subject,
      message: formData.message,
      recipient: tutorEmail,
    };

    try {
      const response = await api({
        method: "post",
        url: "send-individual-message",
        data: payload,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Email Send Successfully !");
        navigate("/tutor");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  // Check if data exists before accessing properties
  if (!data || loading || error) {
    // Handle loading or error state
    return <LoadingBlock></LoadingBlock>;
  }
  return (
    <div className=" max-w-screen-3xl p-4   flex-grow bg-white rounded-lg lg:ml-7 lg:mr-4 mx-5.5">
      <h2 className="text-2xl font-semibold mb-4">
        Send message to {tutorfName} {tutorlName}
      </h2>

      <div className="w-full mb-4 flex items-center">
        <label className="w-1/6 block text-sm font-medium text-gray-600 mr-4">
          From:
        </label>
        <strong>{adminEmail}</strong>
      </div>

      <div className="w-full mb-4 flex items-center">
        <label className="w-1/6 block text-sm font-medium text-gray-600 mr-4">
          To:
        </label>
        <strong>
          {tutorfName} {tutorlName}
        </strong>
      </div>

      <div className="w-full mb-4 flex items-center ">
        <label className="w-1/6 block text-sm font-medium text-gray-600 mr-4">
          Subject:
        </label>
        <div className="w-full flex flex-col">
          <input
            type="text"
            name="subject"
            //value={formData.subject}
            onChange={handleChange}
            className={`w-full px-3 py-2 border border-gray-400 rounded-md  ${
              errors.has("subject") ? "border-red-500" : ""
            }`}
          />
          {errors.has("subject") && (
            <div className="text-red-500 mt-1 ml-2 text-sm">
              {errors.first("subject")}
            </div>
          )}
        </div>
      </div>

      <div className="w-full  mb-4 flex items-center">
        <label className="w-1/6 block text-sm font-medium mr-4">Message:</label>
        <div className="w-full flex flex-col">
          <ReactQuill
            name="message"
            onChange={(value) => setFormData({ ...formData, message: value })}
            className="h-[150px] mb-16"
          />
          {errors.has("message") && (
            <div className="text-red-500 mt-1 ml-2 text-sm">
              {errors.first("message")}
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 ml-40 flex justify-start">
        <button
          onClick={(e) => handleSubmit(e)}
          className="ml-2  bg-lime-600 text-white px-8 py-2 rounded-md"
        >
          {processing ? "Please wait..." : "Send"}
        </button>
      </div>
    </div>
  );
}

export default TutorEmail;
