import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function ContextMessage({ isOpen, setIsModalOpen }) {
  return (
    <Dialog open={isOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>Warning !</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select Future Date To add schedule
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setIsModalOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContextMessage;
