import { ScaleLoader } from "react-spinners"

const LoadingBlock = ({loading}) => {
    return (
        <div className="py-5 text-center">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ScaleLoader
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
            />
        </div>
        </div>
    );
}

export default LoadingBlock;