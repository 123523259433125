import React, { useState } from "react";
import AssignTutorPopup from "./AssignTutorPopup.js";

const TutorTab = ({ tutor }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="w-full flex items-center table-fixed overflow-x-auto ">
      <div className="w-5/6 flex flex-col">
        <div className="mb-4">
          {/* <button
            className="bg-lime-600 text-white px-4 py-2 rounded-md"
            onClick={() => openPopup(true)}
          >
            Assign Tutor
          </button> */}
          {tutor ? (
            <p className="text-dark font-bold  px-2">Assined Tutors</p>
          ) : (
            <p className="text-gray-500 mt-2">
              This student has not been assigned any tutor yet.
            </p>
          )}
        </div>
        <table className="w-full divide-y divide-gray-200 overflow-x-auto">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>S.N</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Name</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Email</span>
              </th>
              {/* <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Default Lesson Length</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Make-up Credits (Unscheduled)</span>
              </th>
              <th className="px-4 py-3.5 text-left text-base font-normal text-gray-700">
                <span>Make-up Credits</span>
              </th> */}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {tutor?.data?.map((person, index) => (
              <tr key={person.sn}>
                <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {index + 1}
                </td>
                <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {person.first_name} {person.last_name}
                </td>
                <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {person.email}
                </td>
                {/* <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {person["Default Lesson Length"]}
                </td>
                <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {person["Make-up Credits (Unscheduled)"]}
                </td>
                <td className=" text-neutral-700 text-base font-light whitespace-nowrap px-4 py-4">
                  {person["Make-up Credits"]}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isPopupOpen && <AssignTutorPopup onClose={closePopup} />}
    </div>
  );
};

export default TutorTab;
