import { useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";

export default function EditStudentModal({ studentId, onClose }) {
  const [processing, setProcessing] = useState(false);

  const initialState = {
    first_name: "",
    last_name: "",
    phone_no: "",
    role: "student",
    address: "",
  };
  const [form, setForm] = useState(initialState);

  //get student detail
  function fetchStudentDetail() {
    setProcessing(true);
    api
      .get(`edit-student/${studentId}`)
      .then((res) => {
        let res_data = res.data.data;

        setForm({
          ...form,
          first_name: res_data.first_name,
          last_name: res_data.last_name,
          email: res_data.email,
          phone_no: res_data.phone_no,
          address: res_data.address,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setProcessing(false));
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  // Handle form input change
  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  //Handle update
  function handleSubmit(e) {
    setProcessing(true);
    e.preventDefault();

    api
      .post(`/update-student/${studentId}`, form)
      .then((res) => {
        toast.success("Student Updated !");
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          toast.error("Failed to process form");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setProcessing(false));
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="lg:w-1/2 w-3/4 gap-[16px] rounded-md border  bg-white p-2 m-2 ">
        <div className="mb-4 mt-4">
          <h2 className="text-dark font-bold  px-2 text-center">
            Edit Student
          </h2>
          {processing && <LoadingBlock>Loading...</LoadingBlock>}
        </div>

        <div className="flex flex-col w-full justify-center  mx-2 ">
          <div className="mb-4 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2 text-nowrap mr-3">
                First Name:
              </label>

              <input
                type="text"
                className="w-4/6 border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your First Name"
                name="first_name"
                value={form.first_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-4 w-full">
            <div className="w-full flex flex-row   ">
              <label className="w-1/4  text-black text-base font-medium  mb-2 text-nowrap mr-3">
                Last Name:
              </label>
              <input
                type="text"
                className="w-4/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Last Name"
                name="last_name"
                value={form.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full justify-center mx-2 ">
          {/* <div className="mb-4 w-1/2">
            <div className="w-full flex flex-row ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                Email:
              </label>

              <input
                type="email"
                className="w-4/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="w-4/6 ml-20 text-sm text-red-500">
              Email can't be updated!
            </div>
          </div> */}

          <div className="mb-4 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2 text-nowrap mr-3">
                Address:
              </label>
              <input
                type="text"
                className="w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Address"
                name="address"
                value={form.address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="mx-4 px-8 text-center md:mr-28">
          <button
            className="bg-lime-600 text-white px-8 py-2 lg:mb-4 rounded-md"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "update"}
          </button>
          <button
            className="text-gray-600 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
