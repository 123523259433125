import React from "react";

const AttendanceStatus = ({ status }) => {
  let textColor = "#000000";
  let bgColor = "#FFFFFF";

  switch (status) {
    case "PRESENT":
      textColor = "#3C7013";
      bgColor = "#C8FFC8";
      break;
    case "ABSENT":
      textColor = "#FF0000";
      bgColor = "#FFC8C8";
      break;
    case "UNRECODED":
      textColor = "#0000FF";
      bgColor = "#C8C8FF";
      break;
    case "TUTOR_CANCELLED":
      textColor = "#FFA500";
      bgColor = "#FFEDCB";
      break;
    default:
      break;
  }
  // Capitalize the first letter and convert the rest to lowercase
  const formattedStatus =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  return (
    <td className="text-gray-900 flex items-center   lg:pr-4 ">
      <span
        className="flex  text-xs lg:text-sm lg:font-normal font-medium px-0 py-1 rounded-full mt-1.5"
        style={{ color: textColor, backgroundColor: bgColor }}
      >
        {formattedStatus.replace(/_/g, " ")}
      </span>
    </td>
  );
};

export default AttendanceStatus;
