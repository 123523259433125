import { useState } from "react";
import PersonalTab from "./Tabs/PersonalTabOld";
import StudentTab from "./Tabs/StudentTab";
import SessionTab from "./Tabs/SessionTab";
import ActionTab from "./Tabs/ActionTab";
import ViewParentDetail from "./Tabs/ViewParentDetail";

export default function EditParent() {
    const [activeTab, setActiveTab] = useState("Personal");

  const tabs = [
    "Personal",
    // "Preferences",
    "Students",
    "Attendance and Notes",
    // "Availability",
    // "Payroll",
    "Action",
  ];

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };

  return (
    <div className="mx-auto  px-5 pt-4 ml-7 mr-4 mt-4 lg:mb-8 bg-white rounded-lg">
      <div className="space-x-4 flex-row lg:pt-4 ml-5">
        {/* Desktop and Tablet view - Dropdown */}
        <select
          className="md:px-20 px-12 py-2 border rounded-md lg:hidden w-full xsm:mb-3  md:w-5/6 "
          value={activeTab}
          onChange={handleTabChange}
        >
          {tabs.map((tab) => (
            <option key={tab} value={tab}>
              {tab}
            </option>
          ))}
        </select>

        {/* Desktop view - Buttons */}
        <div className="hidden space-x-4  lg:flex lg:mb-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`text-left p-2 px-4 cursor-pointer border rounded-md ${
                activeTab === tab
                  ? "bg-amber-500 text-white"
                  : "bg-main text-black"
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="w-full md:p-6 ">
        {/* <div className="w-full flex ">
          <label className="text-black md:mr-3 md:ml-4">Name</label>
          
          <p className="text-dark font-bold  px-20 mb-4">Bikash Sir</p>


        </div> */}
        <div>
          {activeTab === "Personal" && <div>{<ViewParentDetail />}</div>}
          {/* {activeTab === "Preferences" && <div>Content for Preferences</div>} */}
          {activeTab === "Students" && <div>{<StudentTab />}</div>}
          {activeTab === "Attendance and Notes" && (
            <div>{<SessionTab />}</div>
          )}
          {/* {activeTab === "Availability" && <div>Content for Availability</div>}
          {activeTab === "Payroll" && <div>Content for Payroll</div>} */}
          {activeTab === "Action" && <div>{<ActionTab />}</div>}
        </div>
      </div>
    </div>
  );
}
