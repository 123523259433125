export function TimeZone() {
  return [
    {
      group: "US (Common)",
      zones: [
        { value: "America/Puerto_Rico", name: "Puerto Rico (Atlantic)" },
        { value: "America/New_York", name: "New York (Eastern)" },
        { value: "America/Chicago", name: "Chicago (Central)" },
        { value: "America/Denver", name: "Denver (Mountain)" },
        { value: "America/Phoenix", name: "Phoenix (MST)" },
        { value: "America/Los_Angeles", name: "Los Angeles (Pacific)" },
        { value: "America/Anchorage", name: "Anchorage (Alaska)" },
        { value: "Pacific/Honolulu", name: "Honolulu (Hawaii)" },
      ],
    },
    {
      group: "America",
      zones: [
        { value: "America/Adak", name: "Adak" },
        { value: "America/Anchorage", name: "Anchorage" },
        { value: "America/Anguilla", name: "Anguilla" },
        { value: "America/Antigua", name: "Antigua" },
        { value: "America/Araguaina", name: "Araguaina" },
        {
          value: "America/Argentina/Buenos_Aires",
          name: "Argentina - Buenos Aires",
        },
        { value: "America/Argentina/Catamarca", name: "Argentina - Catamarca" },
        {
          value: "America/Argentina/ComodRivadavia",
          name: "Argentina - ComodRivadavia",
        },
        { value: "America/Argentina/Cordoba", name: "Argentina - Cordoba" },
        { value: "America/Argentina/Jujuy", name: "Argentina - Jujuy" },
        { value: "America/Argentina/La_Rioja", name: "Argentina - La Rioja" },
        { value: "America/Argentina/Mendoza", name: "Argentina - Mendoza" },
        {
          value: "America/Argentina/Rio_Gallegos",
          name: "Argentina - Rio Gallegos",
        },
        { value: "America/Argentina/Salta", name: "Argentina - Salta" },
        { value: "America/Argentina/San_Juan", name: "Argentina - San Juan" },
        { value: "America/Argentina/San_Luis", name: "Argentina - San Luis" },
        { value: "America/Argentina/Tucuman", name: "Argentina - Tucuman" },
        { value: "America/Argentina/Ushuaia", name: "Argentina - Ushuaia" },
        { value: "America/Aruba", name: "Aruba" },
        { value: "America/Asuncion", name: "Asuncion" },
        { value: "America/Atikokan", name: "Atikokan" },
        { value: "America/Atka", name: "Atka" },
        { value: "America/Bahia", name: "Bahia" },
        { value: "America/Barbados", name: "Barbados" },
        { value: "America/Belem", name: "Belem" },
        { value: "America/Belize", name: "Belize" },
        { value: "America/Blanc-Sablon", name: "Blanc-Sablon" },
        { value: "America/Boa_Vista", name: "Boa Vista" },
        { value: "America/Bogota", name: "Bogota" },
        { value: "America/Boise", name: "Boise" },
        { value: "America/Buenos_Aires", name: "Buenos Aires" },
        { value: "America/Cambridge_Bay", name: "Cambridge Bay" },
        { value: "America/Campo_Grande", name: "Campo Grande" },
        { value: "America/Cancun", name: "Cancun" },
        { value: "America/Caracas", name: "Caracas" },
        { value: "America/Catamarca", name: "Catamarca" },
        { value: "America/Cayenne", name: "Cayenne" },
        { value: "America/Cayman", name: "Cayman" },
        { value: "America/Chicago", name: "Chicago" },
        { value: "America/Chihuahua", name: "Chihuahua" },
        { value: "America/Coral_Harbour", name: "Coral Harbour" },
        { value: "America/Cordoba", name: "Cordoba" },
        { value: "America/Costa_Rica", name: "Costa Rica" },
        { value: "America/Cuiaba", name: "Cuiaba" },
        { value: "America/Curacao", name: "Curacao" },
        { value: "America/Danmarkshavn", name: "Danmarkshavn" },
        { value: "America/Dawson", name: "Dawson" },
        { value: "America/Dawson_Creek", name: "Dawson Creek" },
        { value: "America/Denver", name: "Denver" },
        { value: "America/Detroit", name: "Detroit" },
        { value: "America/Dominica", name: "Dominica" },
        { value: "America/Edmonton", name: "Edmonton" },
        { value: "America/Eirunepe", name: "Eirunepe" },
        { value: "America/El_Salvador", name: "El Salvador" },
        { value: "America/Ensenada", name: "Ensenada" },
        { value: "America/Fortaleza", name: "Fortaleza" },
        { value: "America/Fort_Wayne", name: "Fort Wayne" },
        { value: "America/Glace_Bay", name: "Glace Bay" },
        { value: "America/Godthab", name: "Godthab" },
        { value: "America/Goose_Bay", name: "Goose Bay" },
        { value: "America/Grand_Turk", name: "Grand Turk" },
        { value: "America/Grenada", name: "Grenada" },
        { value: "America/Guadeloupe", name: "Guadeloupe" },
        { value: "America/Guatemala", name: "Guatemala" },
        { value: "America/Guayaquil", name: "Guayaquil" },
        { value: "America/Guyana", name: "Guyana" },
        { value: "America/Halifax", name: "Halifax" },
        { value: "America/Havana", name: "Havana" },
        { value: "America/Hermosillo", name: "Hermosillo" },
        {
          value: "America/Indiana/Indianapolis",
          name: "Indiana - Indianapolis",
        },
        { value: "America/Indiana/Knox", name: "Indiana - Knox" },
        { value: "America/Indiana/Marengo", name: "Indiana - Marengo" },
        { value: "America/Indiana/Petersburg", name: "Indiana - Petersburg" },
        { value: "America/Indiana/Tell_City", name: "Indiana - Tell City" },
        { value: "America/Indiana/Vevay", name: "Indiana - Vevay" },
        { value: "America/Indiana/Vincennes", name: "Indiana - Vincennes" },
        { value: "America/Indiana/Winamac", name: "Indiana - Winamac" },
        { value: "America/Indianapolis", name: "Indianapolis" },
        { value: "America/Inuvik", name: "Inuvik" },
        { value: "America/Iqaluit", name: "Iqaluit" },
        { value: "America/Jamaica", name: "Jamaica" },
        { value: "America/Jujuy", name: "Jujuy" },
        { value: "America/Juneau", name: "Juneau" },
        { value: "America/Kentucky/Louisville", name: "Kentucky - Louisville" },
        { value: "America/Kentucky/Monticello", name: "Kentucky - Monticello" },
        { value: "America/Knox_IN", name: "Knox IN" },
        { value: "America/La_Paz", name: "La Paz" },
        { value: "America/Lima", name: "Lima" },
        { value: "America/Los_Angeles", name: "Los Angeles" },
        { value: "America/Louisville", name: "Louisville" },
        { value: "America/Maceio", name: "Maceio" },
        { value: "America/Managua", name: "Managua" },
        { value: "America/Manaus", name: "Manaus" },
        { value: "America/Marigot", name: "Marigot" },
        { value: "America/Martinique", name: "Martinique" },
        { value: "America/Matamoros", name: "Matamoros" },
        { value: "America/Mazatlan", name: "Mazatlan" },
        { value: "America/Mendoza", name: "Mendoza" },
        { value: "America/Menominee", name: "Menominee" },
        { value: "America/Merida", name: "Merida" },
        { value: "America/Mexico_City", name: "Mexico City" },
        { value: "America/Miquelon", name: "Miquelon" },
        { value: "America/Moncton", name: "Moncton" },
        { value: "America/Monterrey", name: "Monterrey" },
        { value: "America/Montevideo", name: "Montevideo" },
        { value: "America/Montreal", name: "Montreal" },
        { value: "America/Montserrat", name: "Montserrat" },
        { value: "America/Nassau", name: "Nassau" },
        { value: "America/New_York", name: "New York" },
        { value: "America/Nipigon", name: "Nipigon" },
        { value: "America/Nome", name: "Nome" },
        { value: "America/Noronha", name: "Noronha" },
        { value: "America/North_Dakota/Center", name: "North Dakota - Center" },
        {
          value: "America/North_Dakota/New_Salem",
          name: "North Dakota - New Salem",
        },
        { value: "America/Ojinaga", name: "Ojinaga" },
        { value: "America/Panama", name: "Panama" },
        { value: "America/Pangnirtung", name: "Pangnirtung" },
        { value: "America/Paramaribo", name: "Paramaribo" },
        { value: "America/Phoenix", name: "Phoenix" },
        { value: "America/Port-au-Prince", name: "Port-au-Prince" },
        { value: "America/Porto_Acre", name: "Porto Acre" },
        { value: "America/Port_of_Spain", name: "Port of Spain" },
        { value: "America/Porto_Velho", name: "Porto Velho" },
        { value: "America/Puerto_Rico", name: "Puerto Rico" },
        { value: "America/Rainy_River", name: "Rainy River" },
        { value: "America/Rankin_Inlet", name: "Rankin Inlet" },
        { value: "America/Recife", name: "Recife" },
        { value: "America/Regina", name: "Regina" },
        { value: "America/Resolute", name: "Resolute" },
        { value: "America/Rio_Branco", name: "Rio Branco" },
        { value: "America/Rosario", name: "Rosario" },
        { value: "America/Santa_Isabel", name: "Santa Isabel" },
        { value: "America/Santarem", name: "Santarem" },
        { value: "America/Santiago", name: "Santiago" },
        { value: "America/Santo_Domingo", name: "Santo Domingo" },
        { value: "America/Sao_Paulo", name: "Sao Paulo" },
        { value: "America/Scoresbysund", name: "Scoresbysund" },
        { value: "America/Shiprock", name: "Shiprock" },
        { value: "America/St_Barthelemy", name: "St Barthelemy" },
        { value: "America/St_Johns", name: "St Johns" },
        { value: "America/St_Kitts", name: "St Kitts" },
        { value: "America/St_Lucia", name: "St Lucia" },
        { value: "America/St_Thomas", name: "St Thomas" },
        { value: "America/St_Vincent", name: "St Vincent" },
        { value: "America/Swift_Current", name: "Swift Current" },
        { value: "America/Tegucigalpa", name: "Tegucigalpa" },
        { value: "America/Thule", name: "Thule" },
        { value: "America/Thunder_Bay", name: "Thunder Bay" },
        { value: "America/Tijuana", name: "Tijuana" },
        { value: "America/Toronto", name: "Toronto" },
        { value: "America/Tortola", name: "Tortola" },
        { value: "America/Vancouver", name: "Vancouver" },
        { value: "America/Virgin", name: "Virgin" },
        { value: "America/Whitehorse", name: "Whitehorse" },
        { value: "America/Winnipeg", name: "Winnipeg" },
        { value: "America/Yakutat", name: "Yakutat" },
        { value: "America/Yellowknife", name: "Yellowknife" },
      ],
    },
    {
      group: "Europe",
      zones: [
        { value: "Europe/Amsterdam", name: "Amsterdam" },
        { value: "Europe/Andorra", name: "Andorra" },
        { value: "Europe/Athens", name: "Athens" },
        { value: "Europe/Belfast", name: "Belfast" },
        { value: "Europe/Belgrade", name: "Belgrade" },
        { value: "Europe/Berlin", name: "Berlin" },
        { value: "Europe/Bratislava", name: "Bratislava" },
        { value: "Europe/Brussels", name: "Brussels" },
        { value: "Europe/Bucharest", name: "Bucharest" },
        { value: "Europe/Budapest", name: "Budapest" },
        { value: "Europe/Chisinau", name: "Chisinau" },
        { value: "Europe/Copenhagen", name: "Copenhagen" },
        { value: "Europe/Dublin", name: "Dublin" },
        { value: "Europe/Gibraltar", name: "Gibraltar" },
        { value: "Europe/Guernsey", name: "Guernsey" },
        { value: "Europe/Helsinki", name: "Helsinki" },
        { value: "Europe/Isle_of_Man", name: "Isle of Man" },
        { value: "Europe/Istanbul", name: "Istanbul" },
        { value: "Europe/Jersey", name: "Jersey" },
        { value: "Europe/Kaliningrad", name: "Kaliningrad" },
        { value: "Europe/Kiev", name: "Kiev" },
        { value: "Europe/Lisbon", name: "Lisbon" },
        { value: "Europe/Ljubljana", name: "Ljubljana" },
        { value: "Europe/London", name: "London" },
        { value: "Europe/Luxembourg", name: "Luxembourg" },
        { value: "Europe/Madrid", name: "Madrid" },
        { value: "Europe/Malta", name: "Malta" },
        { value: "Europe/Mariehamn", name: "Mariehamn" },
        { value: "Europe/Minsk", name: "Minsk" },
        { value: "Europe/Monaco", name: "Monaco" },
        { value: "Europe/Moscow", name: "Moscow" },
        { value: "Europe/Nicosia", name: "Nicosia" },
        { value: "Europe/Oslo", name: "Oslo" },
        { value: "Europe/Paris", name: "Paris" },
        { value: "Europe/Podgorica", name: "Podgorica" },
        { value: "Europe/Prague", name: "Prague" },
        { value: "Europe/Riga", name: "Riga" },
        { value: "Europe/Rome", name: "Rome" },
        { value: "Europe/Samara", name: "Samara" },
        { value: "Europe/San_Marino", name: "San Marino" },
        { value: "Europe/Sarajevo", name: "Sarajevo" },
        { value: "Europe/Simferopol", name: "Simferopol" },
        { value: "Europe/Skopje", name: "Skopje" },
        { value: "Europe/Sofia", name: "Sofia" },
        { value: "Europe/Stockholm", name: "Stockholm" },
        { value: "Europe/Tallinn", name: "Tallinn" },
        { value: "Europe/Tirane", name: "Tirane" },
        { value: "Europe/Tiraspol", name: "Tiraspol" },
        { value: "Europe/Uzhgorod", name: "Uzhgorod" },
        { value: "Europe/Vaduz", name: "Vaduz" },
        { value: "Europe/Vatican", name: "Vatican" },
        { value: "Europe/Vienna", name: "Vienna" },
        { value: "Europe/Vilnius", name: "Vilnius" },
        { value: "Europe/Volgograd", name: "Volgograd" },
        { value: "Europe/Warsaw", name: "Warsaw" },
        { value: "Europe/Zagreb", name: "Zagreb" },
        { value: "Europe/Zaporozhye", name: "Zaporozhye" },
        { value: "Europe/Zurich", name: "Zurich" },
      ],
    },
    {
      group: "Asia",
      zones: [
        { value: "Asia/Aden", name: "Aden" },
        { value: "Asia/Almaty", name: "Almaty" },
        { value: "Asia/Amman", name: "Amman" },
        { value: "Asia/Anadyr", name: "Anadyr" },
        { value: "Asia/Aqtau", name: "Aqtau" },
        { value: "Asia/Aqtobe", name: "Aqtobe" },
        { value: "Asia/Ashgabat", name: "Ashgabat" },
        { value: "Asia/Ashkhabad", name: "Ashkhabad" },
        { value: "Asia/Baghdad", name: "Baghdad" },
        { value: "Asia/Bahrain", name: "Bahrain" },
        { value: "Asia/Baku", name: "Baku" },
        { value: "Asia/Bangkok", name: "Bangkok" },
        { value: "Asia/Beirut", name: "Beirut" },
        { value: "Asia/Bishkek", name: "Bishkek" },
        { value: "Asia/Brunei", name: "Brunei" },
        { value: "Asia/Calcutta", name: "Calcutta" },
        { value: "Asia/Choibalsan", name: "Choibalsan" },
        { value: "Asia/Chongqing", name: "Chongqing" },
        { value: "Asia/Chungking", name: "Chungking" },
        { value: "Asia/Colombo", name: "Colombo" },
        { value: "Asia/Dacca", name: "Dacca" },
        { value: "Asia/Damascus", name: "Damascus" },
        { value: "Asia/Dhaka", name: "Dhaka" },
        { value: "Asia/Dili", name: "Dili" },
        { value: "Asia/Dubai", name: "Dubai" },
        { value: "Asia/Dushanbe", name: "Dushanbe" },
        { value: "Asia/Gaza", name: "Gaza" },
        { value: "Asia/Harbin", name: "Harbin" },
        { value: "Asia/Ho_Chi_Minh", name: "Ho Chi Minh" },
        { value: "Asia/Hong_Kong", name: "Hong Kong" },
        { value: "Asia/Hovd", name: "Hovd" },
        { value: "Asia/Irkutsk", name: "Irkutsk" },
        { value: "Asia/Istanbul", name: "Istanbul" },
        { value: "Asia/Jakarta", name: "Jakarta" },
        { value: "Asia/Jayapura", name: "Jayapura" },
        { value: "Asia/Jerusalem", name: "Jerusalem" },
        { value: "Asia/Kabul", name: "Kabul" },
        { value: "Asia/Kamchatka", name: "Kamchatka" },
        { value: "Asia/Karachi", name: "Karachi" },
        { value: "Asia/Kashgar", name: "Kashgar" },
        { value: "Asia/Kathmandu", name: "Kathmandu" },
        { value: "Asia/Katmandu", name: "Katmandu" },
        { value: "Asia/Kolkata", name: "Kolkata" },
        { value: "Asia/Krasnoyarsk", name: "Krasnoyarsk" },
        { value: "Asia/Kuala_Lumpur", name: "Kuala Lumpur" },
        { value: "Asia/Kuching", name: "Kuching" },
        { value: "Asia/Kuwait", name: "Kuwait" },
        { value: "Asia/Macao", name: "Macao" },
        { value: "Asia/Macau", name: "Macau" },
        { value: "Asia/Magadan", name: "Magadan" },
        { value: "Asia/Makassar", name: "Makassar" },
        { value: "Asia/Manila", name: "Manila" },
        { value: "Asia/Muscat", name: "Muscat" },
        { value: "Asia/Nicosia", name: "Nicosia" },
        { value: "Asia/Novokuznetsk", name: "Novokuznetsk" },
        { value: "Asia/Novosibirsk", name: "Novosibirsk" },
        { value: "Asia/Omsk", name: "Omsk" },
        { value: "Asia/Oral", name: "Oral" },
        { value: "Asia/Phnom_Penh", name: "Phnom Penh" },
        { value: "Asia/Pontianak", name: "Pontianak" },
        { value: "Asia/Pyongyang", name: "Pyongyang" },
        { value: "Asia/Qatar", name: "Qatar" },
        { value: "Asia/Qyzylorda", name: "Qyzylorda" },
        { value: "Asia/Rangoon", name: "Rangoon" },
        { value: "Asia/Riyadh", name: "Riyadh" },
        { value: "Asia/Saigon", name: "Saigon" },
        { value: "Asia/Sakhalin", name: "Sakhalin" },
        { value: "Asia/Samarkand", name: "Samarkand" },
        { value: "Asia/Seoul", name: "Seoul" },
        { value: "Asia/Shanghai", name: "Shanghai" },
        { value: "Asia/Singapore", name: "Singapore" },
        { value: "Asia/Taipei", name: "Taipei" },
        { value: "Asia/Tashkent", name: "Tashkent" },
        { value: "Asia/Tbilisi", name: "Tbilisi" },
        { value: "Asia/Tehran", name: "Tehran" },
        { value: "Asia/Tel_Aviv", name: "Tel Aviv" },
        { value: "Asia/Thimbu", name: "Thimbu" },
        { value: "Asia/Thimphu", name: "Thimphu" },
        { value: "Asia/Tokyo", name: "Tokyo" },
        { value: "Asia/Ujung_Pandang", name: "Ujung Pandang" },
        { value: "Asia/Ulaanbaatar", name: "Ulaanbaatar" },
        { value: "Asia/Ulan_Bator", name: "Ulan Bator" },
        { value: "Asia/Urumqi", name: "Urumqi" },
        { value: "Asia/Vientiane", name: "Vientiane" },
        { value: "Asia/Vladivostok", name: "Vladivostok" },
        { value: "Asia/Yakutsk", name: "Yakutsk" },
        { value: "Asia/Yekaterinburg", name: "Yekaterinburg" },
        { value: "Asia/Yerevan", name: "Yerevan" },
      ],
    },
    {
      group: "Africa",
      zones: [
        { value: "Africa/Abidjan", name: "Abidjan" },
        { value: "Africa/Accra", name: "Accra" },
        { value: "Africa/Addis_Ababa", name: "Addis Ababa" },
        { value: "Africa/Algiers", name: "Algiers" },
        { value: "Africa/Asmara", name: "Asmara" },
        { value: "Africa/Asmera", name: "Asmera" },
        { value: "Africa/Bamako", name: "Bamako" },
        { value: "Africa/Bangui", name: "Bangui" },
        { value: "Africa/Banjul", name: "Banjul" },
        { value: "Africa/Bissau", name: "Bissau" },
        { value: "Africa/Blantyre", name: "Blantyre" },
        { value: "Africa/Brazzaville", name: "Brazzaville" },
        { value: "Africa/Bujumbura", name: "Bujumbura" },
        { value: "Africa/Cairo", name: "Cairo" },
        { value: "Africa/Casablanca", name: "Casablanca" },
        { value: "Africa/Ceuta", name: "Ceuta" },
        { value: "Africa/Conakry", name: "Conakry" },
        { value: "Africa/Dakar", name: "Dakar" },
        { value: "Africa/Dar_es_Salaam", name: "Dar es Salaam" },
        { value: "Africa/Djibouti", name: "Djibouti" },
        { value: "Africa/Douala", name: "Douala" },
        { value: "Africa/El_Aaiun", name: "El Aaiun" },
        { value: "Africa/Freetown", name: "Freetown" },
        { value: "Africa/Gaborone", name: "Gaborone" },
        { value: "Africa/Harare", name: "Harare" },
        { value: "Africa/Johannesburg", name: "Johannesburg" },
        { value: "Africa/Kampala", name: "Kampala" },
        { value: "Africa/Khartoum", name: "Khartoum" },
        { value: "Africa/Kigali", name: "Kigali" },
        { value: "Africa/Kinshasa", name: "Kinshasa" },
        { value: "Africa/Lagos", name: "Lagos" },
        { value: "Africa/Libreville", name: "Libreville" },
        { value: "Africa/Lome", name: "Lome" },
        { value: "Africa/Luanda", name: "Luanda" },
        { value: "Africa/Lubumbashi", name: "Lubumbashi" },
        { value: "Africa/Lusaka", name: "Lusaka" },
        { value: "Africa/Malabo", name: "Malabo" },
        { value: "Africa/Maputo", name: "Maputo" },
        { value: "Africa/Maseru", name: "Maseru" },
        { value: "Africa/Mbabane", name: "Mbabane" },
        { value: "Africa/Mogadishu", name: "Mogadishu" },
        { value: "Africa/Monrovia", name: "Monrovia" },
        { value: "Africa/Nairobi", name: "Nairobi" },
        { value: "Africa/Ndjamena", name: "Ndjamena" },
        { value: "Africa/Niamey", name: "Niamey" },
        { value: "Africa/Nouakchott", name: "Nouakchott" },
        { value: "Africa/Ouagadougou", name: "Ouagadougou" },
        { value: "Africa/Porto-Novo", name: "Porto-Novo" },
        { value: "Africa/Sao_Tome", name: "Sao Tome" },
        { value: "Africa/Timbuktu", name: "Timbuktu" },
        { value: "Africa/Tripoli", name: "Tripoli" },
        { value: "Africa/Tunis", name: "Tunis" },
        { value: "Africa/Windhoek", name: "Windhoek" },
      ],
    },
    {
      group: "Australia",
      zones: [
        { value: "Australia/ACT", name: "ACT" },
        { value: "Australia/Adelaide", name: "Adelaide" },
        { value: "Australia/Brisbane", name: "Brisbane" },
        { value: "Australia/Broken_Hill", name: "Broken Hill" },
        { value: "Australia/Canberra", name: "Canberra" },
        { value: "Australia/Currie", name: "Currie" },
        { value: "Australia/Darwin", name: "Darwin" },
        { value: "Australia/Eucla", name: "Eucla" },
        { value: "Australia/Hobart", name: "Hobart" },
        { value: "Australia/LHI", name: "LHI" },
        { value: "Australia/Lindeman", name: "Lindeman" },
        { value: "Australia/Lord_Howe", name: "Lord Howe" },
        { value: "Australia/Melbourne", name: "Melbourne" },
        { value: "Australia/North", name: "North" },
        { value: "Australia/NSW", name: "NSW" },
        { value: "Australia/Perth", name: "Perth" },
        { value: "Australia/Queensland", name: "Queensland" },
        { value: "Australia/South", name: "South" },
        { value: "Australia/Sydney", name: "Sydney" },
        { value: "Australia/Tasmania", name: "Tasmania" },
        { value: "Australia/Victoria", name: "Victoria" },
        { value: "Australia/West", name: "West" },
        { value: "Australia/Yancowinna", name: "Yancowinna" },
      ],
    },
    {
      group: "Indian",
      zones: [
        { value: "Indian/Antananarivo", name: "Antananarivo" },
        { value: "Indian/Chagos", name: "Chagos" },
        { value: "Indian/Christmas", name: "Christmas" },
        { value: "Indian/Cocos", name: "Cocos" },
        { value: "Indian/Comoro", name: "Comoro" },
        { value: "Indian/Kerguelen", name: "Kerguelen" },
        { value: "Indian/Mahe", name: "Mahe" },
        { value: "Indian/Maldives", name: "Maldives" },
        { value: "Indian/Mauritius", name: "Mauritius" },
        { value: "Indian/Mayotte", name: "Mayotte" },
        { value: "Indian/Reunion", name: "Reunion" },
      ],
    },
    {
      group: "Atlantic",
      zones: [
        { value: "Atlantic/Azores", name: "Azores" },
        { value: "Atlantic/Bermuda", name: "Bermuda" },
        { value: "Atlantic/Canary", name: "Canary" },
        { value: "Atlantic/Cape_Verde", name: "Cape Verde" },
        { value: "Atlantic/Faeroe", name: "Faeroe" },
        { value: "Atlantic/Faroe", name: "Faroe" },
        { value: "Atlantic/Jan_Mayen", name: "Jan Mayen" },
        { value: "Atlantic/Madeira", name: "Madeira" },
        { value: "Atlantic/Reykjavik", name: "Reykjavik" },
        { value: "Atlantic/South_Georgia", name: "South Georgia" },
        { value: "Atlantic/Stanley", name: "Stanley" },
        { value: "Atlantic/St_Helena", name: "St Helena" },
      ],
    },
    {
      group: "Pacific",
      zones: [
        { value: "Pacific/Apia", name: "Apia" },
        { value: "Pacific/Auckland", name: "Auckland" },
        { value: "Pacific/Chatham", name: "Chatham" },
        { value: "Pacific/Easter", name: "Easter" },
        { value: "Pacific/Efate", name: "Efate" },
        { value: "Pacific/Enderbury", name: "Enderbury" },
        { value: "Pacific/Fakaofo", name: "Fakaofo" },
        { value: "Pacific/Fiji", name: "Fiji" },
        { value: "Pacific/Funafuti", name: "Funafuti" },
        { value: "Pacific/Galapagos", name: "Galapagos" },
        { value: "Pacific/Gambier", name: "Gambier" },
        { value: "Pacific/Guadalcanal", name: "Guadalcanal" },
        { value: "Pacific/Guam", name: "Guam" },
        { value: "Pacific/Honolulu", name: "Honolulu" },
        { value: "Pacific/Johnston", name: "Johnston" },
        { value: "Pacific/Kiritimati", name: "Kiritimati" },
        { value: "Pacific/Kosrae", name: "Kosrae" },
        { value: "Pacific/Kwajalein", name: "Kwajalein" },
        { value: "Pacific/Majuro", name: "Majuro" },
        { value: "Pacific/Marquesas", name: "Marquesas" },
        { value: "Pacific/Midway", name: "Midway" },
        { value: "Pacific/Nauru", name: "Nauru" },
        { value: "Pacific/Niue", name: "Niue" },
        { value: "Pacific/Norfolk", name: "Norfolk" },
        { value: "Pacific/Noumea", name: "Noumea" },
        { value: "Pacific/Pago_Pago", name: "Pago Pago" },
        { value: "Pacific/Palau", name: "Palau" },
        { value: "Pacific/Pitcairn", name: "Pitcairn" },
        { value: "Pacific/Ponape", name: "Ponape" },
        { value: "Pacific/Port_Moresby", name: "Port Moresby" },
        { value: "Pacific/Rarotonga", name: "Rarotonga" },
        { value: "Pacific/Saipan", name: "Saipan" },
        { value: "Pacific/Samoa", name: "Samoa" },
        { value: "Pacific/Tahiti", name: "Tahiti" },
        { value: "Pacific/Tarawa", name: "Tarawa" },
        { value: "Pacific/Tongatapu", name: "Tongatapu" },
        { value: "Pacific/Truk", name: "Truk" },
        { value: "Pacific/Wake", name: "Wake" },
        { value: "Pacific/Wallis", name: "Wallis" },
        { value: "Pacific/Yap", name: "Yap" },
      ],
    },
    {
      group: "Antarctica",
      zones: [
        { value: "Antarctica/Casey", name: "Casey" },
        { value: "Antarctica/Davis", name: "Davis" },
        { value: "Antarctica/DumontDUrville", name: "DumontDUrville" },
        { value: "Antarctica/Macquarie", name: "Macquarie" },
        { value: "Antarctica/Mawson", name: "Mawson" },
        { value: "Antarctica/McMurdo", name: "McMurdo" },
        { value: "Antarctica/Palmer", name: "Palmer" },
        { value: "Antarctica/Rothera", name: "Rothera" },
        { value: "Antarctica/South_Pole", name: "South Pole" },
        { value: "Antarctica/Syowa", name: "Syowa" },
        { value: "Antarctica/Vostok", name: "Vostok" },
      ],
    },
    {
      group: "Arctic",
      zones: [{ value: "Arctic/Longyearbyen", name: "Longyearbyen" }],
    },
    { group: "UTC", zones: [{ value: "UTC", name: "UTC" }] },
    {
      group: "Manual Offsets",
      zones: [
        { value: "UTC-12", name: "UTC-12" },
        { value: "UTC-11", name: "UTC-11" },
        { value: "UTC-10", name: "UTC-10" },
        { value: "UTC-9", name: "UTC-9" },
        { value: "UTC-8", name: "UTC-8" },
        { value: "UTC-7", name: "UTC-7" },
        { value: "UTC-6", name: "UTC-6" },
        { value: "UTC-5", name: "UTC-5" },
        { value: "UTC-4", name: "UTC-4" },
        { value: "UTC-3", name: "UTC-3" },
        { value: "UTC-2", name: "UTC-2" },
        { value: "UTC-1", name: "UTC-1" },
        { value: "UTC+0", name: "UTC+0" },
        { value: "UTC+1", name: "UTC+1" },
        { value: "UTC+2", name: "UTC+2" },
        { value: "UTC+3", name: "UTC+3" },
        { value: "UTC+4", name: "UTC+4" },
        { value: "UTC+5", name: "UTC+5" },
        { value: "UTC+6", name: "UTC+6" },
        { value: "UTC+7", name: "UTC+7" },
        { value: "UTC+8", name: "UTC+8" },
        { value: "UTC+9", name: "UTC+9" },
        { value: "UTC+10", name: "UTC+10" },
        { value: "UTC+11", name: "UTC+11" },
        { value: "UTC+12", name: "UTC+12" },
        { value: "UTC+13", name: "UTC+13" },
        { value: "UTC+14", name: "UTC+14" },
      ],
    },
  ];
}
