import { useEffect, useState } from "react";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import {
  getDashStartEndDates,
  getStartEndDates,
  getStartEndTime,
} from "../../context/CalendarDates";
import { Link, redirect, useNavigate } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import { getAuthUserInfo } from "../../utils/helpers";

function SessionHistory() {
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [selectedWeeklyDate, setSelectedWeeklyDate] = useState(null);

  // Add state variables to manage modal visibility
  const [isMonthlyModalOpen, setMonthlyModalOpen] = useState(false);
  const [isWeeklyModalOpen, setWeeklyModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = getAuthUserInfo();

  //fetch students

  function fetchSchedule(page = 1) {
    setLoading(true);
    api
      .get("list-session-history", {
        //params: { page },
        params: {
          page,
          year: selectedYear,
          month: selectedMonth,
          selected_date: selectedWeeklyDate,
        },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setSchedule(response.data);
      })
      .catch((error) => {
        if (error.response.headers) {
          // 403 unauthorized error

          toast.error(
            "Access denied ! You are not allowed to access this page"
          );
          navigate("/dashboard");
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSchedule();
    //}, []);
  }, [selectedYear, selectedMonth, selectedWeeklyDate]);

  //console.log("data", schedule.data);

  const years = Array.from(
    { length: new Date().getFullYear() - 2015 + 1 },
    (_, index) => 2015 + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleFilter = () => {
    // Fetch data based on selected filters
    fetchSchedule();
  };

  return (
    <section className="mx-auto w-full  px-5 lg:pb-4 lg:ml-2">
      <div className="w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex space-y-4 flex-row items-center justify-between md:space-y-0 py-2 lg:py-5  bg-white">
                <div>
                  <h2 className="text-lg text-dark p-4 font-semibold">
                    Session History
                  </h2>
                </div>

                <div className="flex space-x-6 mr-6">
                  <button
                    className="bg-white px-4 py-2 rounded-md text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                    onClick={() => setMonthlyModalOpen(true)}
                  >
                    Monthly
                  </button>
                  <button
                    className="bg-white px-4 py-2 rounded-md text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                    onClick={() => setWeeklyModalOpen(true)}
                  >
                    Weekly
                  </button>
                </div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Title</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Tutor</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Student</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Subject</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Date & Time</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Duration</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Attendance</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center text-sm font-medium text-gray-600"
                    >
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>

                

                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        <LoadingBlock loading={loading} />
                      </td>
                    </tr>
                  )}
                  {schedule?.data?.length > 0 ? (
                    schedule?.data?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {index + 1}
                        </td>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.event.topic}
                        </td>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.tutor.first_name}
                          <br />
                          {item.tutor.last_name}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.student.first_name}
                          <br />
                          {item.student.last_name}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.subject.title}
                        </td>

                        {/* Display formatted date and time based on the selected time zone */}

                        <td className="text-gray-900 text-sm font-normal whitespace-nowrap px-4 py-4">
                          <div>
                            <div>
                              {getDashStartEndDates(
                                item.start_date.date_time,
                                user.timezone
                              )}
                              <br />
                              {getStartEndTime(
                                item.start_date.raw,
                                user.timezone
                              )}
                              <br />
                              {getStartEndTime(
                                item.end_date.raw,
                                user.timezone
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.duration} mins
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.attendance_status.toLowerCase()}
                        </td>

                        <td className="text-black text-sm font-medium whitespace-nowrap px-4 py-4 text-center">
                          <Link to={`/session-history/${item.id}`}>
                            <button className="text-white px-3 py-2 bg-amber-500 rounded-lg border justify-center">
                              view session
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4">
                        No Session available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {schedule?.meta?.last_page > 1 && (
        <Pagination
          meta={schedule.meta}
          onChange={(page) => fetchSchedule(page)}
        />
      )}

      {/* Monthly Filter Modal */}
      {isMonthlyModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white px-12 py-10  rounded-md">
            <h2 className="text-xl font-semibold mb-4">Monthly Filter</h2>
            {/* Dropdown for selecting year */}
            <div className="flex mb-8">
              <select
                className="flex px-4 py-2  text-lime-600 border-2 border-lime-600  font-medium rounded-md mr-16"
                //value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                <option value={1900} selected>
                  Select Year
                </option>
                {years.map((year, index) => (
                  <option key={index} value={year}>
                    {year}
                  </option>
                ))}
              </select>

              {/* Dropdown for selecting month */}
              <select
                className="px-4 py-2 text-lime-600 border-2 border-lime-600  font-medium rounded-md"
                //value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              >
                <option value={0} selected>
                  Select Month
                </option>
                {months.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="bg-lime-600 text-white px-6 py-2 rounded-md ml-2"
              onClick={() => {
                handleFilter();
                setMonthlyModalOpen(false);
              }}
            >
              Apply
            </button>
            <button
              className="text-gray-600 px-4 py-2 rounded-md ml-32"
              onClick={() => setMonthlyModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Weekly Filter Modal */}
      {isWeeklyModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="fixed bg-white px-12 py-8 rounded-md">
            <h2 className="text-xl font-semibold mb-4">Weekly Filter</h2>
            <div>
              <input
                type="date"
                value={selectedWeeklyDate || ""}
                onChange={(e) => setSelectedWeeklyDate(e.target.value)}
                className="w-full px-10 py-4 mb-8 border-2  border-gray-300 rounded-md"
              />
            </div>
            {/* <div className="flex justify-around"> */}
            <button
              className="bg-lime-600 text-white px-4 py-2 rounded-md ml-2 mr-20"
              onClick={() => {
                handleFilter();
                setWeeklyModalOpen(false);
              }}
            >
              Apply
            </button>
            <button
              className="text-gray-600 px-4 py-2 rounded-md "
              onClick={() => setWeeklyModalOpen(false)}
            >
              Cancel
            </button>
            {/* </div> */}
          </div>
        </div>
      )}
    </section>
  );
}

export default SessionHistory;
