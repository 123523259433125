import React, { useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate, Link } from "react-router-dom";
import Errors from "../../utils/Errors";
import api from "../../api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { TimeZone } from "../../utils/TimeZone";
import Select from "react-select";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import "./Phoneinput.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function AddTutor() {
  const [showPrivileges, setShowPrivileges] = useState(false);
  const { data, loading, error } = useFetch("/subject"); // Fetch subject list
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedPrivileges, setSelectedPrivileges] = useState([]);
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);
  const [value, setValue] = useState();

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  // Options for react-select
  const timezoneOptions = TimeZone().reduce(
    (acc, group) =>
      acc.concat(
        group.zones.map((zone) => ({
          value: zone.value,
          label: `${group.group} - ${zone.name}`,
        }))
      ),
    []
  );

  const privileges = [
    {
      name: "Administrator",
      value: "administrator",
    },

    {
      name: "Edit Own Schedule",
      value: "edit_own_schedule",
    },

    {
      name: "Take Attendance",
      value: "take_attendance",
    },

    {
      name: "View Student/parent Email Addresses and phone numbers",
      value: "view_student_parent_detail",
    },

    {
      name: "Create/View Reports",
      value: "create_view_report",
    },
    {
      name: "View/Download Student Profile Attachments",
      value: "view_download_student_profile",
    },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#EFF7F9",
      borderColor: state.isFocused ? "#004CBD" : "#9ca3af",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    role: "tutor",
    address: "",
    subject: "",
    timezone: "",
    privilege: [],
  };

  const [form, setForm] = useState(initialState);

  // Function to handle privilege changes
  function handlePrivilegeChange(e) {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedPrivileges((prevSelected) => [...prevSelected, value]); // Add the value to the selected array
    } else {
      setSelectedPrivileges((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      ); // Remove the value from the selected array
    }
  }

  // Handle change event for react-select
  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value);
    setForm({ ...form, timezone: selectedOption.value });
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    //setForm({...form, phone_no:value});
    setValid(validatePhoneNumber(value));
  };

  // Handle form input change
  function handleChange(e) {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && name === "invitation_status") {
      // If the event target is the checkbox for invitation_status
      setForm((prevForm) => ({
        ...prevForm,
        [name]: checked ? 1 : 0, // Set invitation_status to 1 if checked, else 0
        status: checked ? 1 : 0, // Set status to 1 if checked, else 0
      }));
    } else {
      // For other input fields
      setForm({ ...form, [name]: value });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      // Include selected privileges in the form state
      const formData = {
        ...form,
        privilege: selectedPrivileges,
        phone_no: "+" + phoneNumber,
      };
      // console.log('form', formData);
      // return;

      const response = await api({
        method: "post",
        url: "store",
        data: formData,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Tutor Created Successfully!");

        navigate("/tutor");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  return (
    <div className="mx-auto w-full  px-5 lg:my-3 lg:pb-4  lg:flex-col ">
      <div className="inline-block  lg:ml-2 lg:mr-10 min-w-full pb-2 align-middle md:px-6 lg:lg:px-8   max-w-fit  gap-[16px] rounded-md border  bg-white  ">
        <div className=" font-bold text-xl  pt-8 text-dark">
          <Link
            to="/tutor"
            className="text-blue-500 text-start text-xs w-40 mt-2 ml-4 md:mr-80 mr-4"
          >
            <FontAwesomeIcon icon={faAnglesLeft} />
            Back to Tutor List
          </Link>
          Add Tutor
        </div>
        <div className="mb-8 mt-4">
          <div className="w-full flex flex-row   justify-start mx-4 lg:px-8 px-2  items-center">
            <label className="w-1/6  text-neutral-700 text-base font-medium">
              User Type:
            </label>
            <div className="w-5/6 flex flex-row items-center">
              <input
                type="radio"
                className=" md:m-4 m-1 border  w-10 border-gray-400 rounded mr-4 p-2  text-neutral-700  text-base font-normal"
                value="tutor"
                name="role"
                onChange={handleChange}
                checked={form.role === "tutor"}
              />
              {"Tutoring Staff "}

              <input
                type="radio"
                className="md:m-4 m-1 border w-10 border-gray-400 rounded lg:mr-4 p-2  text-neutral-700  text-base font-normal"
                value="nontutor"
                name="role"
                onChange={handleChange}
              />
              {"Non Tutoring Staff "}
            </div>
          </div>
        </div>

        {/* <div className="w-full flex mx-4 lg:px-8 px-2   ">
          <div className="w-full flex flex-col">
            <div className="mb-2 ">
              <div className="w-full flex flex-row  justify-start ">
                <label
                  className="w-1/6  text-neutral-700 text-base font-medium  mb-8 cursor-pointer"
                  onClick={() => setShowPrivileges(!showPrivileges)}
                >
                  Privileges {showPrivileges ? "▼" : "▲"}
                </label>
              </div>
              <div
                className="w-full flex flex-col items-center mb-6"
                style={{ display: showPrivileges ? "block" : "none" }}
              >
                {privileges.map((item, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="checkbox"
                        className="border border-gray-400 rounded p-2 mb-8 mr-2"
                        name={item.value}
                        value={item.value}
                        onChange={handlePrivilegeChange}
                      />
                      <span className="ml-3  text-neutral-700  text-base font-normal">
                        {item.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div> */}

        <div className="flex md:flex-row flex-col w-full justify-center  mx-4 lg:px-8 px-2  ">
          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                First Name:
              </label>

              <input
                type="text"
                className={`w-4/6 border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal${
                  errors.has("first_name") ? "border-red-500" : ""
                }`}
                placeholder="Your First Name"
                name="first_name"
                onChange={handleChange}
              />
            </div>
            {errors.has("first_name") && (
              <div className="text-red-500 mt-1 ml-32  text-sm">
                {errors.first("first_name")}
              </div>
            )}
          </div>

          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row   ">
              <label className="w-1/4  text-black text-base font-medium  mb-2">
                Last Name:
              </label>
              <input
                type="text"
                className="w-4/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Last Name"
                name="last_name"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col w-full justify-center mx-4 lg:px-8 px-2  ">
          <div className="mb-8 lg:w-1/2 w-full ">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                Email:
              </label>

              <input
                type="email"
                className={`w-4/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal${
                  errors.has("email") ? "border-red-500" : ""
                }`}
                placeholder="Your Email"
                name="email"
                onChange={handleChange}
              />
            </div>
            {errors.has("email") && (
              <div className="text-red-500 mt-1 ml-32  text-sm">
                {errors.first("email")}
              </div>
            )}
          </div>

          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                Address:
              </label>
              <input
                type="text"
                className="w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Address"
                name="address"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col  w-full justify-center  mx-4 lg:px-8 px-2  ">
          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                Phone:
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                className="  w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal "
              />

              {!valid && (
                <div className="text-red-500 mt-1 ml-32  text-sm">
                  Please enter a valid phone number.
                </div>
              )}
              {/* <input
                type="tel"
                className="w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Your Phone Number"
                name="phone_no"
                onChange={handleChange}
              /> */}
            </div>
          </div>

          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                Subject:
              </label>

              <select
                className={`w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal ${
                  errors.has("subject") ? "border-red-500" : ""
                }`}
                name="subject"
                onChange={handleChange}
              >
                <option value="selected" selected>
                  Select Subject
                </option>
                {data?.data?.map((sub, i) => (
                  <option value={sub.id} key={i}>
                    {sub.title}
                  </option>
                ))}
              </select>
            </div>
            {errors.has("subject") && (
              <div className="text-red-500 mt-1 ml-32 text-sm">
                {errors.first("subject")}
              </div>
            )}
          </div>
        </div>

        <div className="flex md:flex-row flex-col  w-full justify-center  mx-4 lg:px-8 px-2   ">
          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row   ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                TimeZone:
              </label>

              <Select
                className={`w-4/6  bg-main text-base ${
                  errors.has("timezone") ? "border-red-500" : ""
                }`}
                options={timezoneOptions}
                name="timezone"
                onChange={handleTimeZoneChange}
                value={timezoneOptions.find(
                  (option) => option.value === selectedTimeZone
                )}
                styles={customStyles}
                placeholder="search timezone"
              />
            </div>
            {errors.has("timezone") && (
              <div className="text-red-500 mt-1 ml-32 text-sm">
                {errors.first("timezone")}
              </div>
            )}
          </div>

          <div className="mb-8 lg:w-1/2 w-full">
            <div className="w-full flex flex-row items-center mt-2">
              <label className="w-1/9  text-neutral-700 text-base font-medium  ">
                Invite Tutor:
              </label>

              <input
                type="checkbox"
                className="1/6  border border-gray-400 rounded p-2  ml-8 mx-4 text-neutral-700"
                name="invitation_status"
                onChange={handleChange}
              />
              <p>Invite Tutors for the class</p>
            </div>
          </div>
        </div>

        <div className="mx-4 lg:px-8  ">
          <button
            className="bg-lime-600 text-white px-8  py-2 lg:mb-8 rounded-md"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddTutor;
