import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExtendSessionModal from "./ExtendSessionModal";

function EditConfirmModal({ isOpen, setIsConfirmModalOpen, sessionId, eventId }) {
  const [selectedOption, setSelectedOption] = useState(""); // State to keep track of the selected option
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false); 
  const navigate = useNavigate();

  const handleOptionChange = (option) => {
    setSelectedOption(option); // Update the selected option
    if (option === "extendSession") {
      setIsExtendModalOpen(true); // Open extend session modal if "extendSession" is selected
    } else {
      setIsExtendModalOpen(false); // Close extend session modal if another option is selected
    }
  };

  const handleConfirm = () => {
    // Handle confirm action based on the selected option
    if (selectedOption === "singleSession") {
      // Handle session only action
      navigate(`/edit-event/${sessionId}?editType=single`);
    } else if (selectedOption === "recurringSessions") {
      // Handle following sessions action
      navigate(`/edit-event/${sessionId}?editType=recuring`);
    } 
    
    // else if (selectedOption === "extendSession") {
    //   // Handle following sessions action
    //   navigate(`/edit-event/${sessionId}?editType=extend`);
    // }
  };

  return (
    <>
    <Dialog open={isOpen && !isExtendModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
      <DialogTitle>Confirm Edit Change</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to apply this change to all occurrences of the session?
        </DialogContentText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "singleSession"}
              onChange={() => handleOptionChange("singleSession")}
            />
            <span>No, apply to this one</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "recurringSessions"}
              onChange={() => handleOptionChange("recurringSessions")}
            />
            <span>Yes, apply this and future sessions</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "extendSession"}
              onChange={() => handleOptionChange("extendSession")}
            />
            <span>Extend this session</span>
            
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button color="primary" onClick={handleConfirm}>
              OK
            </Button>
            <Button
              color="primary"
              onClick={() => setIsConfirmModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
    <ExtendSessionModal isExtendModalOpen={isExtendModalOpen} setIsExtendModalOpen={setIsExtendModalOpen} eventId={eventId}/>
    </>
  );
}

export default EditConfirmModal;
