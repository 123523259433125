import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import AddSubject from "./AddSubject";
import EditSubject from "./EditSubject";
import Modal from "react-modal";
import LoadingBlock from "../../Components/LoadingBlock";
import Pagination from "../../Components/Pagination";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { DeleteSubjectAction } from "../../utils/ButtonActions";
import "../../css/Alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

function SubjectList() {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddSubjectModalOpen, setAddSubjectModalOpen] = useState(false);
  const [isEditSubjectModalOpen, setEditSubjectModalOpen] = useState(false);
  const [editSubjectId, setEditSubjectId] = useState(null);

  const ReactSwal = withReactContent(Swal);

  //fetch subjects

  function fetchSubjects(page = 1) {
    setLoading(true);
    api
      .get("list-subject", { params: { page } })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }
        setSubjects(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSubjects();
  }, []);

  // delete action

  async function DeleteSubject(subjectId) {
    const result = await ReactSwal.fire({
      title: <p className="text-center text-black">Confirm delete</p>,
      html: `
            <p className="Align">Are you sure you want to delete this subject? </p>
          `,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "delete-button-class",
        cancelButton: "cancel-button-class",
      },
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteSubjectAction(subjectId)
      .then((res) => {
        if (res) {
          // Manually update the subjects list after successful deletion
          const updatedSubjects = subjects.data.filter(
            (subject) => subject.id !== subjectId
          );

          setSubjects({ ...subjects, data: updatedSubjects });
        }
      })
      .catch((error) => console.log(error));
  }

  // Open add subject modal function
  const openAddSubjectModal = () => {
    setAddSubjectModalOpen(true);
  };

  // Open edit subject modal function
  const openEditSubjectModal = (subjectId) => {
    setEditSubjectId(subjectId);
    setEditSubjectModalOpen(true);
  };

  // Close modal function
  const closeModal = () => {
    setAddSubjectModalOpen(false);
    setEditSubjectModalOpen(false);
    fetchSubjects(); // Refresh subject list on modal close
  };

  return (
    <section className="mx-auto w-full  px-5  lg:pb-4 lg:ml-2">
      <div className=" w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
        <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex items-center justify-start py-2 bg-white">
                <h2 className="text-lg text-dark p-4 font-semibold mr-5">
                  Subjects
                </h2>
                <button
                  type="button"
                  onClick={openAddSubjectModal}
                  className="rounded-md bg-white px-6 py-2 text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                >
                  Add Subject +
                </button>
              </div>

              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5   text-center text-sm  font-medium text-gray-600 pl-8"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center text-sm  font-medium text-gray-600"
                    >
                      <h5>Title</h5>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center text-sm  font-medium text-gray-600"
                    >
                      <span>Status</span>
                    </th>

                    <th
                      scope="col"
                      className=" text-center text-sm  font-medium text-gray-600 ml-70"
                    >
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        <LoadingBlock loading={loading} />
                      </td>
                    </tr>
                  )}
                  {subjects?.data?.length > 0 ? (
                    subjects?.data?.map((sub, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-base text-center font-normal whitespace-nowrap px-3 py-4 pl-8">
                          {index + 1}
                        </td>
                        <td className="text-gray-900 text-base  font-normal text-center whitespace-nowrap px-4 py-4">
                          {sub.title}
                        </td>
                        <td className="text-gray-900 text-base font-normal text-center whitespace-nowrap px-4 py-4">
                          <span
                            className="px-4 py-1 relative inset-0 rounded-full"
                            style={{
                              color:
                                sub.status === 1
                                  ? "green"
                                  : sub.status === 0
                                  ? "red"
                                  : "inherit",
                              backgroundColor:
                                sub.status === 1
                                  ? "#EAF4EE"
                                  : sub.status === 0
                                  ? "#FCECEE"
                                  : "inherit",
                            }}
                          >
                            {sub.status === 1
                              ? "Active"
                              : sub.status === 0
                              ? "Inactive"
                              : ""}
                          </span>
                        </td>

                        <td className="text-black text-sm font-medium whitespace-nowrap text-center ml-70">
                          <button
                            className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center"
                            onClick={() => openEditSubjectModal(sub.id)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>

                          <button
                            className="text-white px-5 py-1 bg-red-700 rounded-lg border justify-center ml-4"
                            onClick={() => DeleteSubject(sub.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4">
                        No Subject available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {subjects?.meta?.last_page > 1 && (
        <Pagination
          meta={subjects.meta}
          onChange={(page) => fetchSubjects(page)}
        />
      )}

      {/* AddSubject Modal */}
      <Modal
        isOpen={isAddSubjectModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Subject Modal"
        className="lg:w-[600px] md:w-[450px] w-[380px] modal-content bg-white rounded-md shadow-md lg:p-8 p-2 lg:py-4 py-2 mx-auto mt-10"
        overlayClassName="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <button
          className="modal-close relative top-2 left-2 text-gray-600 justify-end"
          onClick={closeModal}
        >
          <div className="w-[400px] flex flex-row lg:ml-40 md:ml-24 ml-16 px-10 justify-center items-center">
            <h1 className="font-medium text-2xl mb-2 text-black ">
              {" "}
              Add Subject
            </h1>
            <div className="flex justify-end items-end mb-2 ml-40 text-black">
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </button>
        <AddSubject closeModal={closeModal} />
      </Modal>

      {/* EditSubject Modal */}
      <Modal
        isOpen={isEditSubjectModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Subject Modal"
        className="w-[600px] modal-content bg-white rounded-md shadow-md p-8 py-4 mx-auto mt-10"
        overlayClassName="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <button
          className="modal-close relative top-2 left-2 text-gray-600 justify-end"
          onClick={closeModal}
        >
          <div className="w-[400px] flex flex-row ml-40 px-10 justify-center items-center">
            <h1 className="font-medium text-2xl mb-2 text-black ">
              {" "}
              Edit Subject
            </h1>
            <div className="flex justify-end items-end mb-2 ml-40 text-black">
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </button>
        <EditSubject subjectId={editSubjectId} closeModal={closeModal} />
      </Modal>
    </section>
  );
}

export default SubjectList;
