import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <section className="container flex flex-col items-center justify-center h-screen">
      <h2 className="text-2xl font-bold mb-4">404 Page Not Found</h2>
      <p className="text-lg text-gray-600 mb-8">
        Oops! The page you are looking for does not exist.
      </p>
      <Link to="/">
        <button className="inline-flex w-full items-center justify-center rounded-md bg-yellow bg-amber-500 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80">
          Go to Home
        </button>
      </Link>
    </section>
  );
}

export default PageNotFound;
