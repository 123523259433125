import { useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-toastify";
import LoadingBlock from "../../../Components/LoadingBlock";
// import PhoneInput from "react-phone-input-2";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default function EditParentModal({ parentId, onClose }) {
  const [processing, setProcessing] = useState(false);

  const initialState = {
    first_name: "",
    last_name: "",
    phone_no: "",
    mobile_no: "",
    role: "parent",
    address: "",
  };
  const [form, setForm] = useState(initialState);
  const [value, setValue] = useState();

  //get parent detail
  function fetchParentDetail() {
    setProcessing(true);
    api
      .get(`edit-parent/${parentId}`)
      .then((res) => {
        let res_data = res.data.data;

        setForm({
          ...form,
          first_name: res_data.first_name,
          last_name: res_data.last_name,
          email: res_data.email,
          phone_no: res_data.phone_no,
          mobile_no: res_data.mobile_no,
          role: res_data.role,
          address: res_data.address,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setProcessing(false));
  }

  useEffect(() => {
    fetchParentDetail();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  function handleSubmit(e) {
    setProcessing(true);
    e.preventDefault();

    api
      .post(`/update-parent/${parentId}`, form)
      .then((res) => {
        toast.success("Student Updated !");
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          toast.error("Failed to process form");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setProcessing(false));
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center ">
      <div className=" w-5/6   pb-2 align-middle md:px-6 max-w-fit  gap-[16px] rounded-md border  bg-white m-2 p-2 ">
        <div className="mb-4 mt-4">
          <h2 className="text-dark font-bold  px-2">Edit Parent</h2>
          {processing && <LoadingBlock>Loading...</LoadingBlock>}
        </div>

        <div className="flex md:flex-row flex-col w-full justify-center  mb-2">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5  text-neutral-700 text-base font-medium  mb-2 ">
                First Name:
              </label>

              <input
                type="text"
                className="w-5/6 border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your First Name"
                name="first_name"
                value={form.first_name}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row   ">
              <label className="w-2/5  text-black text-base font-medium  mb-2 text-nowrap">
                Last Name:
              </label>
              <input
                type="text"
                className="w-5/6 border border-gray-400 bg-main  rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Last Name"
                name="last_name"
                value={form.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col w-full justify-center ">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Email:
              </label>

              <input
                type="email"
                className="w-5/6 border border-gray-400 bg-main rounded p-2 text-neutral-700  text-base font-normal"
                placeholder="Your Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="w-4/6 ml-28 text-xs text-red-500">
              Email can't be updated!
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Address:
              </label>
              <input
                type="text"
                className="w-5/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Address"
                name="address"
                value={form.address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full justify-center  ">
          <div className="mb-4 md:w-1/2 w-full">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Phone:
              </label>
              {/* <PhoneInput
                country={"np"}
                value={form.phone_no}
                onChange={(phone) =>
                  setForm({ ...form, phone_no: "+" + phone })
                }
                className="w-4/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                disabled={!valid}
              /> */}
              <PhoneInput
                placeholder="Enter phone number"
                value={form.phone_no}
                onChange={setValue}
                className="md:w-[190px] w-[190px] border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal "
              />
            </div>
          </div>

          <div className="mb-4 md:w-1/2 w-full md:ml-4">
            <div className="w-full flex flex-row  ">
              <label className="w-2/5 text-neutral-700 text-base font-medium  mb-2">
                Notes:
              </label>
              <textarea
                className="w-5/6 border border-gray-400 rounded p-2 bg-main  text-neutral-700  text-base font-normal"
                placeholder="Add Notes"
                name="notes"
                value={form.notes}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="">
          <button
            className="bg-lime-600 text-white px-8 py-2 lg:mb-4 rounded-md"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "update"}
          </button>
          <button
            className="text-gray-600 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
