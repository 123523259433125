import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEdit,
  faTrashAlt,
  faUserCheck,
  faEnvelope,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import EditConfirmModal from "./EditConfirmModal";
import DeleteConfirmModal from "./DeleteConfirmModal";

const ClassEventDetail = ({ appointmentData }) => {
  //console.log("Appointment Data:", appointmentData);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false); // State for edit confirmation modal
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false); // State for delete confirmation modal
  const [sessionId, setSessionId] = useState(null); // State to store session ID

  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();
  if (!appointmentData) {
    // You can return a placeholder or render nothing in this case
    return null;
  }
  const {
    id,
    eventId,
    topic,
    startDate,
    endDate,
    startTime,
    endTime,
    studentTitle,
    subjectTitle,
    tutorTitle,
    zoomLink,
    meetingID,
    passCode,
    attendanceTaken,
  } = appointmentData || {};

  // Format dates without time
  // const formattedStartDate = startDate
  //   ? new Date(startDate).toLocaleDateString()
  //   : "";
  // const formattedEndDate = endDate
  //   ? new Date(endDate).toLocaleDateString()
  //   : "";

  //const attendanceTaken = Boolean(data.attendance);
  const isFutureDate = startDate
    ? new Date(startDate) >= new Date().setHours(0, 0, 0, 0)
    : false;

  // Function to handle edit click and open confirmation modal

  const handleEditClick = (sessionId) => {
    setSessionId(sessionId); // Set session ID
    setShowEditConfirmModal(true);
  };

  const handleDeleteClick = (sessionId) => {
    setSessionId(sessionId); // Set session ID
    setShowDeleteConfirmModal(true);
  };

  return (
    <div className="relative w-auto h-auto rounded-xl overflow-hidden mb-8 ">
      <div className="m-2">
        <div className="pl-2 flex items-center justify-between">
          <div className="text-gray-800 text-xl font-semibold mr-2 mb-2">
            {topic || ""}
          </div>
        </div>

        <div>
          <div className="flex items-center p-2 ">
            <span className="text-slate-600 text-sm font-bold pr-4">
              Meeting Link:
            </span>
            <a
              href={isFutureDate && zoomLink} // enable for only future dates
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-600 text-sm font-bold break-all"
            >
              {zoomLink}
            </a>
          </div>

          <div className="flex items-center p-2 mb-2">
            <div>
              <div className="mb-2">
                <span className="text-slate-600 text-sm font-bold">
                  Meeting ID:
                </span>
                <span className="text-slate-600 text-sm font-bold pl-2">
                  {meetingID}
                </span>
              </div>
              <div className="">
                <span className="text-slate-600 text-sm font-bold pr-2">
                  Passcode:
                </span>
                <span className="text-slate-600 text-sm font-bold">
                  {passCode}
                </span>
              </div>
            </div>
          </div>

          <div className="pl-3 mb-3">
            <span className="text-slate-600 text-sm font-bold ">
              <FontAwesomeIcon icon={faClock} /> {startTime} - {endTime}
            </span>
          </div>
        </div>
        {/* </div> */}

        <div className="p-2 mt-2 mb-2 flex items-center">
          <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-slate-600" />
          <span className="text-slate-600 text-sm font-bold pr-2">
            Attendees:
          </span>
          <span className="text-slate-600 text-sm font-bold">
            {studentTitle || ""}
          </span>
          {/* <FontAwesomeIcon icon={faEnvelope} className="ml-4 text-lime-600" /> */}
        </div>

        <div className="p-2 mt-2 mb-2 flex items-center">
          <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-slate-600" />
          <span className="text-slate-600 text-sm font-bold pr-2">
            Subject:
          </span>
          <span className="text-slate-600 text-sm font-bold">
            {subjectTitle || ""}
          </span>
          {/* <FontAwesomeIcon icon={faEnvelope} className="ml-4 text-lime-600" /> */}
        </div>

        <div className="p-2 mt-2 mb-2 flex items-center">
          <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-slate-600" />
          <span className="text-slate-600 text-sm font-bold pr-2">Tutor:</span>
          <span className="text-slate-600 text-sm font-bold">
            {tutorTitle || ""}
          </span>
          {/* <FontAwesomeIcon icon={faEnvelope} className="ml-4 text-lime-600" /> */}
        </div>
        <div className="divider-line left-0 top-[273px] w-full h-0.5 bg-zinc-200 mt-2" />
        {isFutureDate && (
          <div className="p-2 flex gap-4  bottom-6 justify-center">
            {user.role === "superadmin" && (
              <>
                {/* <Link to={`/class/edit/${id}`}> */}
                <button
                  className="btn-edit text-slate-600 text-sm font-bold leading-tight"
                  onClick={() => handleEditClick(id)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="mr-2 text-blue-600"
                  />
                  Edit
                </button>
                {/* </Link> */}
                <button
                  className="btn-delete text-slate-600 text-sm font-bold leading-tight"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="mr-2 text-red-600"
                  />
                  Delete
                </button>
              </>
            )}

            {/* Edit Attendance Button */}
            {(user.role === "superadmin" ||
              user.role === "tutor" ||
              user.role === "nontutor") &&
              attendanceTaken === 1 && (
                <Link to={`/edit-attendance/${id}`}>
                  <button className="btn-attendance text-slate-600 text-sm font-bold leading-tight">
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      className="mr-2 text-amber-500"
                    />
                    Edit Attendance
                  </button>
                </Link>
              )}

            {/* Take Attendance Button */}
            {(user.role === "superadmin" ||
              user.role === "tutor" ||
              user.role === "nontutor") &&
              !attendanceTaken && (
                <Link to={`/attendance/${id}`}>
                  <button className="btn-attendance text-slate-600 text-sm font-bold leading-tight">
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      className="mr-2 text-amber-500"
                    />
                    Take Attendance
                  </button>
                </Link>
              )}

            {user.role === "superadmin" && (
              <Link to={`/message/${id}`}>
                <button className="btn-email text-slate-600 text-sm font-bold leading-tight">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="mr-2 text-lime-600"
                  />
                  Email
                </button>
              </Link>
            )}
          </div>
        )}

        {/* Confirmation Modal */}
        {showEditConfirmModal && (
          <EditConfirmModal
            isOpen={showEditConfirmModal}
            setIsConfirmModalOpen={setShowEditConfirmModal}
            sessionId={sessionId}
            eventId={eventId}
          />
        )}

        {showDeleteConfirmModal && (
          <DeleteConfirmModal
            isOpen={showDeleteConfirmModal}
            setDeleteConfirmModalOpen={setShowDeleteConfirmModal}
            sessionId={sessionId}
          />
        )}
      </div>
    </div>

    // <div className="relative w-auto h-[250px] rounded-xl overflow-hidden ">
    //   <div className="m-2">
    //     <div className="pl-2 flex items-center justify-between">
    //       <div className="text-gray-800 text-xl font-semibold mr-2 mb-2">
    //         {topic || ""}
    //       </div>
    //     </div>

    //     <div>
    //       <div className="flex items-center p-2 ">
    //         <span className="text-slate-600 text-sm font-bold pr-4">
    //           Meeting Link:
    //         </span>
    //         <a
    //           href={isFutureDate && zoomLink} // enable for only future dates
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="text-slate-600 text-sm font-bold break-all"
    //         >
    //           {zoomLink}
    //         </a>
    //       </div>

    //       <div className="flex items-center p-2 mb-2">

    //           <span className="text-slate-600 text-sm font-bold ">
    //             <FontAwesomeIcon icon={faClock} /> {startTime} - {endTime}
    //           </span>

    //       </div>

    //       <div className="p-2 mt-2 mb-2 flex items-center">
    //       <FontAwesomeIcon icon={faBook} className="mr-2 text-slate-600" />
    //       <span className="text-slate-600 text-sm font-bold pr-2">
    //         Subject:
    //       </span>
    //       <span className="text-slate-600 text-sm font-bold">
    //         {subjectTitle || ""}
    //       </span>
    //     </div>
    //     </div>

    //     <div className="p-2 mt-2 mb-2 flex items-center">
    //       <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-slate-600" />
    //       <span className="text-slate-600 text-sm font-bold pr-2">
    //         Attendees:
    //       </span>
    //       <span className="text-slate-600 text-sm font-bold">
    //         {studentTitle || ""}
    //       </span>
    //     </div>
    //     <div className="p-2 mt-2 mb-2 flex items-center">
    //       <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-slate-600" />
    //       <span className="text-slate-600 text-sm font-bold pr-2">
    //         Tutor:
    //       </span>
    //       <span className="text-slate-600 text-sm font-bold">
    //         {tutorTitle || ""}
    //       </span>
    //     </div>

    //     <div className="divider-line left-0 top-[273px] w-full h-0.5 bg-zinc-200 mt-2" />
    //     {isFutureDate && (
    //       <div className="action-buttons p-2 flex gap-4 absolute  justify-center">
    //         {user.role === "superadmin" && (
    //           <>
    //             <button
    //               className="btn-edit text-slate-600 text-sm font-bold leading-tight"
    //               onClick={() => handleEditClick(id)}
    //             >
    //               <FontAwesomeIcon
    //                 icon={faEdit}
    //                 className="mr-2 text-blue-600"
    //               />
    //               Edit
    //             </button>
    //             <button
    //               className="btn-delete text-slate-600 text-sm font-bold leading-tight"
    //               onClick={() => handleDeleteClick(id)}
    //             >
    //               <FontAwesomeIcon
    //                 icon={faTrashAlt}
    //                 className="mr-2 text-red-600"
    //               />
    //               Delete
    //             </button>
    //           </>
    //         )}

    //         {(user.role === "superadmin" ||
    //           user.role === "tutor" ||
    //           user.role === "nontutor") &&
    //           attendanceTaken === 1 && (
    //             <Link to={`/edit-attendance/${id}`}>
    //               <button className="btn-attendance text-slate-600 text-sm font-bold leading-tight">
    //                 <FontAwesomeIcon
    //                   icon={faUserCheck}
    //                   className="mr-2 text-amber-500"
    //                 />
    //                 Edit Attendance
    //               </button>
    //             </Link>
    //           )}

    //         {(user.role === "superadmin" ||
    //           user.role === "tutor" ||
    //           user.role === "nontutor") &&
    //           !attendanceTaken && (
    //             <Link to={`/attendance/${id}`}>
    //               <button className="btn-attendance text-slate-600 text-sm font-bold leading-tight">
    //                 <FontAwesomeIcon
    //                   icon={faUserCheck}
    //                   className="mr-2 text-amber-500"
    //                 />
    //                 Take Attendance
    //               </button>
    //             </Link>
    //           )}

    //         {user.role === "superadmin" && (
    //           <Link to={`/message/${id}`}>
    //             <button className="btn-email text-slate-600 text-sm font-bold leading-tight">
    //               <FontAwesomeIcon
    //                 icon={faEnvelope}
    //                 className="mr-2 text-lime-600"
    //               />
    //               Email
    //             </button>
    //           </Link>
    //         )}
    //       </div>
    //     )}

    //     {showEditConfirmModal && (
    //       <EditConfirmModal
    //         isOpen={showEditConfirmModal}
    //         setIsConfirmModalOpen={setShowEditConfirmModal}
    //         sessionId={sessionId}
    //         eventId={eventId}
    //       />
    //     )}

    //     {showDeleteConfirmModal && (
    //       <DeleteConfirmModal
    //         isOpen={showDeleteConfirmModal}
    //         setDeleteConfirmModalOpen={setShowDeleteConfirmModal}
    //         sessionId={sessionId}
    //       />
    //     )}
    //   </div>
    // </div>
  );
};

export default ClassEventDetail;
