import React, { useEffect, useState } from "react";

import logo from "../../Assets/Images/Logo.png";
import Illustration from "../../Assets/Images/Signup.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Errors from "../../utils/Errors";
import api from "../../api";
import { toast } from "react-toastify";
function PasswordReset() {
  //const [searchParams] = useSearchParams();
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const [state, setState] = useState({
    //token: searchParams.get("token"),
    token: token,
    email: searchParams.get("email"),
    password: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await api({
        method: "post",
        url: "reset-password",
        data: state,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(
          "Password changed successfully, please login to continue !"
        );
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error(error.response.data.message);
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }
      } else {
        console.error(error);
        toast.error("Something went wrong !");
      }
    }
  }

  return (
    <section className="flex flex-wrap">
      <div className="w-full lg:w-1/2 p-4">
        <img src={Illustration} alt="Signup" className="w-full h-auto mt-10" />
      </div>
      <div className="w-full lg:w-1/2 p-4">
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            className="w-50 h-26  mr-80 mt-5  mx-auto"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center px-4  sm:px-6 sm:py-16 lg:px-8 ">
            <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
              <h2 className="text-3xl font-bold leading-tight text-green-700 sm:text-4xl">
                Reset Password
              </h2>

              {/* <form action="#" method="POST" className="mt-8"> */}
              <div className="space-y-5">
                <div>
                  <label
                    htmlFor="password"
                    className="text-base font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={state.password}
                      onChange={(e) =>
                        setState({ ...state, password: e.target.value })
                      }
                    ></input>
                    <em>
                      Minimum 8 characters,Uppercase & lowercase
                      characters(A-Z),Digits(0-9)
                    </em>

                    {errors.has("password") && (
                      <div className="text-red-500 mt-1 ml-2 text-sm">
                        {errors.first("password")}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="text-base font-medium text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmPassword"
                      name="password_confirmation"
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="password"
                      placeholder="Confirm Password"
                      autoComplete="false"
                      value={state.password_confirmation}
                      onChange={(e) =>
                        setState({
                          ...state,
                          password_confirmation: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex w-full items-center justify-center rounded-md bg-yellow bg-amber-500 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {processing ? "please wait..." : "Submit"}{" "}
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PasswordReset;
