import React, { useState } from "react";
import Select from "react-select";

const AssignTutorPopup = ({ onClose }) => {
  const [billingOption, setBillingOption] = useState("lessons");

  const handleChange = (event) => {
    setBillingOption(event.target.value);
  };

  const lessonCategoryOptions = [
    { value: "math", label: "Math" },
    { value: "science", label: "Science" },
    { value: "history", label: "History" },
  ];
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      <div className="relative bg-white p-8 rounded-lg max-w-screen-md w-full h-[90vh]">
        <div className="bg-lime-600 h-16 mb-10 rounded-t-lg flex items-center justify-center text-white text-2xl font-semibold absolute top-0 left-0 right-0 w-full">
          Tutor's Student
        </div>

        <div
          onClick={onClose}
          className="absolute top-4 right-4 p-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="text-neutral-900 text-base font-medium mb-6">
          <p className="mt-10">
            Add a payment to a tutor any time you give a check or make a payment
            to them. TutorBird will automatically add income they're owed based
            on their schedule.
          </p>
        </div>

        <div className="w-full flex flex-row items-center space-y-4">
          <label className="w-2/6  text-black text-base font-normal  mb-2">
            Student:
          </label>
          <input
            type="text"
            id="student"
            className="w-3/4 border text-base font-normal  text-neutral-700 border-gray-400 rounded p-2"
            placeholder="Enter student name"
          />
        </div>

        <div className="w-full flex flex-row items-center space-y-4">
          <label className="w-2/6  text-black text-base font-normal  mb-2">
            Default Lesson Category:
          </label>
          <Select
            id="category"
            className="w-3/4  text-base font-normal   text-neutral-700"
            options={lessonCategoryOptions}
            placeholder="Select lesson category"
          />
        </div>

        <div className="w-full flex flex-row items-center space-y-4">
          <label className="w-2/6  text-black text-base font-normal  mb-2">
            Default Lesson Length:
          </label>
          <input
            type="text"
            id="length"
            className="w-3/4 border text-base font-normal  text-neutral-700 border-gray-400 rounded p-2"
            placeholder="Enter lesson length"
          />
        </div>

        <div className="w-full flex flex-row items-start mt-4">
          <label className="w-2/6  text-black text-base font-normal  mb-2">
            Default Billing:
          </label>
          <div className="w-3/4 flex flex-col items-center space-y-4">
            <div className="flex flex-col items-start space-y-2 ml-6">
              <div className="w-full flex items-center">
                <input
                  type="radio"
                  className="border border-gray-400 text-base font-normal  text-neutral-700 rounded p-2 m-2"
                  value="lessons"
                  name="billing"
                  onChange={handleChange}
                  checked={billingOption === "lessons"}
                />
                <span className=" text-neutral-700 text-base font-normal ml-2">
                  Student pays based on the number of lessons taken
                </span>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  className="border border-gray-400 text-base font-normal  text-neutral-700 rounded p-2 m-2"
                  value="hourly"
                  name="billing"
                  onChange={handleChange}
                  checked={billingOption === "hourly"}
                />
                <span className=" text-neutral-700 text-base font-normal ml-2">
                  Student pays an hourly rate (charges will automatically adjust
                  to lesson length)
                </span>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  className=" text-neutral-700 text-base font-normal border border-gray-400 rounded p-2 m-2"
                  value="none"
                  name="billing"
                  onChange={handleChange}
                  checked={billingOption === "none"}
                />
                <span className=" text-neutral-700 text-base font-normal ml-2">
                  Don't create any charges automatically from the schedule
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-row items-center space-y-4">
          <label className="w-2/6  text-black text-base font-normal  mb-2">
            Make-up Credits:
          </label>
          <input
            type="text"
            id="credits"
            className="w-3/4 border text-base font-normal  text-neutral-700 border-gray-400 rounded p-2"
            placeholder="Enter make-up credits"
          />
        </div>

        {/* Save Button */}
        <div className="absolute bottom-8 right-8">
          <div className="px-5 py-1 bg-amber-500 rounded-lg border justify-center items-center gap-1 inline-flex cursor-pointer">
            <div className="text-white text-base font-medium">Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTutorPopup;
