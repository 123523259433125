import { toast } from "react-toastify";
import api from "../../api";

// export function UpdateSingleSession(sessionId, formData) {
//   const promise = api.post(`/update-single-session/${sessionId}`, formData);
//   toast.promise(promise, {
//     pending: "Updating Class",
//     success: "Class has been updated",
//     error: "Error while updating this class !",
//   });
//   return promise;
// }

export function UpdateSingleSession(sessionId, formData) {
  const promise = api.post(`/update-single-session/${sessionId}`, formData);
  return promise
    .then((res) => {
      toast.success("Class has been updated");
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      const errorMessage =
        error.response.data.message ||
        "An error occurred while updating this class!";
      toast.error(errorMessage);
      throw error; // Rethrow the error to maintain the rejected state of the promise chain
    });
}

export function UpdateRecurringSession(sessionId, formData) {
  const promise = api.post(`/update-recuring-session/${sessionId}`, formData);
  // toast.promise(promise, {
  //   pending: "Updating Class",
  //   success: "Class has been updated",
  //   error: "Error while updating this class !",
  // });
  // return promise;

  return promise
    .then((res) => {
      toast.success("Class has been updated");
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      const errorMessage =
        error.response.data.message ||
        "An error occurred while updating this class!";
      toast.error(errorMessage);
      throw error; // Rethrow the error to maintain the rejected state of the promise chain
    });
}

export function extendSession(eventId, formData) {
  const promise = api.post(`/extend-schedule-session/${eventId}`, formData);
  // toast.promise(promise, {
  //   pending: "Updating Class",
  //   success: "Class has been updated",
  //   error: "Error while updating this class !",
  // });
  // return promise;

  return promise
    .then((res) => {
      toast.success("Class has been extended");
      return res.data; // Return the response data on success
    })
    .catch((error) => {
      const errorMessage =
        error.response.data.message 
      toast.error(errorMessage);
      throw error; // Rethrow the error to maintain the rejected state of the promise chain
    });
}

export function DeleteSingleSession(sessionId) {
  const promise = api.delete(`delete-single-session/${sessionId}`);
  toast.promise(promise, {
    pending: "Deleting Session",
    success: "Session has been deleted",
    error: "Error while deleting this Session !",
  });
  return promise;
}

export function DeleteRecurringSession(sessionId) {
  const promise = api.delete(`delete-recurring-session/${sessionId}`);
  toast.promise(promise, {
    pending: "Deleting Session",
    success: "Session has been deleted",
    error: "Error while deleting this Session !",
  });
  return promise;
}

export function DeleteaAllSession(sessionId) {
  const promise = api.delete(`delete-all-session/${sessionId}`);
  toast.promise(promise, {
    pending: "Deleting Session",
    success: "Session has been deleted",
    error: "Error while deleting this Session !",
  });
  return promise;
}
