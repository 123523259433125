import React, {useEffect, useState } from "react";
import api from "../../api";
import useFetch from "../../hooks/useFetch";
import Errors from "../../utils/Errors";
import { toast } from "react-toastify";
import LoadingBlock from "../LoadingBlock";

const PasswordResetModal = ({ userId, onClose, redirect }) => {
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const { data, loading, error } = useFetch(`user-info/${userId}`);

  // const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  // let user = getAuthUser();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (data && data.data && data.data.email) {
      setState((prevState) => ({
        ...prevState,
        email: data.data.email,
      }));
    }
  }, [data]);

  async function handleResetPassword(e) {
    e.preventDefault();
    setProcessing(true);

    // Call your API to reset the password

    try {
      const response = await api({
        method: "post",
        url: "reset-user-password",
        data: state,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Password changed successfully");
        onClose(); // Close the modal
        redirect();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error(error.response.data.errors);
          //toast.error("Failed to process form !");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }
      } else {
        console.error(error);
        toast.error("Something went wrong !");
      }
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="fixed bg-white p-8 rounded-md max-w-md">
        <h2 className="text-lg font-semibold mb-4">Reset Password</h2>
        <div className="mb-4">
          <label
            htmlFor="newPassword"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            New Password
          </label>
          {loading && <LoadingBlock>Loading...</LoadingBlock>}
          <input
            type="password"
            id="newPassword"
            name="password"
            autoComplete="new-password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            placeholder="Enter new password"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <em>
          Minimum 8 characters,Uppercase & lowercase characters(A-Z),Digits(0-9)
        </em>

        {errors.has("password") && (
          <div className="text-red-500 mt-1 ml-2 text-sm">
            {errors.first("password")}
          </div>
        )}

        <div className="mb-6">
          <label
            htmlFor="confirmPassword"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="password_confirmation"
            onChange={(e) =>
              setState({
                ...state,
                password_confirmation: e.target.value,
              })
            }
            placeholder="Confirm new password"
            autoComplete="false"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="flex justify-end">
          <button
            className="bg-lime-600 text-white px-4 py-2 rounded-md mr-2"
            onClick={handleResetPassword}
          >
            {processing ? "Resetting..." : "Reset Password"}
          </button>
          <button
            className="text-gray-600 px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetModal;
