import moment from "moment-timezone";
import { useEffect, useState } from "react";

const Timer = ({ rowData, startDateTime, userTimezone }) => {
  const calculateTimeLeft = () => {
    //const difference = +new Date(startDateTime) - +new Date();
    const startDateInUserTimezone = moment.utc(startDateTime).tz(userTimezone);
    const difference = startDateInUserTimezone - moment();
    let timeLeft = {};

    if (difference > 0) {
      // Convert the startDateTime to the user's timezone
      const startDateInUserTimezone = moment
        .utc(startDateTime)
        .tz(userTimezone);

      // Calculate the difference using the user's timezone
      //const differenceInUserTimezone = startDateInUserTimezone - moment();

      timeLeft = {
        days: startDateInUserTimezone.diff(moment(), "days"),
        hours: startDateInUserTimezone.diff(moment(), "hours") % 24,
        minutes: startDateInUserTimezone.diff(moment(), "minutes") % 60,
        seconds: startDateInUserTimezone.diff(moment(), "seconds") % 60,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  // Show the "Start Class" button when there are 15 minutes or less left until the class starts
  if (timeLeft.minutes < 15 && timeLeft.hours === 0 && timeLeft.days === 0) {
    return (
      <a href={rowData.zoom_link} target="_blank" rel="noopener noreferrer">
        <button className="text-blue-500 hover:bg-blue-500 hover:text-white px-4 py-1 rounded-full transition duration-300 whitespace-nowrap">
          Start Class
        </button>
      </a>
    );
  } else if (timeLeft.days > 0) {
    return <div>After {timeLeft.days} days</div>;
  } else if (timeLeft.hours || timeLeft.minutes || timeLeft.seconds) {
    return (
      <div>
        {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:
        {formatTime(timeLeft.seconds)}
      </div>
    );
  } else {
    return null;
  }
};

export default Timer;
