import React, { useState } from "react";
import Errors from "../../utils/Errors";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { toast } from "react-toastify";
import { TimeZone } from "../../utils/TimeZone";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";

const AddParent = () => {
  const [errors, setErrors] = useState(new Errors());
  const [processing, setProcessing] = useState(false);
  const [showSecondaryParent, setShowSecondaryParent] = useState(false);
  const navigate = useNavigate();
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  const { studentId } = useParams();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  // Options for react-select
  const timezoneOptions = TimeZone().reduce(
    (acc, group) =>
      acc.concat(
        group.zones.map((zone) => ({
          value: zone.value,
          label: `${group.group} - ${zone.name}`,
        }))
      ),
    []
  );

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    phone_no: "",
    role: "parent",
    notes: "",
    student_id: studentId,
    //sms_capable: 1,
    timezone: "",
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#EFF7F9",
      borderColor: state.isFocused ? "#004CBD" : "#9ca3af",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  const [form, setForm] = useState(initialState);

  const toggleSecondaryParent = () => {
    setShowSecondaryParent(!showSecondaryParent);
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    //setForm({...form, phone_no:value});
    setValid(validatePhoneNumber(value));
  };

  function handleChange(e) {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && name === "invitation_status") {
      // If the event target is the checkbox for invitation_status
      setForm((prevForm) => ({
        ...prevForm,
        [name]: checked ? 1 : 0, // Set invitation_status to 1 if checked, else 0
        status: checked ? 1 : 0, // Set status to 1 if checked, else 0
      }));
    } else {
      // For other input fields
      setForm({ ...form, [name]: value });
    }
  }

  // Handle change event for react-select
  const handleTimeZoneChange = (selectedOption) => {
    setSelectedTimeZone(selectedOption.value);
    setForm({ ...form, timezone: selectedOption.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    try {
      const formData = {
        ...form,
        phone_no: "+" + phoneNumber,
      };

      const response = await api({
        method: "post",
        url: "store",
        data: formData,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Parent Created Sucessfully !");

        navigate(`/student`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  return (
    <div className="mx-auto w-full px-5 lg:my-3 lg:pb-4  lg:flex-col">
      <div className="inline-block  lg:ml-2 lg:mr-10 min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  gap-[16px] rounded-md border  bg-white  overflow-x-auto">
        <div className="w-full  px-6 flex flex-col justify-center relative lg:pt-6 pt-3 ">
          <h1 class="text-xl font-medium mt-2 max-w-[400px] mx-4 px-4 text-dark">
            Primary Parent info
          </h1>

          <div className="mb-4 mt-4 md:mt-7">
            <div className="flex flex-row w-full justify-center items-center mx-4 px-8 mt-4">
              <div className="mb-4 w-1/2">
                <div className="w-full flex flex-row  ">
                  <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                    First Name:
                  </label>

                  <input
                    type="text"
                    className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                      errors.has("first_name") ? "border-red-500" : ""
                    }`}
                    placeholder="Your First Name"
                    name="first_name"
                    onChange={handleChange}
                  />

                  {errors.has("first_name") && (
                    <div className="text-red-500 mt-1 ml-2 text-sm">
                      {errors.first("first_name")}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4 w-1/2">
                <div className="w-full flex flex-row   ">
                  <label className="w-1/4  text-black text-base font-medium  mb-2">
                    Last Name:
                  </label>
                  <input
                    type="text"
                    className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                    placeholder="Your Last Name"
                    name="last_name"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full justify-center items-center mx-4 px-8 mb-4">
              <div className="mb-4 w-1/2">
                <div className="w-full flex flex-row ">
                  <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                    Email:
                  </label>

                  <input
                    type="email"
                    className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                      errors.has("email") ? "border-red-500" : ""
                    }`}
                    placeholder="Your Email"
                    name="email"
                    onChange={handleChange}
                  />

                  {errors.has("email") && (
                    <div className="text-red-500 mt-1 ml-2 text-sm">
                      {errors.first("email")}
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-4 w-1/2">
                <div className="w-full flex flex-row  ">
                  <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                    Address:
                  </label>
                  <input
                    type="text"
                    className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                    placeholder="Address"
                    name="address"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full justify-center  mx-4 px-8">
              <div className="mb-4 w-1/2 ">
                <div className="w-full flex flex-row ">
                  <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                    TimeZone:
                  </label>

                  <Select
                    className={`w-4/6  bg-main ${
                      errors.has("timezone") ? "border-red-500" : ""
                    }`}
                    options={timezoneOptions}
                    name="timezone"
                    onChange={handleTimeZoneChange}
                    value={timezoneOptions.find(
                      (option) => option.value === selectedTimeZone
                    )}
                    styles={customStyles}
                  />
                </div>
                {errors.has("timezone") && (
                  <div className="text-red-500 mt-1 ml-32 text-sm">
                    {errors.first("timezone")}
                  </div>
                )}
              </div>

              <div className="mb-4 w-1/2  ">
                <div className="w-full flex flex-row ">
                  <label className="w-1/9  text-neutral-700 text-base font-medium ">
                    Phone No:
                  </label>
                  <div className="w-4/6 flex flex-col ">
                    <PhoneInput
                      country={"np"}
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      className="w-4/6 rounded text-neutral-700  text-base font-normal lg:ml-17 lg:mr-12"
                    />

                    {!valid && (
                      <div className="text-red-500 mt-1 ml-32  text-sm">
                        Please enter a valid phone number.
                      </div>
                    )}
                    {/* <div className="flex flex-row mt-4">
                      <input
                        type="checkbox"
                        name="sms_capable"
                        value="1"
                        className=" text-lg font-semibold"
                      />

                      <span className="text-sm font-base ml-4 ">
                        SMS capable
                      </span>
                    </div> */}

                  </div>
                </div>
                    <div className="mb-8 lg:w-1/2 w-full">
                      <div className="w-full flex flex-row items-center mt-2">
                        <label className="w-1/9  text-neutral-700 text-base font-medium  ">
                          Invite Parent:
                        </label>

                        <input
                          type="checkbox"
                          className="1/6  border border-gray-400 rounded p-2  ml-8 mx-4 text-neutral-700"
                          name="invitation_status"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
              </div>
            </div>

            {showSecondaryParent && (
              <div className="mb-4 ">
                <h1 class="text-xl font-medium mt-2 max-w-[400px] mx-4 px-4 text-dark">
                  Secondary Parent info
                </h1>

                <div className="flex flex-row w-full justify-center items-center mx-4 px-8 my-8">
                  <div className="mb-4 w-1/2">
                    <div className="w-full flex flex-row  ">
                      <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                        First Name:
                      </label>

                      <input
                        type="text"
                        className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                          errors.has("first_name") ? "border-red-500" : ""
                        }`}
                        placeholder="Your First Name"
                        name="first_name"
                        onChange={handleChange}
                      />

                      {errors.has("first_name") && (
                        <div className="text-red-500 mt-1 ml-2 text-sm">
                          {errors.first("first_name")}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 w-1/2">
                    <div className="w-full flex flex-row   ">
                      <label className="w-1/4  text-black text-base font-medium  mb-2">
                        Last Name:
                      </label>
                      <input
                        type="text"
                        className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                        placeholder="Your Last Name"
                        name="last_name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-center items-center mx-4 px-8 mb-4">
                  <div className="mb-4 w-1/2">
                    <div className="w-full flex flex-row ">
                      <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                        Email:
                      </label>

                      <input
                        type="email"
                        className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                          errors.has("email") ? "border-red-500" : ""
                        }`}
                        placeholder="Your Email"
                        name="email"
                        onChange={handleChange}
                      />

                      {errors.has("email") && (
                        <div className="text-red-500 mt-1 ml-2 text-sm">
                          {errors.first("email")}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 w-1/2">
                    <div className="w-full flex flex-row  ">
                      <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                        Address:
                      </label>
                      <input
                        type="text"
                        className="w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal"
                        placeholder="Address"
                        name="address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row w-full justify-center  mx-4 px-8">
                  <div className="mb-4 w-1/2 ">
                    <div className="w-full flex flex-row ">
                      <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                        TimeZone:
                      </label>

                      <Select
                        className={`w-4/6  bg-main ${
                          errors.has("timezone") ? "border-red-500" : ""
                        }`}
                        options={timezoneOptions}
                        name="timezone"
                        onChange={handleTimeZoneChange}
                        value={timezoneOptions.find(
                          (option) => option.value === selectedTimeZone
                        )}
                        styles={customStyles}
                      />
                    </div>
                    {errors.has("timezone") && (
                      <div className="text-red-500 mt-1 ml-32 text-sm">
                        {errors.first("timezone")}
                      </div>
                    )}
                  </div>

                  <div className="mb-4 w-1/2  ">
                    <div className="w-full flex flex-row ">
                      <label className="w-1/4  text-neutral-700 text-base font-normal ">
                        Notes:
                      </label>
                      <div className="w-4/6 flex flex-col ">
                        <input
                          type="Notes"
                          className="  border border-gray-400 rounded p-2 text-gray-600 text-base font-light bg-main"
                          placeholder="Notes"
                          name="Notes"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-row  ml-40 mb-16">
              <button
                className="bg-lime-600 w-1/6 text-white p-2 rounded-md mt-4 "
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                {processing ? "Please wait..." : "Save"}
              </button>
              <button
                className="bg-amber-500 w-2/6 text-white p-2 rounded-md mt-4 ml-16"
                onClick={toggleSecondaryParent}
              >
                {showSecondaryParent
                  ? "Hide Secondary Parent"
                  : "Add Secondary Parent"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParent;
