import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import EditTutorModal from "./EditTutorModal";
import { Delete } from "@mui/icons-material";
import { DeleteTutorAction } from "../../../utils/ButtonActions";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingBlock from "../../../Components/LoadingBlock";

export default function ViewTutorDetail() {
  const [showEditTutorModal, setShowEditTutorModal] = useState(false); // State for edit confirmation modal
  const { tutorId } = useParams();
  const [TutorId, setTutorId] = useState(null);
  const { data, loading, error } = useFetch(`/get-tutor-detail/${tutorId}`);
  const ReactSwal = withReactContent(Swal);
  const navigate = useNavigate();

  //console.log(data);

  const handleEditClick = (tutorId) => {
    setTutorId(tutorId); // Set session ID
    setShowEditTutorModal(true);
  };

  //delete action

  async function DeleteTutor(tutorId) {
    const result = await ReactSwal.fire({
      title: <p className="text-center text-gray-600">Confirm delete</p>,
      html: `
            <p className="Align">Are you sure you want to delete this tutor? </p>
          `,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "delete-button-class",
        cancelButton: "cancel-button-class",
      },
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteTutorAction(tutorId)
      .then((res) => {
        if (res) {
          navigate("/tutor");
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) {
    return <LoadingBlock />;
  }
  return (
    <div className="md:w-1/2 w-full flex items-center">
      <div className="w-full md:ml-4 flex flex-col md:gap-[16px]">
        <div className="w-full flex items-center">
          <label className="text-black mr-2 w-1/3 text-nowrap">
            Full Name:
          </label>
          <p className="text-dark font-bold lg:ml-20 ml-6 w-2/3 text-nowrap">
            {data?.data?.first_name} {data?.data?.last_name}
          </p>
          <div className="flex items-center gap-1 lg:mr-24">
            <span
              onClick={() => handleEditClick(tutorId)}
              className="text-dark cursor-pointer"
            >
              <div className="rounded-full border border-gray-300 p-1 bg-white hover:bg-gray-200">
                <EditIcon />
              </div>
            </span>
            <span
              className="text-dark cursor-pointer"
              onClick={() => DeleteTutor(tutorId)}
            >
              <div className="rounded-full border border-gray-300 bg-white hover:bg-gray-200 p-1">
                <Delete />
              </div>
            </span>
          </div>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Address:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.address}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Email:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.email}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Phone:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.phone_no}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Assigned Subject:</label>
          <p className="text-dark font-bold w-2/3">
            {data?.data?.subject.title}
          </p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Tutor Type:</label>
          <p className="text-dark font-bold w-2/3 ">{data?.data?.role}</p>
        </div>

        {/* <div className="w-full flex">
          <label className="text-black mr-2">Privileges:</label>
          {data?.data?.privilege &&
          data?.data?.privilege?.filter(
            (privilege) => privilege.trim() !== "" && privilege !== '"'
          ).length > 0 ? (
            <div className="flex flex-wrap">
              {data?.data?.privilege
                .filter(
                  (privilege) => privilege.trim() !== "" && privilege !== '"'
                )
                .map((privilege, index) => (
                  <span
                    key={index}
                    className="mr-2 mb-2 px-2 py-1 rounded bg-gray-300"
                  >
                    {privilege
                      .replace(/_/g, " ")
                      .replace(/(^|\s)\S/g, (char) => char.toUpperCase())}
                  </span>
                ))}
            </div>
          ) : (
            <p className="text-dark font-bold">No privileges</p>
          )}
        </div> */}
      </div>

      {showEditTutorModal && (
        <EditTutorModal
          tutorId={TutorId}
          onClose={() => setShowEditTutorModal(false)}
        />
      )}
    </div>
  );
}
