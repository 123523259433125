import LoadingBlock from "../../Components/LoadingBlock";
import { useEffect, useState } from "react";
import api from "../../api";
import Pagination from "../../Components/Pagination";
import {
  getDashStartEndDates,
  getStartEndDates,
  getStartEndTime,
} from "../../context/CalendarDates";
import { getAuthUserInfo } from "../../utils/helpers";

function ClassList() {
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = getAuthUserInfo();

  //fetch students

  function fetchSchedule(page = 1) {
    setLoading(true);
    api
      .get("list-schedule", {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setSchedule(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSchedule();
  }, []);

  //console.log(schedule)

  return (
    <section className="mx-auto w-full  px-5 lg:pb-4 lg:ml-2">
      <div className="w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
        <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex space-y-4 flex-row items-center justify-between md:space-y-0 bg-white  py-2 lg:py-5 ">
                <div>
                  <h2 className="text-lg text-dark p-4 font-semibold">
                    Class List
                  </h2>
                </div>
                <div>
                  {/* <div className="flex col">
                    <div>
                      <button
                        type="button"
                        className="mr-12 rounded-md bg-white px-6 py-2 ml-5 text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                      >
                        <Link to="/calendar/add">Add Class + </Link>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center  text-sm  font-medium text-gray-600"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm  font-medium text-gray-600"
                    >
                      <span>Title</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm  font-medium text-gray-600"
                    >
                      <span>Tutor</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm  font-medium text-gray-600"
                    >
                      <span>Student</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm  font-medium text-gray-600"
                    >
                      <span>Subject</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-sm  font-medium text-center text-gray-600"
                    >
                      Start-End Date
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        <LoadingBlock loading={loading} />
                      </td>
                    </tr>
                  )}
                  {schedule?.data?.length > 0 ? (
                    schedule?.data?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-center text-base font-normal whitespace-nowrap px-4 py-4">
                          {index + 1}
                        </td>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.topic}
                        </td>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.tutor.first_name} {item.tutor.last_name}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.student.first_name} {item.student.last_name}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {item.subject.title}
                        </td>

                        {/* Display formatted date and time based on the selected time zone */}

                        <td className="text-gray-900 text-sm font-normal  text-center  px-4 py-4">
                          <div>
                            {getDashStartEndDates(
                              item.start_date.date_time,
                              user.timezone
                            )}
                            <br />
                            {getStartEndTime(
                              item.start_date.raw,
                              user.timezone
                            )}
                            <br />
                            {getStartEndTime(item.end_date.raw, user.timezone)}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className=" text-center py-4 ">
                        No Class available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {schedule?.meta?.last_page > 1 && (
        <Pagination
          meta={schedule.meta}
          onChange={(page) => fetchSchedule(page)}
        />
      )}
    </section>
  );
}

export default ClassList;
