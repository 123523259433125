import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const token = localStorage.getItem("token");

    if(!token){
        return <Navigate to={'/'} state={{from:location}} replace/>
    }

    return <Outlet/>
}

export default ProtectedRoute;