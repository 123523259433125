import React from 'react';

function Pagination({ meta, onChange }) {
  const totalPages = Math.ceil(meta.total / meta.per_page);
  const maxPagesToShow = 15;
  const currentPage = meta.current_page;

  let startPage = currentPage - Math.floor(maxPagesToShow / 2);
  startPage = Math.max(1, startPage);
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`pagination-button flex items-center justify-center rounded-md bg-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            currentPage === i ? 'bg-blue-500 text-white' : ''
          }`}
          onClick={() => onChange(i)}
          style={{ margin: '0.25rem' }}
        >
          <span className="w-6 h-6 flex items-center justify-center">{i}</span>
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="flex items-center justify-center pt-6">
      <button
        className="pagination-button flex items-center justify-center rounded-md bg-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={() => onChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        <span className="w-6 h-6 flex items-center justify-center">&lt;</span>
      </button>
      {renderPageButtons()}
      <button
        className="pagination-button flex items-center justify-center rounded-md bg-gray-300 text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={() => onChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
        <span className="w-6 h-6 flex items-center justify-center">&gt;</span>
      </button>
    </div>
  );
}

export default Pagination;
