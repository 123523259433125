export function UserStatusLayout({ user }) {
  const activeBgColor = "#EAF4EE";
  const inactiveBgColor = "#FCECEE";

  return (
    <td className="text-gray-900 text-sm font-medium">
      <span
        className="px-4 py-1 relative inset-0 rounded-full"
        style={{
          color:
            user.invitation_status === 1 && user.status === 1
              ? "green"
              : user.invitation_status === 0 && user.status === 1
              ? "green"
              : user.invitation_status === 0 && user.status === 0
              ? "red"
              : "inherit",
          backgroundColor:
            user.invitation_status === 1 && user.status === 1
              ? activeBgColor
              : user.invitation_status === 0 && user.status === 1
              ? activeBgColor
              : user.invitation_status === 0 && user.status === 0
              ? inactiveBgColor
              : "inherit",
        }}
      >
        {user.invitation_status === 1 && user.status === 1
          ? "Active"
          : user.invitation_status === 0 && user.status === 1
          ? "Active"
          : user.invitation_status === 0 && user.status === 0
          ? "Inactive"
          : ""}
      </span>
    </td>
  );
}

export function EmailLogStatus({ email }) {
  const successBgColor = "#EAF4EE";
  const failedBgColor = "#FCECEE";

  return (
    <td className="text-gray-900 text-center text-base font-normal whitespace-nowrap px-4 py-4">
      <span
        className="px-4 py-1 relative inset-0 rounded-full"
        style={{
          color:
            email.status === 1
              ? "green"
              : email.status === 0
              ? "red"
              : "inherit",

          backgroundColor:
            email.status === 1
              ? successBgColor
              : email.status === 0
              ? failedBgColor
              : "inherit",
        }}
      >
        {email.status === 1 ? "Success" : email.status === 0 ? "Failed" : ""}
      </span>
    </td>
  );
}
