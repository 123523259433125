import { useContext, useEffect, useState } from "react";
import api from "../../../api";
import { AuthContext } from "../../../context/AuthContext";
import LoadingBlock from "../../../Components/LoadingBlock";
import Pagination from "../../../Components/Pagination";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Avatar from "../../../Components/auth/Avatar";

function TutorStudent() {
  const [student, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();
  //fetch students

  function fetchStudents(page = 1) {
    setLoading(true);
    api
      .get(`get-tutor-student/${user.id}`, {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setStudents(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchStudents();
  }, []);
  return (
    <section className="mx-auto w-full px-5 lg:pb-4 lg:ml-2">
      <div className="lg:mt-3 flex flex-col overflow-x-auto">
        <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  ">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex  space-y-4 flex-row items-center justify-between md:space-y-0 py-2 lg:py-5  bg-white rounded-t-lg ">
                <div>
                  <h2 className="text-lg p-4  text-dark font-semibold">
                    Assigned Students
                  </h2>
                </div>
              </div>

              <table className="w-full table-auto divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-right text-sm font-medium text-gray-600"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 lg:pl-40 pl-20"
                    >
                      <span>Name</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 lg:pr-24 pr-16"
                    >
                      <span>Family</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                    >
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>

                {loading && <LoadingBlock>Loading...</LoadingBlock>}
                <tbody className="divide-y divide-gray-200 bg-white">
                  {student?.data?.length > 0 ? (
                    student?.data?.map((student, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-base text-right  font-normal whitespace-nowrap px-4 py-4">
                          {index + 1}
                        </td>
                        <td className="text-dark  text-base  font-medium  whitespace-nowrap px-2 py-4 flex gap-3 lg:pl-40 pl-20">
                          <Avatar user={student} />
                          <div>
                            <Link
                              to={`#`}
                              className="text-base  font-medium text-dark hover:underline"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {student.first_name} {student.last_name}
                            </Link>

                            
                          </div>
                        </td>
                        <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 lg:pr-24 pr-16 ">
                          <Link
                            to={"#"}
                            className="text-dark  text-base font-medium hover:underline "
                          >
                            {student.parent_details
                              ? `${student.parent_details.first_name} ${student.parent_details.last_name}`
                              : "---"}
                          </Link>
                          <br />
                          <p className="text-xs font-normal text-gray-500">
                            {student.parent_details
                              ? `${student.parent_details.email}`
                              : "---"}
                          </p>
                        </td>

                        <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 ">
                          <Link to={`/tutor/message/${student.id}`}>
                            <button className="btn-email text-slate-600 text-sm font-bold leading-tight">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="mr-2 text-lime-600"
                              />
                              Email
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4">
                        No Students available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {student?.meta?.last_page > 1 && (
          <Pagination
            meta={student.meta}
            onChange={(page) => fetchStudents(page)}
          />
        )}
      </div>
    </section>
  );
}

export default TutorStudent;
