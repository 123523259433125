import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
} from "@mui/material";
import {
  DeleteRecurringSession,
  DeleteSingleSession,
  DeleteaAllSession,
} from "./SessionButtonAction";

function DeleteConfirmModal({ isOpen, setDeleteConfirmModalOpen, sessionId }) {
  const [selectedOption, setSelectedOption] = useState(""); // State to keep track of the selected option

  const handleOptionChange = (option) => {
    setSelectedOption(option); // Update the selected option
  };

  const handleConfirm = () => {
    // Handle confirm action based on the selected option
    if (selectedOption === "sessionOnly") {
      // Handle session only action
      DeleteSingleSession(sessionId)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((error) => console.log(error));
    } else if (selectedOption === "followingSessions") {
      // Handle following sessions action
      DeleteRecurringSession(sessionId)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((error) => console.log(error));
    } else if (selectedOption === "allSessions") {
      // Handle all sessions action
      DeleteaAllSession(sessionId)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((error) => console.log(error));
    }

    setDeleteConfirmModalOpen(false); // Close the modal
  };

  return (
    <Dialog open={isOpen} onClose={() => setDeleteConfirmModalOpen(false)}>
      <DialogTitle>Confirm Delete Change</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to apply this change to all occurrences of the session?
        </DialogContentText>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "sessionOnly"}
              onChange={() => handleOptionChange("sessionOnly")}
            />
            <span>This session only</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "followingSessions"}
              onChange={() => handleOptionChange("followingSessions")}
            />
            <span>This and following sessions</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedOption === "allSessions"}
              onChange={() => handleOptionChange("allSessions")}
            />
            <span>All sessions</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button color="primary" onClick={handleConfirm}>
              OK
            </Button>
            <Button
              color="primary"
              onClick={() => setDeleteConfirmModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteConfirmModal;
