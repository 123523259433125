import { useEffect, useState } from "react";
import Errors from "../../utils/Errors";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import Select from "react-select";
import {
  convertApiTimeToInputTime,
  formatAPIDate,
  getEndDates,
  getStartEndDates,
  getStartEndTime,
} from "../../context/CalendarDates";
import {
  UpdateRecurringSession,
  UpdateSingleSession,
  extendSession,
} from "../../Components/Calendar/SessionButtonAction";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EditEvent() {
  const { sessionId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editType = queryParams.get("editType");

  const [singleprocessing, setSingleProcessing] = useState(null);
  const [recuprocessing, setRecuProcessing] = useState(null);
  //const [extdprocessing, setExtdProcessing] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errors, setErrors] = useState(new Errors());
  const navigate = useNavigate();

  //get subject list
  const { data: subjectData } = useFetch("/subject");

  // Transform subject data into options for React Select
  const subjectOptions =
    subjectData?.data?.map((sub) => ({
      value: sub.id,
      label: sub.title,
    })) || [];

  // get tutor list

  const { data: activeTutor } = useFetch("/active-tutor-list");

  // Transform tutor data into options for React Select
  const TutorOptions =
    activeTutor?.data?.map((tutor) => ({
      value: tutor.id,
      label: tutor.first_name + " " + tutor.last_name,
    })) || [];

  // get student list

  const { data: activeStudent } = useFetch("/active-student-list");

  // Transform tutor data into options for React Select
  const studentOptions =
    activeStudent?.data?.map((stu) => ({
      value: stu.id,
      label: stu.first_name + " " + stu.last_name,
    })) || [];

  //get class detail
  function fetchEventDetail() {
    setLoading(true);
    api
      .get(`edit-session/${sessionId}`)
      .then((res) => {
        let data = res.data.data;
        setForm({
          ...form,
          topic: data?.event?.topic,
          event_id: data.event_id,
          subject_id: data.subject_id,
          tutor_id: data.tutor_id,
          student_id: data.student_id,
          start_date: new Date(data.start_date.raw),
          duration: data.duration,
          repeat: data.repeat,
          end_date: new Date(data.end_date.raw),
          zoom_link: data.zoom_link,
          repeat_day: data.repeat_day,
          meeting_id: data.meeting_id,
          passcode: data.passcode,
          type: data.type,
        });
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchEventDetail();
  }, []);

  const [form, setForm] = useState({
    topic: "",
    subject_id: "",
    tutor_id: "",
    student_id: "",
    start_date: new Date(),
    duration: "",
    repeat: "",
    end_date: new Date(),
    zoom_link: "",
    meeting_id: "",
    passcode: "",
    repeat_day: [],
  });

  //console.log('form data1', form);
  function handleChange(e) {
    const { name, value } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }

  const formData = {
    ...form,
    start_date: form.start_date.toISOString(), //convert to UTC format
    end_date: form.end_date.toISOString(),
    // tutor_id: selectedTutor.value, // <-- Include tutor_id
    // student_id: selectedStudent.value, // <-- Include student_id
    // subject_id: selectedSubject.value, // <-- Include
  };

  async function handleSingleSessionSubmit(e) {
    e.preventDefault();
    setSingleProcessing(true);

    // console.log("edit data", form);

    // return;

    UpdateSingleSession(sessionId, formData)
      .then((res) => {
        if (res) {
          navigate("/calendar");
        }
      })
      .catch((error) => console.log(error));
  }

  async function handleRecurringSessionSubmit(e) {
    e.preventDefault();
    setRecuProcessing(true);

    // console.log("edit rec data", formData);

    // return;

    UpdateRecurringSession(sessionId, formData)
      .then((res) => {
        if (res) {
          navigate("/calendar");
        }
      })
      .catch((error) => console.log(error));
  }

  // async function handleExtendSessionSubmit(e) {
  //   e.preventDefault();
  //   setExtdProcessing(true);

  //   // console.log("edit rec data", form);
  //   // return;

  //   extendSession(sessionId, form)
  //     .then((res) => {
  //       if (res) {
  //         navigate("/calendar");
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }

  if (loading) {
    return <LoadingBlock></LoadingBlock>;
  }

  //console.log("event", form);
  return (
    <div className="mx-auto w-full px-5 lg:my-3 lg:pb-4  lg:flex-col">
      <div className="inline-block  lg:ml-2 lg:mr-10 min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  gap-[16px] rounded-md border  bg-white  overflow-x-auto">
        <div className="font-bold text-xl  pt-8 text-dark ">
          <Link
            to="/calendar"
            className="text-blue-500 text-start text-sm w-40 mt-2 ml-4 mr-80"
          >
            Back to Calendar
          </Link>
          Edit Event
        </div>
        <div className=" mb-4 mt-4 md:mt-7">
          <div className="flex md:flex-row flex-col w-full justify-center items-center mx-4 px-8 mb-4">
            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row  ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  Topic:
                </label>

                <input
                  type="text"
                  className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                    errors.has("topic") ? "border-red-500" : ""
                  }`}
                  placeholder="Class Topic"
                  name="topic"
                  value={form.topic}
                  onChange={handleChange}
                  readOnly
                />
                {errors.has("topic") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("topic")}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row  ">
                <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                  Tutor:
                </label>

                <Select
                  className={`w-4/6 bg-main  rounded  text-neutral-700  text-base font-normal  ${
                    errors.has("tutor") ? "border-red-500" : ""
                  }`}
                  name="tutor_id"
                  value={TutorOptions.find(
                    (option) => option.value === form.tutor_id
                  )}
                  onChange={(tutor) =>
                    setForm({
                      ...form,
                      tutor_id: tutor.value,
                    })
                  }
                  options={TutorOptions}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#EFF7F9",
                    }),
                  }}
                ></Select>
                {errors.has("tutor_id") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("tutor_id")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col w-full justify-center items-center mx-4 px-8 mb-4">
            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row ">
                <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                  Student:
                </label>

                <Select
                  className={`w-4/6 bg-main  rounded  text-neutral-700  text-base font-normal ${
                    errors.has("student") ? "border-red-500" : ""
                  }`}
                  name="student_id"
                  value={studentOptions.find(
                    (option) => option.value === form.student_id
                  )}
                  onChange={(stu) =>
                    setForm({
                      ...form,
                      student_id: stu.value,
                    })
                  }
                  options={studentOptions}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#EFF7F9",
                    }),
                  }}
                ></Select>

                {errors.has("student_id") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("student_id")}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4 lg:w-1/2 w-full">
              <div className="w-full flex flex-row  ">
                <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                  Subject:
                </label>

                <Select
                  className={`w-4/6 rounded  text-neutral-700  text-base font-normal   ${
                    errors.has("subject") ? "border-red-500" : ""
                  }`}
                  name="subject_id"
                  value={subjectOptions.find(
                    (option) => option.value === form.subject_id
                  )}
                  options={subjectOptions}
                  onChange={(sub) =>
                    setForm({
                      ...form,
                      subject_id: sub.value,
                    })
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#EFF7F9",
                    }),
                  }}
                >
                  {/* {subjectData?.data?.map((sub, i) => (
                    <option value={sub.id} key={i}>
                      {sub.title}
                    </option>
                  ))} */}
                </Select>

                {errors.has("subject_id") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("subject_id")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col w-full justify-center  mx-4 px-8">
            <div className="mb-4 lg:w-1/2 w-full ">
              <div className="w-full flex flex-row ">
                <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                  Start Date:
                </label>
                <div className="w-4/6 flex flex-col">
                  <ReactDatePicker
                    className={`w-full border border-gray-400 rounded p-2  text-neutral-700 bg-main text-base font-light${
                      errors.has("start_date") ? " border-red-500" : ""
                    }`}
                    selected={form.start_date}
                    onChange={(date) =>
                      setForm({
                        ...form,
                        start_date: date,
                        //update repeat day when start date changes

                        repeat_day: date
                          ? [
                              date
                                .toLocaleDateString("en", {
                                  weekday: "short",
                                })
                                .toUpperCase()
                                .substring(0, 2),
                            ]
                          : [],
                      })
                    }
                    showTimeSelect
                    dateFormat={"d/M/yyyy      h:mm aa"}
                    name="start_date"
                    id="startDate"
                  />

                  <em className="text-xs mt-1">
                    This is the local time. Date/Time might be different for
                    users.
                  </em>

                  {errors.has("start_date") && (
                    <div className="text-red-500 mt-1 ml-2 text-sm">
                      {errors.first("start_date")}
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="w-1/2 mb-8">
                <div className="w-full flex flex-row items-center">
                  <label className="w-1/6 ml-4 text-black text-base font-normal mb-2">
                    Time:
                  </label>
                  <div className="w-5/6 ml-4 flex flex-col">
                    <input
                      type="time"
                      className={`border border-gray-400 rounded p-2 mr-2 text-black text-base font-light${
                        errors.has("start_time") ? " border-red-500" : ""
                      }`}
                      name="start_time"
                      value={form.start_time}
                      onChange={handleChange}
                      style={{ WebkitAppearance: "textfield" }}
                      disabled={editType === "extend" ?? ""}
                    />
                    {errors.has("start_time") && (
                      <div className="text-red-500 mt-1 ml-2 text-sm">
                        {errors.first("start_time")}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
            </div>

            <div className="mb-4 lg:w-1/2 w-full ">
              <div className="w-full flex flex-row ">
                <label className="w-1/4 text-neutral-700 text-base font-medium  mb-2">
                  Duration:
                </label>
                <div className="w-4/6  flex flex-row">
                  <input
                    type="text"
                    className={`w-full border bg-main border-gray-400 rounded-l p-2  text-black text-base font-light ${
                      errors.has("duration") ? "border-red-500" : ""
                    }`}
                    placeholder="Enter duration in minutes"
                    name="duration"
                    value={form.duration}
                    onChange={handleChange}
                    disabled={editType === "extend" ?? ""}
                  />
                  <span className="inline-flex items-center px-3 text-sm text-white bg-dark border opacity-80 border-dark rounded-e-md">
                    Minutes
                  </span>
                  {errors.has("duration") && (
                    <div className="text-red-500 mt-1 ml-2 text-sm">
                      {errors.first("duration")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col w-full justify-center  mx-4 px-8 ">
            <div className="mb-4 lg:w-1/2 w-full flex flex-row ">
              {/* {isRepeating && ( */}
              <div className="lg:w-1/4 w-20 text-neutral-700 text-base font-medium  mb-2">
                This event repeats:
              </div>
              <div className="w-4/6  repeating-event-section bg-main  py-3 rounded-md drop-shadow border border-gray-400  ">
                <h2 className="w-full   text-teal-700 text-lg font-normal  mb-2 text-center">
                  Recurring Event
                </h2>
                <div className="flex items-center mb-2 ml-6">
                  <h3 className="w-1/4  text-black  text-base font-normal  mb-2">
                    Repeat:
                  </h3>
                  {form.repeat === "daily" && (
                    <>
                      <label className=" font-light text-base  ">Daily</label>
                      <input
                        type="radio"
                        name="repeat"
                        value="daily"
                        className=" border  border-gray-400 rounded ml-4  text-black  text-base font-light"
                        checked={form.repeat === "daily"}
                        onChang={handleChange}
                      />
                    </>
                  )}
                  {form.repeat === "weekly" && (
                    <>
                      <label className="ml-10 mr-4 font-light text-base ">
                        Weekly
                      </label>
                      <input
                        type="radio"
                        name="repeat"
                        value="weekly"
                        checked={form.repeat === "weekly"}
                        onChange={handleChange}
                        className="border border-gray-400 rounded "
                      />
                    </>
                  )}
                </div>

                {(editType === "recuring" || editType === "extend") && (
                  <div className="flex flex-col items-start text-left    mb-2 ml-6">
                    <div className="w-full flex ">
                      <h4 className="w-1/4   text-black text-base ">
                        Repeat Until:
                      </h4>

                      <ReactDatePicker
                        className={`border w-5/6 border-gray-400 bg-white rounded px-14 py-2 text-base font-light ml-4${
                          errors.has("end_date") ? " border-red-500" : ""
                        }`}
                        selected={form.end_date}
                        onChange={(date) =>
                          setForm({ ...form, end_date: date })
                        }
                        dateFormat={"d/M/yyyy"}
                        name="end_date"
                        id="endDate"
                      />
                    </div>
                    {/* <em
                      style={{
                        visibility: blink ? "visible" : "hidden",
                        color: "green",
                      }}
                    >
                      {editType === "recuring"
                        ? "set new end date for recurring session"
                        : "end date must be future date to extend session"}
                    </em> */}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4 lg:w-1/2 w-full ">
              <div className="w-full flex flex-row ">
                <div className="w-1/4  text-black text-base font-normal mb-2">
                  Description:
                </div>
                <textarea
                  className="w-4/6  h-40 border bg-main border-gray-400 rounded p-2 text-base font-normal "
                  placeholder="Class Zoom Link Description"
                  name="zoom_link"
                  onChange={handleChange}
                  value={form.zoom_link}
                  disabled={editType === "extend" ?? ""}
                >
                  {/* {form.zoom_link} */}
                </textarea>
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col w-full justify-center  mx-4 px-8 my-3.5  ">
            <div className="mb-4 lg:w-1/2 w-full flex flex-row ">
              <div className="lg:w-1/4 w-20 text-neutral-700 text-base font-medium  mb-2">
                Meeting ID:
              </div>

              <input
                type="text"
                className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                  errors.has("meeting_id") ? "border-red-500" : ""
                }`}
                placeholder="Meeting ID"
                name="meeting_id"
                value={form.meeting_id}
                onChange={handleChange}
                readOnly
              />
              {errors.has("meeting_id") && (
                <div className="text-red-500 mt-1 ml-2 text-sm">
                  {errors.first("meeting_id")}
                </div>
              )}
            </div>

            <div className="mb-4 lg:w-1/2 w-full ">
              <div className="w-full flex flex-row ">
                <div className="w-1/4  text-black text-base font-normal mb-2">
                  Passcode:
                </div>

                <input
                  type="text"
                  className={`w-4/6 bg-main border border-gray-400 rounded p-2 text-neutral-700  text-base font-normal${
                    errors.has("passcode") ? "border-red-500" : ""
                  }`}
                  placeholder="Passcode"
                  name="passcode"
                  value={form.passcode}
                  onChange={handleChange}
                  readOnly
                />
                {errors.has("passcode") && (
                  <div className="text-red-500 mt-1 ml-2 text-sm">
                    {errors.first("passcode")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-2">
            {editType === "single" && (
              <button
                className="bg-lime-600 text-white px-8 py-2 rounded-md mr-4"
                type="submit"
                onClick={(e) => handleSingleSessionSubmit(e)}
              >
                {singleprocessing ? "Please wait..." : "Update This Event"}
              </button>
            )}
            {editType === "recuring" && (
              <button
                className="bg-lime-600 text-white px-8 py-2 rounded-md mr-14 ml-12"
                type="submit"
                onClick={(e) => handleRecurringSessionSubmit(e)}
              >
                {recuprocessing
                  ? "Please wait..."
                  : "Update This & Future Events"}
              </button>
            )}

            {/* {editType === "extend" && (
                <button
                  className="bg-lime-600 text-white px-8 py-2 rounded-md mr-4"
                  type="submit"
                  onClick={(e) => handleExtendSessionSubmit(e)}
                >
                  {extdprocessing ? "Please wait..." : "Extend This Event"}
                </button>
              )} */}

            <button
              className="bg-lime-600 text-white px-8 py-2 rounded-md"
              type="submit"
              onClick={(e) => navigate("/calendar")}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvent;
