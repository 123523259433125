import { faLock, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Errors from "../../utils/Errors";
import PasswordResetModal from "../../Components/Modals/PasswordResetModal";
import api from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { TimeZone } from "../../utils/TimeZone";

function MyProfile() {
  const { getAuthUser, syncUserInformation } = useContext(AuthContext);
  const user = getAuthUser();

  const [showResetModal, setShowResetModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors] = useState(new Errors());
  const inputFile = useRef(null);
  const initialTimeZone = user.timezone || "";
  //const [selectedTimeZone, setSelectedTimeZone] = useState("");

  // Options for react-select
  const timezoneOptions = TimeZone().reduce(
    (acc, group) =>
      acc.concat(
        group.zones.map((zone) => ({
          value: zone.value,
          label: `${group.group} - ${zone.name}`,
        }))
      ),
    []
  );

  const [state, setState] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    profile_pic: user.avatar,
    timezone: initialTimeZone,
  });

  console.log('state', state);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#EFF7F9",
      borderColor: state.isFocused ? "#004CBD" : "#9ca3af",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  function handleChange(e) {
    let { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  // Handle change event for react-select
  const handleTimeZoneChange = (selectedOption) => {
    //console.log(selectedOption.value); //
    //setSelectedTimeZone(selectedOption.value);
    setState({ ...state, timezone: selectedOption.value });
  };

  useEffect(() => {
    setErrors(new Errors());
  }, [state]);

  function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);

    api
      .post("/update-profile", { ...state })
      .then((response) => {
        syncUserInformation();
        toast.success("Your profile has been updated");
       // setSelectedTimeZone(state.timezone);
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Something went wrong");
      })
      .finally(() => setProcessing(false));
  }

  const handleProfileChange = (e) => {
    const files = e.target.files;
    if (files) {
      const formData = new FormData();
      formData.append("file", files[0], files[0].name);
      api
        .post(`change-profile-picture`, formData)
        .then((res) => {
          toast.success("Profile Changed successfully !");
          syncUserInformation();
        })
        .catch((error) => {
          toast.error("something went wrong !");
        });
    }
  };

  const handleProfileUpload = () => {
    inputFile.current.click();
  };

  // Open modal for password reset
  const handleSetPasswordClick = (tutorId) => {
    setSelectedUserId(tutorId);
    setShowResetModal(true);
  };

  return (
    <div className=" max-w-screen-3xl md:p-4 xsm:pt-2 lg:mr-4 lg:ml-7  mx-5.5 flex-grow  bg-white  flex-col rounded lg">
      <div className=" font-medium text-3xl  text-dark text-center mb-4">
        My profile
      </div>
      <div className=" lg:mx-12 mx-2  h-auto mb-8  flex flex-col gap-[16px]   rounded-md  bg-white pb-8">
        <div className="col-sm-3 col">
          <div className=" font-medium text-lg my-6  mx-4 lg:px-8 px-2">
            <p>Update your personal settings here.</p>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col w-full justify-center items-center mx-4 lg:px-8 px-2">
          <div className="flex  lg:w-1/2 w-full mb-6">
            <label
              htmlFor="firstName"
              className="w-1/4  text-black  text-base font-normal mt-2 "
            >
              First Name
            </label>
            <input
              type="text"
              className="w-4/6 border border-gray-400 rounded p-2 text-black  text-base font-light ml-2 bg-main"
              id="firstName"
              name="first_name"
              aria-describedby="firstNameHelp"
              value={state.first_name}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="flex lg:w-1/2 w-full mb-6">
            <label
              htmlFor="lastName"
              className="w-1/4  text-black  text-base font-normal  mt-2 mr-2"
            >
              Last Name
            </label>
            <input
              type="text"
              className="w-4/6 border border-gray-400 rounded p-2 text-black  text-base font-light bg-main "
              id="lastName"
              name="last_name"
              aria-describedby="lastNameHelp"
              value={state.last_name}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="flex lg:flex-row flex-col w-full justify-center mx-4 lg:px-8 px-2">
          <div className="lg:w-1/2 w-full flex items-center mb-6">
            <label
              htmlFor="email"
              className="w-1/4  text-black  text-base font-normal  mt-2"
            >
              Email
            </label>
            <input
              type="email"
              className="w-4/6 border border-gray-400 rounded p-2 text-black  text-base font-light ml-2 bg-main"
              id="email"
              name="email"
              value={state.email}
              disabled
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="lg:w-1/2 w-full flex items-center mb-6">
            <div className="w-full flex flex-row items-center">
              <label className="w-1/4  text-black  text-base font-normal  mt-2 ">
                Profile Picture:
              </label>
              <div className="relative lg:w-20 lg:h-20 w-14 h-14 mx-6 rounded-full overflow-hidden">
                {user.profile_pic && (
                  <img
                    src={user.profile_pic}
                    alt={user.first_name}
                    name="file"
                    className="border-black bg-slate-300 object-cover w-full h-full rounded-full  "
                    onClick={handleProfileUpload}
                  />
                )}
              </div>
              <div className="flex items-center ml-2">
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={inputFile}
                  onChange={handleProfileChange}
                />
              </div>
              <button
                className="text-xs p-2 bg-lime-600  text-white rounded cursor-pointer"
                onClick={handleProfileUpload}
                type="button"
              >
                <h5>Upload</h5>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full justify-center  mx-4 px-8">
          <div className="mb-4 w-1/2 ">
            <div className="w-full flex flex-row ">
              <label className="w-1/4  text-neutral-700 text-base font-medium  mb-2">
                TimeZone:
              </label>

              <Select
                className={`w-4/6 lg:ml-2 bg-main ${
                  errors.has("timezone") ? "border-red-500" : ""
                }`}
                options={timezoneOptions}
                name="timezone"
                onChange={handleTimeZoneChange}
                value={timezoneOptions.find(
                  (option) => option.value === state.timezone
                )}
                styles={customStyles}
                
              />
            </div>
            {errors.has("timezone") && (
              <div className="text-red-500 mt-1 ml-32 text-sm">
                {errors.first("timezone")}
              </div>
            )}
          </div>

          <div className="mb-4 w-1/2 ">
            {/* <div className="w-full flex flex-row items-center mt-2">
              <label className="w-1/9  text-neutral-700 text-base font-medium  ">
                Invite Student:
              </label>

              <input
                type="checkbox"
                className="1/6  border border-gray-400 rounded p-2  ml-8 mx-4 text-neutral-700"
                name="invitation_status"
                onChange={handleChange}
              />
              <p>Invite Students for the class</p>
            </div> */}
          </div>
        </div>

        <div className="w-full mt-4 d-flex flex-row flex-sm-row  mx-4 lg:px-8 px-2 ">
          <button
            className="bg-amber-500 text-white px-6 p-2 rounded-md lg:mr-16 mr-4"
            disabled={processing}
            onClick={(e) => handleSubmit(e)}
          >
            {processing ? "Please wait..." : "Update"}
          </button>

          <button
            type="button"
            className="bg-lime-600 text-white px-6 p-2 md:ml-16 ml-4 rounded-md"
            onClick={() => handleSetPasswordClick(user.id)}
          >
            <FontAwesomeIcon icon={faLock} className="lock mr-2" />
            Change Password
          </button>
        </div>

        {/* Change Password Modal */}
        {showResetModal && (
          <PasswordResetModal
            userId={selectedUserId}
            onClose={() => setShowResetModal(false)}
            redirect={() => navigate("/settings/profile")}
          />
        )}
      </div>
    </div>
  );
}

export default MyProfile;
