import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import Pagination from "../../Components/Pagination";
import { UserStatusLayout } from "../../Components/layouts/UserStatusLayout";
import Avatar from "../../Components/auth/Avatar";

function TutorList() {
  const [tutor, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);

  //fetch tutors

  function fetchTutors(page = 1) {
    setLoading(true);
    api
      .get("list-tutor", {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setTutors(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTutors();
  }, []);

  return (
    <>
      <section className="mx-auto w-full px-5 lg:pb-4 lg:ml-2">
        <div className="w-auto lg:mt-3 flex flex-col overflow-x-auto md:overflow-visible">
          <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit  ">
              <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                <div className="flex items-center justify-start py-2 bg-white">
                  <h2 className="text-lg text-dark p-4 font-semibold mr-5">
                    Tutors
                  </h2>
                  <button
                    type="button"
                    className="rounded-md bg-white px-6 py-2 text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                  >
                    <Link to="/add-tutor">Add Tutor +</Link>
                  </button>
                </div>
                {/* <div class="max-w-full overflow-x-auto">
    <table class="w-full table-auto"></table> */}

                <table className="w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-right text-sm font-medium text-gray-600 pl-12"
                      >
                        <span>S.N</span>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 lg:pl-44 pl-20"
                      >
                        <span>Name</span>
                      </th>
                      {/* <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                      >
                        <span>Email</span>
                      </th> */}
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 pr-40"
                      >
                        <span>Subject</span>
                      </th>

                      {/* <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-medium text-gray-600"
                      >
                        <span>Type</span>
                      </th> */}

                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-sm font-medium text-gray-600 pr-20"
                      >
                        <span>Status</span>
                      </th>

                      {/* <th
                        scope="col"
                        className="px-4 py-3.5 text-center text-sm font-medium text-gray-600"
                      >
                        <span>Action</span>
                      </th> */}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {loading && (
                      <tr>
                        <td colSpan="4" className="text-center py-4">
                          <LoadingBlock loading={loading} />
                        </td>
                      </tr>
                    )}
                    {tutor?.data?.length > 0 ? (
                      tutor?.data?.map((tutor, index) => (
                        <tr key={index}>
                          <td className="text-gray-900 text-base text-right  font-normal whitespace-nowrap px-4 py-4">
                            {index + 1}
                          </td>

                          <td className="text-gray-900  text-base font-normal whitespace-nowrap px-4 py-4 flex gap-3 lg:pl-44 pl-20">
                            <Avatar user={tutor} />
                            <div>
                              <Link
                                to={`/tutor/${tutor.id}`}
                                className="text-dark  text-base font-medium hover:underline "
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {tutor.first_name} {tutor.last_name}
                              </Link>

                              <p className="text-xs font-normal text-gray-500">
                                <Link
                                  to={`/tutor/send-message/${tutor.id}`}
                                  style={{ color: "#2E33A8" }}
                                  className=""
                                >
                                  {tutor.email}
                                </Link>
                              </p>
                            </div>
                          </td>

                          <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                            {tutor.subject?.title}
                          </td>

                          <UserStatusLayout user={tutor} />
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center py-4">
                          No Tutor available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {tutor?.meta?.last_page > 1 && (
            <Pagination
              meta={tutor.meta}
              onChange={(page) => fetchTutors(page)}
            />
          )}
        </div>
      </section>
    </>
  );
}

export default TutorList;
