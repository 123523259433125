import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import moment from "moment/moment";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";
import { getStartEndTime } from "../../context/CalendarDates";
import useFetch from "../../hooks/useFetch";
import { AuthContext } from "../../context/AuthContext";

const AttendanceAccordian = () => {
  const { attendanceId } = useParams();
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(new Errors());

  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  let redirect;
  if (user.role === "superadmin") {
    redirect = "/class";
  } else if (user.role === "tutor" || user.role === "nontutor") {
    redirect = "/calendar";
  }

  //fetch event details
  const {
    data: eventDetail,
    loading,
    error,
  } = useFetch(`attendance-detail/${attendanceId}`);

  //console.log("data form api", eventDetail?.data);

  const attendance_status = {
    UNRECORDED: "Unrecorded",
    PRESENT: "Present",
    ABSENT: "Absent",
    TUTOR_CANCELED: "Tutor Canceled",
  };

  // store attendace
  const initialState = {
    event_id: eventDetail?.event_id,
    student_id: eventDetail?.student_id,
    attendance_status: "",
    details: "",
  };
  const [form, setForm] = useState(initialState);

  function handleAttendanceChange(e) {
    const { value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      attendance_status: value,
    }));
  }

  const handleChange = (value) => {
    setForm({ ...form, details: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    console.log("final form", form);
    //return;
    try {
      const response = await api({
        method: "post",
        url: `/update-attendance/${attendanceId}`,
        data: form,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Attendace Taken Successfully !");
        navigate(redirect);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form !");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong !");
      }
    }
  }

  return (
    <div className=" max-w-screen-3xl md:px-8 px-4  py-4  flex-grow bg-white rounded-lg lg:ml-7 lg:mr-4 mx-5.5">
      <div className="mb-6 mt-6">
        <div className="w-full items-center">
          <div className="">
            <div className="font-bold text-xl text-dark mb-6 ml-10">
              {eventDetail?.data?.student?.first_name}{" "}
              {eventDetail?.data?.student?.last_name}
            </div>
            <div className="w-full flex py-8 px-10 border border-gray-200 text-black rounded  bg-main ">
              <div className="mr-14">
                Date:{" "}
                {eventDetail
                  ? moment(eventDetail?.data?.event?.start_date).format(
                      "MMMM D, YYYY"
                    )
                  : "Loading..."}
              </div>
              <div>
                {" "}
                Time:{" "}
                {getStartEndTime(
                  eventDetail?.data?.start_date.raw,
                  user.timezone
                )}{" "}
                -{" "}
                {getStartEndTime(
                  eventDetail?.data?.end_date.raw,
                  user.timezone
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border rounded-md px-8 mt-12 relative  bg-main ">
        <div>
          <h1 className="mt-5 text-lg font-semibold">Attendance</h1>
          <p className="mb-10">
            Record attendance for this {eventDetail?.data?.event?.subject.title}{" "}
            class.
          </p>
        </div>

        <div className="mb-4 mt-4">
          <div className="w-full items-center">
            <div className="w-1/4 text-base font-medium">Attendance Status</div>

            {Object.entries(attendance_status).map(([name, label], index) => (
              <>
                <input
                  key={index}
                  type="radio"
                  className="border border-gray-400 rounded p-2 m-4"
                  name="attendance_status"
                  value={name}
                  onChange={handleAttendanceChange}
                />
                {label}
              </>
            ))}

            {errors.has("attendance_status") && (
              <div className="text-red-500 mt-1 ml-2 text-sm">
                {errors.first("attendance_status")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full border rounded-md px-8 mt-12 relative   bg-main">
        <div>
          <h1 className="mt-5 text-lg font-semibold ">Lesson Notes</h1>
          <p className="mb-10">
            Lesson Notes for the {eventDetail?.data?.event?.subject.title}{" "}
            class.
          </p>
        </div>

        <div>
          <label>Attendance Notes:</label>
          <div>
            <ReactQuill
              className="w-full h-[180px] mb-8 pb-10 bg-white"
              name="details"
              onChange={handleChange}
            />
            {errors.has("details") && (
              <div className="text-red-500 mt-1 ml-2 text-sm">
                {errors.first("details")}
              </div>
            )}
          </div>

          <div className="mt-2 py-2 w-full flex flex-row items-center">
            {/* Add your additional content here */}
          </div>
        </div>
      </div>

      <div className="mt-4 ml-1">
        <button
          className=" bg-amber-500 text-white px-4 py-2 rounded mr-2"
          type="submit"
          onClick={(e) => handleSubmit(e)}
        >
          {processing ? "Please wait..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AttendanceAccordian;
