import { useNavigate } from "react-router-dom";
import { loginAsUser } from "../../../utils/Admin";
import { useState } from "react";
import PasswordResetModal from "../../../Components/Modals/PasswordResetModal";
import EnableDisableButton from "../../../utils/EnableDisableButton";

export function Actiontab({ userId }) {
  const [showResetModal, setShowResetModal] = useState(false);
  const [selecteduserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  // Open modal for password reset
  const handleSetPasswordClick = (userId) => {
    setSelectedUserId(userId);
    setShowResetModal(true);
  };
  return (
    <>
      <button
        className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
        onClick={() => loginAsUser(userId)}
      >
        Login As Student
      </button>

      <button
        className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
        onClick={() => handleSetPasswordClick(userId)}
      >
        Reset Password
      </button>

      <EnableDisableButton userId={userId} redirect={'/student'}/>

      {showResetModal && (
        <PasswordResetModal
          userId={selecteduserId}
          onClose={() => setShowResetModal(false)}
          redirect={() => navigate("/student")}
        />
      )}
    </>
  );
}
