import { useEffect, useState } from "react";
import withReactContent from "sweetalert2-react-content";
import api from "../../api";
import Swal from "sweetalert2";
import LoadingBlock from "../../Components/LoadingBlock";
import Pagination from "../../Components/Pagination";
import { EmailLogStatus } from "../../Components/layouts/UserStatusLayout";
import { getStartEndDates } from "../../context/CalendarDates";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DeleteLogAction } from "../../utils/ButtonActions";

function EmailLog() {
  const [emailLogs, setEmailLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const ReactSwal = withReactContent(Swal);

  //fetch subjects

  function fetchEmailLog(page = 1) {
    setLoading(true);
    api
      .get("email-log", { params: { page } })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }
        setEmailLogs(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchEmailLog();
  }, []);

  //delete action

  async function DeleteLog(logId) {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
                  <div>Are you sure you want to delete this log?</div>
                </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteLogAction(logId)
      .then((res) => {
        if (res) {
          // Manually update the log list after successful deletion
          const updatedLogs = emailLogs.data.filter(
            (emailLogs) => emailLogs.id !== logId
          );

          setEmailLogs({ ...emailLogs, data: updatedLogs });
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <section className="mx-auto w-full  px-5 lg:pb-4 lg:ml-2">
      <div className="w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
              <div className="flex space-y-4 flex-row items-center justify-between md:space-y-0  py-2 lg:py-5  bg-white">
                <h2 className="text-lg text-dark p-4 font-semibold">
                  Calendar Email Logs
                </h2>
              </div>
              <table className="min-w-full divide-y  divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm font-medium text-gray-600"
                    >
                      <span>S.N</span>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm font-medium text-gray-600"
                    >
                      <h5>Subject</h5>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm font-medium text-gray-600"
                    >
                      <h5>Recipients</h5>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm font-medium text-gray-600"
                    >
                      <h5>Type</h5>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-left  text-sm font-medium text-gray-600"
                    >
                      <h5>Sent Date</h5>
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center  text-sm font-medium text-gray-600"
                    >
                      <span>Status</span>
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center  text-sm font-medium text-gray-600"
                    >
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading && (
                    <tr>
                      <td colSpan="4" className="text-center py-4">
                        <LoadingBlock loading={loading} />
                      </td>
                    </tr>
                  )}
                  {emailLogs?.data?.length > 0 ? (
                    emailLogs?.data?.map((em, index) => (
                      <tr key={index}>
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {index + 1}
                        </td>
                        {/* <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {em.subject.length > 7 ? (
                            <>
                              {em.subject.slice(0, 7)}
                              <br />
                              {em.subject.slice(7)}
                            </>
                          ) : (
                            em.subject
                          )}
                        </td> */}
                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {em.subject.length > 7 ? (
                            <>
                              <span style={{ lineHeight: "0.4" }}>
                                {em.subject.slice(0, 7)}
                              </span>
                              {em.subject.slice(7).indexOf(" ") !== -1 ? (
                                <>
                                  <br />
                                  <span style={{ lineHeight: "0.4" }}>
                                    {em.subject.slice(
                                      7,
                                      em.subject.slice(7).indexOf(" ") + 7
                                    )}
                                  </span>
                                  <br />
                                  <span>
                                    {em.subject.slice(
                                      em.subject.slice(7).indexOf(" ") + 7
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>{em.subject.slice(7)}</>
                              )}
                            </>
                          ) : (
                            em.subject
                          )}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          <strong>From:</strong> {em.from} <br />
                          <strong>To:</strong> {em.to}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {em.type}
                        </td>

                        <td className="text-gray-900 text-base font-normal whitespace-nowrap px-4 py-4">
                          {getStartEndDates(em.created_at.date_time)}
                        </td>

                        <EmailLogStatus email={em} />
                        <td className="text-black text-sm font-medium whitespace-nowrap px-4 py-4 text-center ">
                          <Link to={`/view-log/${em.id}`}>
                            <button className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center">
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                          </Link>
                          <button
                            className="text-white px-5 py-1 bg-red-700 rounded-lg border justify-center ml-4"
                            onClick={() => DeleteLog(em.id)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4">
                        No Logs available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {emailLogs?.meta?.last_page > 1 && (
        <Pagination
          meta={emailLogs.meta}
          onChange={(page) => fetchEmailLog(page)}
        />
      )}
    </section>
  );
}

export default EmailLog;
