import React from "react";
import ReactDOM from "react-dom/client";
import "./Global.css";

import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { DateProvider } from "./context/CalendarSelectedDate";
//import { SelectedDateProvider } from "./Components/Calendar/SelectedDateProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  // <SelectedDateProvider>
  <AuthProvider>
    <DateProvider>
      <App />
      <ThemeProvider theme={theme} />
      <ToastContainer autoClose="5000" />
    </DateProvider>
  </AuthProvider>
  // </SelectedDateProvider>
);
