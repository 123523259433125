import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { getStartEndDates } from "../../context/CalendarDates";
import LoadingBlock from "../../Components/LoadingBlock";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

function ViewEmailLog() {
  const { logId } = useParams();
  const { data, loading, error } = useFetch(`view-email-log/${logId}`);

  // Display error toast if there is an error
  if (error) {
    toast.error("Failed to fetch email log. Please try again later.");
  }

  if (loading) {
    return <LoadingBlock />;
  }
  return (
    <div className="mx-auto max-w-screen-3xl lg:px-4 lg:ml-3 px-4 mr-1 ml-1 flex-grow ">
      {/* Left Column - General and Attendance Details */}
      <div className="w-auto lg:mt-3 flex flex-col overflow-x-auto md:overflow-visible">
        {/* General Details Block */}
        <div className="bg-white rounded-md px-8 py-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Log Details</h2>
          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">From:</label>
            <p>{data?.data?.from}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">To:</label>
            <p>{data?.data?.to}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">Subject:</label>
            <p>{data?.data?.subject}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">Status:</label>
            <p>
              {data?.data?.status === 1
                ? "Success"
                : data?.data?.status === 0
                ? "Failed"
                : ""}
            </p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">Type:</label>
            <p>{data?.data?.type}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">Send On:</label>
            <p>{getStartEndDates(data?.data?.created_at.date_time)}</p>
          </div>

          <div className="flex items-center mb-2">
            <label className="w-1/6 text-md font-semibold">Description:</label>
            {/* <ReactQuill
              value={data?.data?.description}
              className=" h-[150px] mb-16"
            /> */}
            <textarea
              className="w-5/6 border border-gray-400 rounded p-10 text-gray-600 text-base font-light"
              value={data?.data?.description}
            />
          </div>
          {data?.data?.others && (
            <div className="flex items-center mb-2">
              <label className="w-1/4 text-md font-semibold">Errors:</label>
              <textarea
              className="w-5/6 border border-gray-400 rounded p-8 text-gray-600 text-base font-light"
              value={data?.data?.others}
            />
            </div>
          )}

          {/* Add other general details similarly */}
        </div>
      </div>
    </div>
  );
}

export default ViewEmailLog;
