import { Dialog, DialogActions, DialogTitle } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

function CalendarContexMenu({ isOpen, setIsModalOpen, clickedDate }) {
  //console.log('clicked date', clickedDate);
  // Format the clicked date
  const formattedDate =
    clickedDate &&
    clickedDate.toLocaleDateString("en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });

  // const clickedDateUTC = clickedDate?.toISOString();
  // console.log('clickedDateUTC date', clickedDateUTC);

  return (
    <Dialog open={isOpen} onClose={() => setIsModalOpen(false)}>
      <CloseIcon
        onClick={() => setIsModalOpen(false)}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 10,
          top: 10,
          color: "red",
          background: "#FCECEE",
        }}
      />
      <div className="mx-8 ">
        <DialogTitle>
          <p className="text-xl text-center mr-4">Add schedule</p>{" "}
          <p className="text-sm text-center mt-1 font-medium ">
            ({formattedDate})
          </p>
        </DialogTitle>

        <div className="bg-lime-600  text-white text-base rounded-md m-2  mx-10 mb-4">
          <DialogActions>
            <Link to={"/calendar/add"} color="primary">
              Add New Event
            </Link>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}

export default CalendarContexMenu;
