import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dashboard,
  Event,
  Face,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../../Assets/Images/Logo.png";

const Sidebar = ({ sidebarToggle, toggleSidebar }) => {
  const [selected, setSelected] = useState("");

  const pages = [
    {
      name: "Dashboard",
      icon: <Dashboard />,
      link: "/dashboard",
    },
    {
      name: "Calendar",
      icon: <Event />,
      link: "/calendar",
    },
    { name: "Student", icon: <Face />, link: "/tutor-student" },
  ];

  const handleItemClick = (pageName) => {
    setSelected((prev) => (prev === pageName ? "" : pageName));
  };

  return (
    <aside
      className={`fixed left-0 top-0 z-9999 flex w-72 h-[660px] flex-col overflow-y-hidden bg-dark border border-gray-300 duration-300 ease-linear dark:bg-boxdark lg:fixed lg:translate-x-0 lg:m-4 ${
        sidebarToggle ? "translate-x-0" : "-translate-x-full"
      } rounded-xl`}
      onClick={() => {}}
    >
      {/* SIDEBAR HEADER */}
      <div className="flex items-center justify-center gap-2 px-6  py-5.5 lg:py-6.5">
        <Link to="/dashboard">
          <img src={Logo} alt="Logo" className="w-40 h-20 fill-none mx-auto " />
        </Link>

        <button
          className=" ml-8 mb-10 text-white block lg:hidden"
          onClick={(e) => {
            e.stopPropagation();
            toggleSidebar(!sidebarToggle);
          }}
        >
          <CloseIcon />
        </button>
      </div>
      {/* SIDEBAR HEADER */}

      {/* MENU */}
      <nav className="mt-5 px-4 py-4 lg:mt-9 lg:px-6 ">
        <div>
          <h3 className="mb-4 ml-4 text-sm font-medium text-bodydark2">MENU</h3>
          <ul className="mb-6 flex flex-col gap-1.5">
            {pages.map((page) => (
              <li key={page.name}>
                <Link
                  to={page.link}
                  className={`group relative flex items-center gap-2.5 rounded-md px-4 py-2  font-medium text-bodydark1 hover:text-dark duration-300 ease-in-out hover:bg-[#B0E3EE] opacity-85 dark:hover:bg-meta-4 ${
                    selected === page.name ||
                    (page.link !== "#" && page.link === selected)
                      ? "bg-[#B0E3EE] opacity-85 dark:bg-meta-4 text-dark"
                      : ""
                  }`}
                  onClick={() => handleItemClick(page.name)}
                >
                  {page.icon}
                  {page.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      {/* MENU */}
    </aside>
  );
};

export default Sidebar;
