import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import LoadingBlock from "../../../Components/LoadingBlock";
import { Edit } from "@mui/icons-material";
import EditParentModal from "../../Parents/Tabs/EditParentModal";

const ViewParent = ({ parent }) => {
  const { data, loading, error } = useFetch(`/user-info/${parent.data.id}`);
  const [ParentId, setParentId] = useState(null);
  const [showEditParentModal, setShowEditParentModal] = useState(false); // State for edit confirmation modal

  const handleEditClick = (parentId) => {
    setParentId(parentId); // Set session ID
    setShowEditParentModal(true);
  };

  if (loading) {
    return <LoadingBlock />;
  }
  return (
    <div className="md:w-1/2 w-full flex items-center">
      <div className="w-full md:ml-4 flex flex-col md:gap-[16px]">
        <div className="w-full flex items-center">
          <label className="text-black mr-2 w-1/3 text-nowrap">
            Full Name:
          </label>
          <p className="text-dark font-bold lg:ml-20 ml-6 w-2/3 text-nowrap">
            {data?.data?.first_name} {data?.data?.last_name}
          </p>
          <div className="flex items-center gap-1 lg:mr-24">
            <span
              onClick={() => handleEditClick(data?.data?.id)}
              className="text-dark cursor-pointer"
            >
              <div className="rounded-full border border-gray-300 p-1 bg-white hover:bg-gray-200">
                <Edit />
              </div>
            </span>
            
          </div>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Address:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.address}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Email:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.email}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Phone:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.phone_no ? data?.data?.phone_no : '---'}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Address:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.address}</p>
        </div>

        <div className="w-full flex">
          <label className="text-black mr-2 w-1/3">Notes:</label>
          <p className="text-dark font-bold w-2/3">{data?.data?.notes ? data?.data?.notes : '---'}</p>
        </div>
      </div>

      {showEditParentModal && (
        <EditParentModal
          parentId={data?.data?.id}
          onClose={() => setShowEditParentModal(false)}
        />
      )}
    </div>
  );
};

export default ViewParent;
