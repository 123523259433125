import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import LoadingBlock from "../../Components/LoadingBlock";
import api from "../../api";
import { toast } from "react-toastify";
import Errors from "../../utils/Errors";

function EmailMessage() {
  const { sessionId } = useParams();
  const { data, loading, error } = useFetch(`attendance-detail/${sessionId}`);
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(new Errors());
  let user = getAuthUser();
  const [processing, setProcessing] = useState(false);
  //console.log("data here", data);
  const adminEmail = user.email;
  // Check if tutor, student, and parent properties exist
  const tutorEmail = data?.data?.tutor?.email || "";
  const tutorfName = data?.data?.tutor?.first_name || "";
  const tutorlName = data?.data?.tutor?.last_name || "";

  const parentEmail = data?.data?.parent?.email || "";
  const parentfName = data?.data?.parent?.first_name || "";
  const parentlName = data?.data?.parent?.last_name || "";

  const eventId = data?.data?.event?.id;

  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });

  // const handleSelectChange = (name, selectedOption) => {
  //   setFormData({ ...formData, [name]: selectedOption });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toOptions = [
    { value: tutorEmail, label: tutorfName + " " + tutorlName },
    { value: parentEmail, label: parentfName + " " + parentlName },
  ];

  async function handleSubmit(e) {
    e.preventDefault();
    setProcessing(true);
    const recipients = selectedRecipients.map((recipient) => ({
      email: recipient,
    }));

    // Create the payload
    const payload = {
      event_id: eventId,
      fromEmail: adminEmail,
      subject: formData.subject,
      message: formData.message,
      recipients: recipients,
    };

    //console.log("Form Data:", payload);

    try {
      const response = await api({
        method: "post",
        url: "send-calendar-message",
        data: payload,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Email Send Successfully !");
        navigate("/calendar");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
        }
      } else {
        console.error(error);
        toast.error("Something went wrong");
      }
    }
  }

  // Initialize formData.to with all recipients by default
  //const selectedRecipients = [tutorEmail, studentEmail, parentEmail];

  // Check if data exists before accessing properties
  if (!data || loading || error) {
    // Handle loading or error state
    return <LoadingBlock></LoadingBlock>;
  }

  return (
    <div className=" max-w-screen-3xl p-4   flex-grow bg-white rounded-lg lg:ml-7 lg:mr-4 mx-5.5">
      <h2 className="text-2xl font-semibold mb-4">Compose Message</h2>

      <div className="w-full mb-4 flex items-center">
        <label className="w-1/6  font-gray-800  text-base font-medium  mr-4">
          From:
        </label>
        <input
          type="text"
          name="emailfrom"
          value={adminEmail}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-400 rounded-md"
          readOnly
        />
      </div>

      <div className="w-full mb-4 flex items-center">
        <label className="w-1/6  font-gray-800  text-base font-medium  mr-4">
          To:
        </label>
        <Select
          className="w-full  border rounded-md"
          name="recipients"
          value={toOptions.filter((option) =>
            selectedRecipients.includes(option.value)
          )}
          onChange={(selectedOptions) =>
            setSelectedRecipients(selectedOptions.map((option) => option.value))
          }
          options={toOptions}
          isMulti={true}
        />
      </div>

      <div className="w-full mb-4 flex items-center ">
        <label className="w-1/6  font-gray-800  text-base font-medium  mr-4">
          Subject:
        </label>
        <div className="w-full flex flex-col">
          <input
            type="text"
            name="subject"
            //value={formData.subject}
            onChange={handleChange}
            className={`w-full px-3 py-2 border border-gray-400 rounded-md  ${
              errors.has("subject") ? "border-red-500" : ""
            }`}
          />
          {errors.has("subject") && (
            <div className="text-red-500 mt-1 ml-2 text-sm">
              {errors.first("subject")}
            </div>
          )}
        </div>
      </div>

      <div className="w-full  mb-4 flex items-center">
        <label className="w-1/6  font-gray-800  text-base font-medium  mr-4">
          Message:
        </label>
        <div className="w-full flex flex-col ">
          <ReactQuill
            name="message"
            onChange={(value) => setFormData({ ...formData, message: value })}
            className=" h-[150px] mb-16 "
          />
          {errors.has("message") && (
            <div className="text-red-500 mt-1 ml-2 text-sm">
              {errors.first("message")}
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 ml-40 flex justify-start">
        <button
          onClick={(e) => handleSubmit(e)}
          className="ml-2  bg-lime-600 text-white px-8 py-2 rounded-md"
        >
          {processing ? "Please wait..." : "Send"}
        </button>
      </div>
    </div>
  );
}

export default EmailMessage;
