import Login from "../Components/auth/Login";
import Signup from "../Components/auth/Signup";
import ForgotPassword from "../pages/Auth/Forgotpassword";
import PasswordReset from "../pages/Auth/PasswordReset";

const AuthRoutes = [
  {
    path: "/",
    element: <Login />,
  },

  // {
  //   path: "user-activation/:token",
  //   element: <UserActivation />,
  // },

  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },

  {
    path: "/reset-password/:token",
    element: <PasswordReset />,
  },
];

export default AuthRoutes;
