import { useEffect, useState } from "react";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { DeleteParentAction } from "../../utils/ButtonActions";
import { UserStatusLayout } from "../../Components/layouts/UserStatusLayout";
import PasswordResetModal from "../../Components/Modals/PasswordResetModal";
import Avatar from "../../Components/auth/Avatar";

function Parents() {
  const [parent, setParents] = useState([]);
  const [loading, setLoading] = useState(false);
  const ReactSwal = withReactContent(Swal);
  const [showResetModal, setShowResetModal] = useState(false);
  const [selecteduserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  //fetch parents

  function fetchParents(page = 1) {
    setLoading(true);
    api
      .get("list-parent", {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setParents(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchParents();
  }, []);

  //delete action

  async function DeleteParent(parentId) {
    const result = await ReactSwal.fire({
      title: <p className="text-start">Confirm delete</p>,
      html: `<div class="text-start">
                  <div>Are you sure you want to delete this parent?</div>
                </div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteParentAction(parentId)
      .then((res) => {
        if (res) {
          // Manually update the parent list after successful deletion
          const updatedParents = parent.data.filter(
            (parent) => parent.id !== parentId
          );

          setParents({ ...parent, data: updatedParents });
        }
      })
      .catch((error) => console.log(error));
  }

  // Open modal for password reset
  const handleSetPasswordClick = (userId) => {
    setSelectedUserId(userId);
    setShowResetModal(true);
  };
  return (
    <>
      <section className="mx-auto w-full  px-5 lg:pb-4 lg:ml-2">
        <div className="w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
          <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit ">
              <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                <div className="flex  space-y-4 flex-row items-center justify-between md:space-y-0  py-2 lg:py-5  bg-white rounded-t-lg">
                  <div>
                    <h2 className="text-lg text-dark  font-semibold p-4">
                      Parents
                    </h2>
                  </div>
                  <div></div>
                </div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-right text-sm  font-medium text-gray-600"
                      >
                        <span>S.N</span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm  font-medium text-gray-600 lg:pl-40 pl-20 "
                      >
                        <span>Name</span>
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm  font-medium text-gray-600"
                      >
                        <span>Student</span>
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm  font-medium text-gray-600"
                      >
                        <span>Status</span>
                      </th>
                      {/* <th
                        scope="col"
                        className="px-4 py-3.5 text-center text-sm  font-medium text-gray-600"
                      >
                        <span>Action</span>
                      </th> */}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {loading && (
                      <tr>
                        <td colSpan="4" className="text-center py-4">
                          <LoadingBlock loading={loading} />
                        </td>
                      </tr>
                    )}
                    {parent?.data?.length > 0 ? (
                      parent?.data?.map((parent, index) => (
                        <tr key={index}>
                          <td className="text-gray-900 text-base text-right font-normal whitespace-nowrap px-4 py-4">
                            {index + 1}
                          </td>

                          <td className="text-gray-900 text-base font-normal whitespace-nowrap px-3 py-4 flex gap-3 lg:pl-40 pl-20 ">
                            <Avatar user={parent} />
                            <div>
                              <Link
                                to={`/parent/${parent.id}`}
                                className="text-base font-medium text-dark hover:underline"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {parent.first_name} {parent.last_name}
                              </Link>

                              <p className="text-xs font-normal text-gray-500 ">
                                <Link
                                  to={`/parent/send-message/${parent.id}`}
                                  style={{ color: "#2E33A8" }}
                                >
                                  {parent.email}
                                </Link>
                              </p>
                            </div>
                          </td>

                          {/* <td className="whitespace-nowrap px-4 py-4">{student.parent_details?.first_name} {student.parent_details?.last_name}</td> */}
                          <td className="text-gray-900 text-base font-normal whitespace-nowrap px-3 py-4 pr-24">
                            {parent.student_details
                              ? `${parent.student_details.first_name} ${parent.student_details.last_name}`
                              : "---"}
                            <br />
                            {/* <span className="text-xs font-normal text-gray-500">
                              {parent.student_details
                                ? `${parent.student_details.email}`
                                : "---"}
                            </span> */}
                          </td>

                          <UserStatusLayout user={parent} />

                          {/* <td className="text-black text-sm font-medium whitespace-nowrap px-4 py-4 text-center ">
                            <ParentActionRow
                              user={parent}
                              DeleteParent={DeleteParent}
                              handleSetPasswordClick={handleSetPasswordClick}
                              loginAsUser={loginAsUser}
                            />
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center py-4">
                          No Parent available
                        </td>
                      </tr>
                    )}
                  </tbody>

                  {showResetModal && (
                    <PasswordResetModal
                      userId={selecteduserId}
                      onClose={() => setShowResetModal(false)}
                      redirect={() => navigate("/parent")}
                    />
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        {parent?.meta?.last_page > 1 && (
          <Pagination
            meta={parent.meta}
            onChange={(page) => fetchParents(page)}
          />
        )}
        {/* <div className="flex items-center justify-center pt-6">
          <a
            href="#"
            className="mx-1 cursor-not-allowed text-base font-semibold text-gray-900"
          >
            <span className="hidden lg-block">&larr; Previous</span>
            <span className="block lg:hidden">&larr;</span>
          </a>
          <a
            href="#"
            className="mx-1 flex items-center rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
          >
            1
          </a>
          <a
            href="#"
            className="mx-1 flex items-center rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
          >
            2
          </a>
          <a
            href="#"
            className="mx-1 flex items-center rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
          >
            3
          </a>
          <a
            href="#"
            className="mx-1 flex items-center rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
          >
            4
          </a>
          <a href="#" className="mx-2 text-base font-semibold text-gray-900">
            <span className="hidden lg-block">Next &rarr;</span>
            <span className="block lg:hidden">&rarr;</span>
          </a>
        </div> */}
      </section>
    </>
  );
}

export default Parents;
