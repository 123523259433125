import React from "react";
import useFetch from "../../hooks/useFetch";
import LoadingBlock from "../../Components/LoadingBlock";
import { AuthContext } from "../../context/AuthContext";
import AdminDashboard from "../../Components/Dashboard/AdminDashboard";
import TutorDashboard from "../../Components/Dashboard/TutorDashboard";
import StudentDashboard from "../../Components/Dashboard/StudentDashboard";
import { useContext } from "react";

const Dashboard = () => { 
  const { isLoggedIn, getAuthUser } = useContext(AuthContext);
  let user = getAuthUser();

  console.log(user.timezone);
  let role, url, ListUrl;
  switch (user.role) {
    case "superadmin":
      //role = "Admin";
      url = "/admin-dashboard";
      ListUrl = "/class-list";
      break;
    case "tutor":
    case "nontutor":
      //role = "Tutor";
      url = "/tutor-dashboard";
      ListUrl = "/tutor-class-list";
      break;
    case "student":
      //role = "Student";
      url = "/student-dashboard";
      ListUrl = "/student-class-list";
      break;
    case "parent":
      //role = "Parent";
      url = "/parent-dashboard";
      ListUrl = "/parent-class-list";
      break;
    default:
      break;
  }

  const { data, loading, error } = useFetch(url);
  const { data: classDetail, processing } = useFetch(ListUrl);

  //console.log("classes", classDetail?.upcoming_session);

  // Filter classes for the last session and upcoming sessions
  const upcomingSessionClass = classDetail?.upcoming_session; // Get the upcoming class session
  const previousSessionClass = classDetail?.previous_session; // Get the previous class session

  // const [upcomingSessionClass, setUpcomingSessionClass] = useState([]);
  // const [previousSessionClass, setPreviousSessionClass] = useState([]);

  if (
    loading ||
    !data ||
    processing ||
    classDetail === undefined ||
    classDetail?.upcoming_session === undefined
  ) {
    return <LoadingBlock>Loading...</LoadingBlock>;
  }

  const totalStudents = data?.studentCount;
  const totalSubjects = data?.subjectCount;
  const totalTeachers = data?.teachersCount;
  const totalClasses = data?.classCount;
  const Timezone = user?.timezone;

  return (() => {
    switch (user.role) {
      case "superadmin":
        return (
          <AdminDashboard
            totalClasses={totalClasses}
            totalTeachers={totalTeachers}
            totalSubjects={totalSubjects}
            totalStudents={totalStudents}
            upcomingSessionClass={upcomingSessionClass}
            previousSessionClass={previousSessionClass}
            userTimezone = {Timezone}
          />
        );

      case "tutor":
      case "nontutor":
        return (
          <TutorDashboard
            totalClasses={totalClasses}
            totalStudents={totalStudents}
            upcomingSessionClass={upcomingSessionClass}
            previousSessionClass={previousSessionClass}
            userTimezone = {Timezone}
          />
        );

      case "student":
      case "parent":
        return (
          <StudentDashboard
            totalSubjects={totalSubjects}
            totalTeachers={totalTeachers}
            totalClasses={totalClasses}
            upcomingSessionClass={upcomingSessionClass}
            previousSessionClass={previousSessionClass}
            userTimezone = {Timezone}
          />
        );

      default:
        return null;
    }
  })();
};

export default Dashboard;
