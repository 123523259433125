import { useState } from "react";
import Errors from "../../../utils/Errors";
import api from "../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import LoadingBlock from "../../../Components/LoadingBlock";
import { Delete } from "@mui/icons-material";
import { DeleteStudentAction } from "../../../utils/ButtonActions";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const PersonalTabOld = ({ student, loading }) => {
  //console.log(student?.data?.id);
  //return;
  const [processing, setProcessing] = useState(null);
  const [errors, setErrors] = useState(new Errors());
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const ReactSwal = withReactContent(Swal);
  let studentId = student?.data?.id;

  const initialState = {
    first_name: "Hailey",
    last_name: "Orona",
    email: "Hailey@gmail.com",
    phone_no: "973793792",
    //profile_pic: "",
    role: "student",
  };

  if (student) {
    initialState.first_name = student?.data?.first_name;
    initialState.last_name = student?.data?.last_name;
    initialState.email = student?.data?.email;
    initialState.phone_no = student?.data?.phone_no;
  }

  const [form, setForm] = useState(initialState);

  // console.log('form', student?.data?.first_name);
  // console.log('form', form);

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  function handleUpdate(e) {
    setProcessing(true);
    e.preventDefault();

    api
      .post(`/update-student/${student.data.id}`, form)
      .then((res) => {
        toast.success("Student Updated !");
        navigate("/student");
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.errors) {
          setErrors(new Errors(error.response.data.errors));
          toast.error("Failed to process form");
          return;
        }
        toast.error("Server error. Please try again");
      })
      .finally(() => setProcessing(false));
  }

  function handleEdit() {
    setEditMode(true);
  }

  //delete action

  async function DeleteStudent(studentId) {
    const result = await ReactSwal.fire({
      title: <p className="text-center text-gray-600">Confirm delete</p>,
      html: `
            <p className="Align">Are you sure you want to delete this student? </p>
          `,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "delete-button-class",
        cancelButton: "cancel-button-class",
      },
    });

    if (!result.isConfirmed) {
      return;
    }

    DeleteStudentAction(studentId)
      .then((res) => {
        if (res) {
          navigate("/student");
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="w-full flex items-center mb-8">
      <div className="w-full flex flex-col relative gap-[16px]">
        <div className="w-full flex items-center">
          {loading && <LoadingBlock />}
          <label className="text-black">Name:</label>
          <p className="text-dark font-bold  px-2">
            {student?.data?.first_name} {student?.data?.last_name}
          </p>
          <div className="flex items-center gap-1 ml-5">
            {!editMode && (
              <span onClick={handleEdit} className="text-dark cursor-pointer">
                <div className="rounded-full border border-gray-300 p-1">
                  {updateSuccess ? "Changes Saved!" : <EditIcon />}
                </div>
              </span>
            )}
            {!editMode && (
              <span
                className="text-dark cursor-pointer"
                onClick={() => DeleteStudent(studentId)}
              >
                <div className="rounded-full border border-gray-300 p-1">
                  <Delete />
                </div>
              </span>
            )}

            {editMode && (
              <button
                onClick={handleUpdate}
                className="text-dark cursor-pointer"
              >
                {processing ? "Please wait..." : "Save Changes"}
              </button>
            )}
          </div>
        </div>
        <div className="w-full flex lg:flex-row flex-col justify-between ">
          <div className="mb-4  w-full lg:w-1/2 ">
            <div className=" flex flex-row items-center">
              <label className="w-1/4  text-neutral-700 text-base font-normal  mb-2">
                First Name:
              </label>
              <input
                type="text"
                name="first_name"
                value={form.first_name}
                className={`w-4/6 ${
                  editMode ? "border" : ""
                } border-gray-400 rounded p-2 text-gray-600 text-base font-light bg-main`}
                placeholder="Your first Name"
                onChange={handleChange}
                readOnly={!editMode}
              />
              {errors.has("first_name") && (
                <div className="text-red-500 mt-1 ml-2 text-sm">
                  {errors.first("first_name")}
                </div>
              )}
            </div>
          </div>
          <div className="mb-4 w-full lg:w-1/2 ">
            <div className="w-full flex flex-row items-center">
              <label className="w-1/4  text-neutral-700 text-base font-normal  mb-2">
                Last Name:
              </label>
              <input
                type="text"
                name="last_name"
                value={form.last_name}
                className={`w-4/6 ${
                  editMode ? "border" : ""
                } border-gray-400 rounded p-2 text-gray-600 text-base font-light bg-main`}
                placeholder="Your Last Name"
                onChange={handleChange}
                readOnly={!editMode}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex lg:flex-row flex-col justify-between ">
          <div className="mb-4 w-full lg:w-1/2">
            <div className="w-full flex flex-row items-center">
              <label className="w-1/4 text-neutral-700 text-base font-normal  mb-2">
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={form.email}
                className={`w-4/6 ${
                  editMode ? "border" : ""
                } border-gray-400 rounded p-2 text-gray-600 text-base font-light bg-main`}
                placeholder="Your Email"
                //onChange={handleChange}
                readOnly
              />
            </div>
            <div className="w-4/6 ml-30 text-sm text-red-500">
              Email can't be updated!
            </div>
          </div>
          <div className="mb-4 w-full lg:w-1/2">
            <div className="w-full flex flex-row items-center">
              <label className="w-1/4  text-neutral-700 text-base font-normal  mb-2">
                Phone:
              </label>
              <input
                type="tel"
                name="phone_no"
                value={form.phone_no}
                className={`w-4/6 ${
                  editMode ? "border" : ""
                } border-gray-400 rounded p-2 text-gray-600 text-base font-light bg-main`}
                placeholder="Your Phone Number"
                onChange={handleChange}
                readOnly={!editMode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalTabOld;
