//import moment from "moment/moment";
import moment from 'moment-timezone';


export function formatAPIDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")} ${date
    .toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
    .toUpperCase()}`;
  return formattedDate;
};


export function getEndDates(date){
  return moment(date).format("YYYY/MM/DD");

  // // Parse the UTC date time using moment
  // const parsedDateTime = moment.utc(date);

  // // Convert to local time zone
  // const localDateTime = parsedDateTime.local();

  // //console.log('Local Date Time:', moment(date).format("YYYY-MM-DD HH:mm:A")); 

  // // Format the local date time as per your requirement
  // return localDateTime.format("YYYY-MM-DD HH:mm:A"); // Adjust the format as needed
  
}

export function getStartEndDates(date){
    return moment(date).format("YYYY/MM/DD HH:mm:A");

    // // Parse the UTC date time using moment
    // const parsedDateTime = moment.utc(date);

    // // Convert to local time zone
    // const localDateTime = parsedDateTime.local();

    // //console.log('Local Date Time:', moment(date).format("YYYY-MM-DD HH:mm:A")); 

    // // Format the local date time as per your requirement
    // return localDateTime.format("YYYY-MM-DD HH:mm:A"); // Adjust the format as needed
    
}

// export function getDashStartEndDates(date){
//   return moment(date).format("MMM D");
// }

export function getDashStartEndDates(dateString, userTimezone) {
  const formattedDate = moment.utc(dateString).tz(userTimezone).format("MMM D");
  return formattedDate;
}

// export function getStartEndTime(dateTime){
//     // Parse the UTC date time using moment
//     const parsedDateTime = moment.utc(dateTime);

//     // Convert to local time zone
//     const localDateTime = parsedDateTime.local();

//     // Format the local date time as per your requirement
//     return localDateTime.format("h:mm:A"); // Adjust the format as needed
  
// }

export function getStartEndTime(dateTimeString, userTimezone) {
  
  const formattedTime = moment.utc(dateTimeString).tz(userTimezone).format("h:mm:A");
  
  return formattedTime;
}

export function convertApiTimeToInputTime(apiTime) {
    return moment(apiTime, "HH:mm:ss").format("HH:mm");
  }

export function convertInputTimeToApiTime(inputTime) {
  return moment(inputTime, "HH:mm").format("HH:mm:ss");
}

