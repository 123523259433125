import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { loginAsUser } from "../../../utils/Admin";
import PasswordResetModal from "../../../Components/Modals/PasswordResetModal";
import useFetch from "../../../hooks/useFetch";
import LoadingBlock from "../../../Components/LoadingBlock";
import EnableDisableButton from "../../../utils/EnableDisableButton";

export default function TutorAction() {
  const { tutorId } = useParams();
  const [showResetModal, setShowResetModal] = useState(false);
  const [selecteduserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  // Open modal for password reset
  const handleSetPasswordClick = (tutorId) => {
    setSelectedUserId(tutorId);
    setShowResetModal(true);
  };

  return (
    <>
      <button
        className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
        onClick={() => loginAsUser(tutorId)}
      >
        Login As Tutor
      </button>

      <button
        className="text-white px-5 py-1 bg-lime-600 rounded-lg border justify-center ml-4"
        onClick={() => handleSetPasswordClick(tutorId)}
      >
        Reset Password
      </button>
      <EnableDisableButton userId={tutorId} redirect={"/tutor"} />

      {showResetModal && (
        <PasswordResetModal
          userId={selecteduserId}
          onClose={() => setShowResetModal(false)}
          redirect={() => navigate("/tutor")}
        />
      )}
    </>
  );
}
