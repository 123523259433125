import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { extendSession } from "./SessionButtonAction";
import { useNavigate } from "react-router";

function ExtendSessionModal({
  isExtendModalOpen,
  setIsExtendModalOpen,
  eventId,
}) {
  const [endDate, setEndDate] = useState(new Date());
  const [extdprocessing, setExtdProcessing] = useState(null);
  const navigate = useNavigate();

  async function handleExtendSessionSubmit(e) {
    e.preventDefault();
    setExtdProcessing(true);

    // Format the date before sending it to the backend
    const formattedEndDate = endDate.toISOString(); // Convert to ISO string format
    // Create an object with 'end_date' key and its value as the formatted end date
    const requestData = {
      end_date: formattedEndDate,
    };

    console.log('end date', requestData);

    extendSession(eventId, requestData)
      .then((res) => {
        if (res) {
          navigate("/calendar");
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <Dialog
      open={isExtendModalOpen}
      onClose={() => setIsExtendModalOpen(false)}
    >
      <DialogTitle>Select date to extend this session</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{eventId}</DialogContentText> */}
        <ReactDatePicker
          className="border w-3/4 border-gray-400 bg-white rounded px-14 py-2 font-light mr-16"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          //showTimeSelect
          dateFormat={"d/M/yyyy"}
          name="end_date"
          id="endDate"
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={(e) => handleExtendSessionSubmit(e)}>
          {extdprocessing ? "Please wait..." : "Ok"}
        </Button>
        <Button onClick={() => setIsExtendModalOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExtendSessionModal;
