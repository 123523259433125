import React from "react";

import Header from "./Header";
import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    <div className="flex min-h-screen bg-main">
      <main className="flex-grow ml-0 lg:ml-72 bg-main overflow-hidden">
        <Header />
        <div className="flex overflow-hidden">
          {/* Scrollable Content Area */}
          <div className="lg:px-3  bg-main flex-grow">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}

export default AuthLayout;
