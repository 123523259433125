import { createContext, useState } from "react";

const CalendarSelectedDate = createContext();

const DateProvider = ({ children }) => {
  const [isClickedDate, setIsClickeddDate] = useState(null);

  return (
    <CalendarSelectedDate.Provider value={{ isClickedDate, setIsClickeddDate }}>
      {children}
    </CalendarSelectedDate.Provider>
  );
};

export { CalendarSelectedDate, DateProvider };

// export const useDate = () => {
//     const context = useContext(CalendarSelectedDate);
//     if (!context) {
//       throw new Error("useDate must be used within a DateProvider");
//     }
//     return context;
//   };
