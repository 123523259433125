import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import LoadingBlock from "../../Components/LoadingBlock";
import Pagination from "../../Components/Pagination";
import "../../css/Alert.css";
import { UserStatusLayout } from "../../Components/layouts/UserStatusLayout";
import Avatar from "../../Components/auth/Avatar";

export default function Student() {
  const [student, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  //fetch students

  function fetchStudents(page = 1) {
    setLoading(true);
    api
      .get("list-student", {
        params: { page },
      })
      .then((response) => {
        if (response.status === 422) {
          //extract information
        }

        setStudents(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <>
      <section className=" mx-auto w-full  px-5 lg:pb-4 lg:ml-2">
        <div className="w-auto mt-3 flex flex-col overflow-x-auto md:overflow-visible">
          <div className="-mx-4  overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" inline-block min-w-full pb-2 align-middle md:px-6 lg:px-8 max-w-fit ">
              <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                <div className="flex items-center justify-start py-2 bg-white">
                  <h2 className="text-lg text-dark p-4 font-semibold mr-5">
                    Students
                  </h2>
                  <button
                    type="button"
                    className="rounded-md bg-white px-6 py-2 text-sm font-medium text-lime-600 border border-lime-600 shadow-sm hover:bg-lime-600 hover:text-white"
                  >
                    <Link to="/add-student">Add Student +</Link>
                  </button>
                </div>

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-right text-sm  font-medium text-gray-600 "
                      >
                        <span>S.N</span>
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm  font-medium text-gray-600 lg:pl-50 pl-20"
                      >
                        <span>Name</span>
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm  font-medium text-gray-600 pr-30"
                      >
                        <span>Family</span>
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm  font-medium text-gray-600"
                      >
                        <span>Status</span>
                      </th>
                      {/* <th
                        scope="col"
                        className="px-2 py-3.5 text-center text-sm  font-medium text-gray-600"
                      >
                        <span>Action</span>
                      </th> */}
                    </tr>
                  </thead>

                  {loading && <LoadingBlock>Loading...</LoadingBlock>}
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {student?.data?.length > 0 ? (
                      student?.data?.map((student, index) => (
                        <tr key={index}>
                          <td className="text-gray-900 text-base text-right font-normal   whitespace-nowrap px-4 py-4 pr-6">
                            {index + 1}
                          </td>

                          <td className="text-dark lg:pl-50 pl-20 text-base font-medium  whitespace-nowrap px-2 py-4 flex gap-3 ">
                            <Avatar user={student} />
                            <div>
                              <Link
                                to={`/student/edit/${student.id}`}
                                className="text-base  font-medium text-dark hover:underline"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {student.first_name} {student.last_name}
                              </Link>

                              {/* <p className="text-xs font-normal text-gray-500">
                                <Link
                                  to={`/student/send-message/${student.id}`}
                                  style={{ color: "#2E33A8" }}
                                >
                                  {student.email}
                                </Link>
                              </p> */}
                            </div>
                          </td>

                          {/* <td className="whitespace-nowrap px-4 py-4">{student.parent_details?.first_name} {student.parent_details?.last_name}</td> */}
                          <td className="text-gray-900 text-base font-normal   whitespace-nowrap px-2 py-4">
                            {student.parent_details
                              ? `${student.parent_details.first_name} ${student.parent_details.last_name}`
                              : "---"}
                            <br />
                            {/* <Link
                              to={`/parent/send-message/${student.id}`}
                              style={{ color: "#2E33A8" }}
                            > */}
                            <span className=" text-xs font-normal text-gray-500">
                              {student.parent_details
                                ? `${student.parent_details.email}`
                                : "---"}
                              {/* </Link> */}
                            </span>
                          </td>

                          <UserStatusLayout user={student} />

                          {/* <td className="text-gray-600  whitespace-nowrap text-sm font-light px-5 py-1 text-center ">
                            <StudentActionRow
                              user={student}
                              DeleteStudent={DeleteStudent}
                              loginAsUser={loginAsUser}
                            />
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center py-4">
                          No Student available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {student?.meta?.last_page > 1 && (
            <Pagination
              meta={student.meta}
              onChange={(page) => fetchStudents(page)}
            />
          )}
        </div>
      </section>
    </>
  );
}
